import {
  Button,
  Input,
  Row,
  Col,
  Space,
  Upload,
  Radio,
  Select,
  // Popconfirm,
  Spin,
  Divider,
  Popconfirm,
} from "antd";
import { put_api, get_api } from "api";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Label } from "reactstrap";
import {
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as CustomArrowIcon } from "../../assets/img/dropdown-icon.svg";
import deleteIcon from "../../assets/img/delete-icon.svg";
import Map from "views/Map";
import dayjs from "dayjs";
// import { post_api } from "api";
import { isEmpty, startCase, toUpper } from "lodash";
import { useNavigate } from "react-router-dom";
import UploadLogo from "../../assets/img/upload-icon.svg";
import { updateFirebaseUserByUserId } from "firebaseConfig";
import { post_api } from "api";
import { useAuth } from "context/AuthContext";

const { Option } = Select;

const BusinessUserProfile = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const [profileData, setProfileData] = useState({
    location: { lat: "", long: "", address: "", locationName: "" },
    contact: { email: "", phone: "", website: "" },
    companyName: "",
    pricingPlan: {
      productName: "",
      productAmount: "",
      productIntervalType: "",
      currency: "",
      subscriptionStatus: "",
      trial_period_days: "",
      trialPeriodStartDate: "",
      trialPeriodEndDate: "",
      totalPaid: "",
      invoiceStatus: "",
      subscriptionIntentCreatedDate: "",
      paymentMethodId: "",
    },
    language: [],
    testingTime: "",
    companyLogo: "",
    equipment: [],
    services: [],
    software: [],
    additionalRequirements: [""],
    isVaccinated: false,
  });

  const [previewUrl, setPreviewUrl] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getServices = async () => {
    try {
      const { data } = await get_api("service/all");
      setAllServices(data.allServices);
      return data;
    } catch (err) {
      console.log("🚀 ~ getServices ~ err:", err);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await get_api("software/all");
      setAllSoftwares(data.allSoftwares);
      return data;
    } catch (err) {
      console.log("🚀 ~ getSoftwares ~ err:", err);
    }
  };

  const getEquipments = async () => {
    try {
      const { data } = await get_api("equipment/all");
      setAllEquipments(data.allEquipments);
      return data;
    } catch (err) {
      console.log("🚀 ~ getEquipments ~ err:", err);
    }
  };

  const getLanguages = async () => {
    try {
      const { data } = await get_api("language/get-all-languages");
      setCountryData(data.allLanguages);
      return data;
    } catch (err) {
      console.log("🚀 ~ getLanguages ~ err:", err);
    }
  };

  const getUserProfile = async () => {
    try {
      const { data } = await get_api("profile");
      if (
        !Array.isArray(data.companyProfileDetail.additionalRequirements) ||
        data.companyProfileDetail.additionalRequirements.length === 0
      ) {
        data.companyProfileDetail.additionalRequirements = [""];
      }

      if (!isEmpty(data.companyProfileDetail.language)) {
        data.companyProfileDetail.language =
          data.companyProfileDetail.language.map((lng) => lng._id);
      }

      if (!isEmpty(data.companyProfileDetail.services)) {
        data.companyProfileDetail.services =
          data.companyProfileDetail.services.map((servs) => servs._id);
      }

      if (!isEmpty(data.companyProfileDetail.equipment)) {
        data.companyProfileDetail.equipment =
          data.companyProfileDetail.equipment.map((eqp) => eqp._id);
      }

      if (!isEmpty(data.companyProfileDetail.software)) {
        data.companyProfileDetail.software =
          data.companyProfileDetail.software.map((sft) => sft._id);
      }
      setProfileData(data.companyProfileDetail);
      if (data?.companyProfileDetail?.companyLogo) {
        setPreviewUrl(data.companyProfileDetail.companyLogo);
      }
    } catch (error) {
      console.log("Error fetching user profile:", error);
    }
  };

  useEffect(() => {
    getUserProfile();
    getServices();
    getSoftwares();
    getEquipments();
    getLanguages();
  }, []);

  const handleInputChange = (e, field, subfield = null, index = null) => {
    const { value } = e.target;

    setProfileData((prevData) => {
      if (index !== null) {
        const updatedArray = [...prevData[field]];
        updatedArray[index] = value;
        return { ...prevData, [field]: updatedArray };
      } else if (subfield) {
        return {
          ...prevData,
          [field]: { ...prevData[field], [subfield]: value },
        };
      } else {
        return { ...prevData, [field]: value };
      }
    });
  };

  const handleSelectChange = (value, field) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFormSubmit = async () => {
    try {
      setLoading(true);
      const formData = { ...profileData };
      delete formData.pricingPlan;
      const { data } = await put_api("profile", formData);
      console.log("Response:", data.companyProfileDetail);
      localStorage.setItem(
        "company_profile",
        JSON.stringify(data?.companyProfileDetail) ?? null
      );
      setLoading(false);
      const updatedRecord = {
        user_full_name: startCase(data?.companyProfileDetail?.companyName),
        profile_picture: data?.companyProfileDetail?.companyLogo,
      };
      await updateFirebaseUserByUserId(
        data?.companyProfileDetail?.userId,
        updatedRecord
      );
      navigate("/dashboard");
    } catch (errorInfo) {
      console.log("Validation failed:", errorInfo);
      setLoading(false);
    }
  };

  const uploadImage = async (values) => {
    try {
      const { data } = await put_api("profile/upload", values);
      return data?.url;
    } catch (err) {
      console.log("🚀 ~ uploadImage ~ err:", err);
      return false;
    }
  };

  const handleFileChange = async ({ file }) => {
    const formData = new FormData();
    formData.append("logo", file);

    const url = await uploadImage(formData);
    setPreviewUrl(url);
    setProfileData((prevData) => ({ ...prevData, companyLogo: url }));
    updateFirebaseUserByUserId(userData.id, { profile_picture: url });
  };

  const handleDeleteUpload = async () => {
    try {
      const { data } = await put_api("profile/remove-uploaded", {
        url: previewUrl,
      });
      setPreviewUrl(data?.url);
      setProfileData((prevData) => ({ ...prevData, companyLogo: data?.url }));
      return data?.url;
    } catch (err) {
      console.log("🚀 ~ uploadImage ~ err:", err);
      return false;
    }
  };

  const handleAddTextbox = () => {
    setProfileData((prev) => ({
      ...prev,
      additionalRequirements: [...profileData.additionalRequirements, ""],
    }));
  };

  const handleRemoveAdditionalRequirement = (arr, idx) => {
    if (idx !== -1) {
      const newArr = [...arr];
      newArr.splice(idx, 1);
      setProfileData((prev) => ({
        ...prev,
        additionalRequirements: newArr,
      }));
    }
  };

  const handleSearch = async (value) => {
    setInputValue(value);
    if (value.length > 2) {
      try {
        const response = await get_api(`location/get-locations/${value}`);
        const locations = response.data.locations.predictions.map(
          (prediction) => ({
            label: prediction.description,
            value: prediction.place_id,
          })
        );
        setOptions(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const getLocationDetails = async (placeId) => {
    try {
      const response = await get_api(
        `location/get-location-details/${placeId}`
      );
      const locationData = response.data.locationDetails;
      const cityName = locationData.address_components.find((component) =>
        component.types.includes("locality")
      )["long_name"];
      const stateName = locationData.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )["long_name"];

      setProfileData((prev) => ({
        ...prev,
        location: {
          lat: locationData.geometry.location.lat,
          long: locationData.geometry.location.lng,
          address: `${cityName}, ${stateName}`,
          locationName: locationData.name,
        },
      }));
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleChangeMap = async (val, event) => {
    const selectedOption = options.find((option) => option.value === val);
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
    await getLocationDetails(val);
  };

  const handleClearSelection = () => {
    setInputValue("");
    setOptions([]);
  };

  const handlePrimaryChange = (val) => {
    setProfileData((prev) => {
      const secondaryLanguages = prev.language
        .slice(1)
        .filter((lang) => lang !== val);
      return {
        ...prev,
        language: [val, ...secondaryLanguages],
      };
    });
  };

  const handleSecondaryChange = (vals) => {
    setProfileData((prev) => ({
      ...prev,
      language: [
        prev.language[0],
        ...vals.filter((val) => val !== prev.language[0]._id),
      ],
    }));
  };

  const handleAddSelectBox = () => {
    setProfileData((prev) => ({
      ...prev,
      language: [...prev.language, ""],
    }));
  };

  const handleRemoveLanguage = (id) => {
    setProfileData((prev) => ({
      ...prev,
      language: prev.language.filter((lang) => lang !== id),
    }));
  };

  const handleCancelSubscription = async (subscriptionId, isCancelled) => {
    try {
      const { data } = await post_api("stripe/cancel-subscription", {
        subscriptionId,
      });
      navigate("/dashboard")
      return data;
    } catch (err) {
      console.log("🚀 ~ handleCancelSubscription ~ err:", err);
      return false;
    }
  };

  return (
    <div className="content mb-0">
      <div className="page-header-wrap">
        <h2 className="page-main-title">Business Profile</h2>
        <Popconfirm
          title="Cancel Subscription"
          description="Are you sure to cancel subscription?"
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
          onConfirm={() =>
            handleCancelSubscription(
              profileData.pricingPlan?.subscriptionId
            )
          }
        >
          <Button className="action-btn cancel-btn-wrap" disabled={profileData.pricingPlan.cancelAt !== null ? true : false}>
            {profileData.pricingPlan.cancelAt !== null ? 'Subscription cancelled' : 'Cancel Subscription'}
          </Button>
        </Popconfirm>
      </div>
      {loading && <Spin fullscreen />}
      <Card className="profile-card-wrapper">
        <CardBody className="user-profile-body">
          <Row gutter={[20, 20]} className="mb-md-4 mb-3">
            <Col xl={4} md={5} xs={24}>
              <Label className="card-sub-title">Company Logo</Label>
              <div className="company-logo-wrapper">
                {!previewUrl && (
                  <Upload
                    maxCount={1}
                    beforeUpload={() => false}
                    listType="picture"
                    accept="image/*"
                    onChange={handleFileChange}
                    fileList={null}
                  >
                    <Button className="upload-btn">
                      <img src={UploadLogo} alt="upload icon" />
                      Upload Logo
                    </Button>
                  </Upload>
                )}
                {previewUrl && (
                  <div className="upload-img-preview">
                    <img
                      className="selected-profile-img"
                      src={previewUrl}
                      alt="Company Logo Preview"
                    />
                    <Button
                      type="primary"
                      danger
                      className="profile-delete-icon"
                      icon={<i className="fas fa-trash-alt"></i>}
                      onClick={handleDeleteUpload}
                    />
                  </div>
                )}
              </div>
            </Col>
            <Col xl={20} md={19} xs={24}>
              <Row gutter={[20, 10]} className="mb-2">
                <Col lg={8} sm={12} xs={24}>
                  <Label className="card-sub-title">Company Name</Label>
                  <Input
                    value={profileData.companyName}
                    onChange={(e) => handleInputChange(e, "companyName")}
                    placeholder="Enter company name"
                    className="stepper-input-field"
                  />
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <Label className="card-sub-title">Website</Label>
                  <Input
                    value={profileData.contact.website}
                    onChange={(e) => handleInputChange(e, "contact", "website")}
                    placeholder="Enter website"
                    className="stepper-input-field"
                  />
                </Col>
                <Col lg={8} sm={12} xs={24}>
                  <Label className="card-sub-title">Email</Label>
                  <Input
                    value={profileData.contact.email}
                    onChange={(e) => handleInputChange(e, "contact", "email")}
                    placeholder="Enter email"
                    className="stepper-input-field"
                  />
                </Col>
                <Col span={24}>
                  <Label className="card-sub-title">Location</Label>
                  <div className="location-stepper">
                    <Select
                      showSearch
                      className="common-dropdown common-antd-component"
                      value={inputValue || undefined}
                      placeholder="Search a location"
                      onSearch={handleSearch}
                      onChange={handleChangeMap}
                      filterOption={false}
                      notFoundContent={null}
                      suffixIcon={
                        inputValue && (
                          <CloseCircleOutlined onClick={handleClearSelection} />
                        )
                      }
                    >
                      {options.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
          <Row gutter={[20, 10]} className="mb-md-4 mb-3">
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Phone</Label>
              <Input
                value={profileData.contact.phone}
                onChange={(e) => handleInputChange(e, "contact", "phone")}
                placeholder="Enter phone"
                className="stepper-input-field"
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Street Address</Label>
              <Input
                value={profileData.location.locationName}
                onChange={(e) =>
                  handleInputChange(e, "location", "locationName")
                }
                placeholder="Enter location name"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">City, state</Label>
              <Input
                value={profileData.location.address}
                onChange={(e) =>
                  handleInputChange(e, "location", "address")
                }
                placeholder="Enter address"
                className="stepper-input-field"
                disabled
              />
            </Col>
          </Row>
          <Row gutter={[20, 10]} className="mb-md-4 mb-3">
            {/* <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Subscription Product</Label>
              <Input
                value={profileData.pricingPlan.productName}
                placeholder="Enter product name"
                className="stepper-input-field"
                disabled
              />
            </Col> */}
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Subscription Amount</Label>
              <Input
                value={profileData.pricingPlan.productAmount}
                placeholder="Enter product amount"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">
                Subscription Interval Type
              </Label>
              <Input
                value={profileData.pricingPlan.productIntervalType}
                placeholder="Enter interval type"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Currency</Label>
              <Input
                value={toUpper(profileData.pricingPlan.currency)}
                placeholder="Enter currency"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Subscription Status</Label>
              <Input
                value={
                  profileData.pricingPlan.subscriptionStatus !== "trialing"
                    ? profileData.pricingPlan.subscriptionStatus
                    : "28 Days Free Trial"
                }
                placeholder="Enter subscription status"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Trial Period Days</Label>
              <Input
                value={profileData.pricingPlan.trial_period_days}
                placeholder="Enter trial period days"
                className="stepper-input-field"
                disabled
              />
            </Col>
          </Row>
          <Row gutter={[20, 10]} className="mb-md-4 mb-3">
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Trial Period Start Date</Label>
              <Input
                value={dayjs
                  .unix(profileData.pricingPlan.trialPeriodStartDate)
                  .format("DD-MM-YYYY")}
                placeholder="Enter trial period start date"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Trial Period End Date</Label>
              <Input
                value={dayjs
                  .unix(profileData.pricingPlan.trialPeriodEndDate)
                  .format("DD-MM-YYYY")}
                placeholder="Enter trial period end date"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Total Paid</Label>
              <Input
                value={profileData.pricingPlan.totalPaid}
                placeholder="Enter total paid"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Invoice Status</Label>
              <Input
                value={startCase(profileData.pricingPlan.invoiceStatus)}
                placeholder="Enter invoice status"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Coupon Code Applied</Label>
              <Input
                value={profileData.pricingPlan?.couponName ? startCase(profileData.pricingPlan?.couponName) : "-"}
                placeholder="Discount Coupon Code"
                className="stepper-input-field"
                disabled
              />
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Vaccination Required</Label>
              <Radio.Group
                onChange={(e) => {
                  handleInputChange(e, "isVaccinated");
                }}
                value={profileData.isVaccinated}
              >
                <Space className="radio-wrapper" direction="horizontal">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Space>
              </Radio.Group>
            </Col>
            <Col lg={8} sm={12} xs={24}>
              <Label className="card-sub-title">Testing Time</Label>
              <Radio.Group
                onChange={(e) => handleInputChange(e, "testingTime")}
                value={profileData.testingTime}
              >
                <Space className="radio-wrapper" direction="horizontal">
                  <Radio value={"20-mins"}>20 Min</Radio>
                  <Radio value={"30-mins"}>30 Min</Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={[20, 10]} className="mb-md-4 mb-3">
            <Col lg={8} md={12} xs={24}>
              <Label className="card-sub-title">Services</Label>
              <Select
                className="common-dropdown common-antd-component"
                value={profileData.services}
                mode="multiple"
                placeholder="Select all that apply"
                suffixIcon={<CustomArrowIcon />}
                options={allServices.map((service) => ({
                  value: service._id,
                  label: service.name,
                }))}
                onChange={(value) => handleSelectChange(value, "services")}
                showSearch={false}
              />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <Label className="card-sub-title">Equipment</Label>
              <Select
                className="common-dropdown common-antd-component"
                value={profileData.equipment}
                mode="multiple"
                placeholder="Select all that apply"
                suffixIcon={<CustomArrowIcon />}
                options={allEquipments.map((equip) => ({
                  value: equip._id,
                  label: equip.name,
                }))}
                onChange={(value) => handleSelectChange(value, "equipment")}
                showSearch={false}
              />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <Label className="card-sub-title">Software</Label>
              <Select
                className="common-dropdown common-antd-component"
                value={profileData.software}
                mode="multiple"
                placeholder="Select all that apply"
                suffixIcon={<CustomArrowIcon />}
                options={allSoftwares.map((soft) => ({
                  value: soft._id,
                  label: soft.name,
                }))}
                onChange={(value) => handleSelectChange(value, "software")}
                showSearch={false}
              />
            </Col>
          </Row>
          <Row gutter={16} className="mb-md-4 mb-3">
            <Col span={24}>
              <Label className="card-sub-title">Additional Requirements</Label>
              <Row gutter={[20, 10]} className="mb-1">
                {profileData.additionalRequirements?.map(
                  (requirement, index) => (
                    <Col lg={8} md={12} xs={24}>
                      <div className="add-required-field-wrapper" key={index}>
                        {profileData.additionalRequirements.length > 1 && (
                          <Button
                            className="delete-icon-wrap"
                            color="link"
                            onClick={() =>
                              handleRemoveAdditionalRequirement(
                                profileData.additionalRequirements,
                                index
                              )
                            }
                          >
                            <img src={deleteIcon} alt="delete icon" />
                          </Button>
                        )}
                        <div className="add-requirement-input-wrap">
                          <Input
                            key={index}
                            type="text"
                            className="stepper-input-field"
                            placeholder="Enter Additional Requirements"
                            value={requirement}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "additionalRequirements",
                                null,
                                index
                              )
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
              {profileData.additionalRequirements.length < 3 && (
                <div className="add-other-field">
                  <Button
                    className="add-field-btn"
                    color="link"
                    onClick={handleAddTextbox}
                  >
                    Add <span className="add-icon">+</span>
                  </Button>
                </div>
              )}
            </Col>
          </Row>
          <Divider />
          <Row gutter={[20]} className="mb-4">
            <Col span={24}>
              <div className="language-selector-wrapper primary-language">
                <Label className="card-sub-title">Primary Language</Label>
                <Select
                  className="common-dropdown common-antd-component"
                  placeholder="Primary Language"
                  optionFilterProp="children"
                  onChange={handlePrimaryChange}
                  value={profileData?.language[0]}
                  suffixIcon={<CustomArrowIcon />}
                >
                  {countryData.map((country) => (
                    <Option
                      key={country._id}
                      value={country._id}
                      disabled={profileData.language.some(
                        (secLng) => secLng === country._id
                      )}
                    >
                      <img src={country.flag} alt="" />
                      {country.language}
                    </Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col span={24}>
              <div className="language-selector-wrapper secondary-language">
                {profileData.language.slice(1).length > 0 && (
                  <label className="card-sub-title">Secondary Language</label>
                )}
                <Row gutter={[20, 10]}>
                  {profileData.language
                    .slice(1)
                    .map((secondaryLanguage, index) => {
                      return (
                        <Col xl={5} sm={8} xs={24}>
                          <div
                            key={index + 1}
                            className="profile-selector-wrapper"
                          >
                            {profileData.language.slice(1).length > 1 && (
                              <Button
                                className="delete-icon-wrap"
                                color="link"
                                onClick={() =>
                                  handleRemoveLanguage(secondaryLanguage)
                                }
                              >
                                <img src={deleteIcon} alt="delete icon" />
                              </Button>
                            )}
                            <Select
                              className="common-dropdown common-antd-component"
                              placeholder="Secondary Languages"
                              optionFilterProp="children"
                              onChange={(value) =>
                                handleSecondaryChange(
                                  profileData.language
                                    .slice(1)
                                    .map((lang, idx) =>
                                      idx === index ? value : lang
                                    )
                                )
                              }
                              value={secondaryLanguage}
                              suffixIcon={<CustomArrowIcon />}
                            >
                              {countryData.map((country) => (
                                <Option
                                  key={country._id}
                                  value={country._id}
                                  disabled={
                                    country._id === profileData.language[0] ||
                                    profileData.language
                                      .slice(1)
                                      .includes(country._id)
                                  }
                                >
                                  <img src={country.flag} alt="" />
                                  {country.language}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </div>
              <div className="add-other-field">
                <Button
                  className="add-field-btn"
                  color="link"
                  onClick={handleAddSelectBox}
                  disabled={profileData.language.includes("")}
                >
                  Add <span className="add-icon">+</span>
                </Button>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              {/* <Label className="card-sub-title">Location</Label> */}
              <div className="location-stepper">
                {/* <Select
                  showSearch
                  className="common-dropdown common-antd-component"
                  value={inputValue || undefined}
                  placeholder="Search a location"
                  onSearch={handleSearch}
                  onChange={handleChangeMap}
                  filterOption={false}
                  notFoundContent={null}
                  suffixIcon={
                    inputValue && (
                      <CloseCircleOutlined onClick={handleClearSelection} />
                    )
                  }
                >
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select> */}
                <div className="location-map-wrapper">
                  <Map
                    lat={profileData?.location?.lat}
                    long={profileData?.location?.long}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row className="profile-page-footer" gutter={16}>
            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Space>
                <Button
                  className="common-footer-btn cancel-btn-wrap"
                  onClick={() => navigate("/dashboard")}
                >
                  Cancel
                </Button>
                <Button
                  className="common-footer-btn save-btn-wrap"
                  onClick={handleFormSubmit}
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default BusinessUserProfile;
