import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import useResetPasswordHook from "./useResetPasswordHook";
import { ErrorDisplay } from "components/GeneralComponents/Utils";
import { getFieldClassName } from "components/GeneralComponents/Utils";
import CommonForm from "views/CommonForm";
import { Link } from "react-router-dom";
import { Spin } from "antd";

function ForgotPassword() {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    errors,
    showPassword,
    handleShowPassword,
    values,
    signin,
    loading,
  } = useResetPasswordHook();

  return (
    <CommonForm>
      <div className="reset-form-wrap">
        <h2 className="form-title">Reset Password</h2>
        <Form className="reset-pass-form common-form-content" onSubmit={handleSubmit}>
          <FormGroup>
            <Label className="form-label" for="password">Password</Label>
            <Input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              placeholder="Enter your password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={getFieldClassName("password", errors, touched)}
            />
            <ErrorDisplay name="password" errors={errors} touched={touched} />
          </FormGroup>
          <FormGroup>
            <Label className="form-label" for="password">Confirm Password</Label>
            <Input
              type={showPassword ? "text" : "password"}
              name="confirm"
              id="password"
              placeholder="Re-enter password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirm}
              className={getFieldClassName("confirm", errors, touched)}
            />
            <ErrorDisplay name="confirm" errors={errors} touched={touched} />
          </FormGroup>
          <div className="cstm-checkbox">
            <div className="cstm-checkbox-link">
              <Input
                type="checkbox"
                name="show_password"
                id="show_password"
                checked={showPassword}
                onChange={handleShowPassword}
              />
              <Label for="show_password" className="cstm-checkbox-text">Show Password</Label>
            </div>
          </div>
          <Button
            color="primary"
            className="common-submit-btn"
            type="submit"
            disabled={loading}
          >
            {loading && <Spin fullscreen />} Reset Password
          </Button>
          <hr />
          <div className="form-footer-account-link">
            <Link to={signin} className="footer-redirect-link" type="Sign Up">
              Back to Sign In
            </Link>
          </div>
        </Form>
      </div>
    </CommonForm>
  );
}

export default ForgotPassword;
