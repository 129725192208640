/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/jsx-no-target-blank */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import largefavicon from "../../assets/img/optom-large-img.svg";
import logo from "../../assets/img/optopm-logo.svg";
import { Navbar, NavbarBrand, Container } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import he from 'he';

const TermsAndConditions = () => {
  const [content, setContent] = useState("");

  const getTermsAndConditions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}cms-pages/terms-and-conditions`
      );
      setContent(response.data.cmsPages.content);
    } catch (err) {
      console.log("🚀 ~ getTermsAndConditions ~ err:", err);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  return (
    <div className="cms-page-content-wrapper">
      <div className="cms-content-sec">
        <div className="cms-content">
          {/* <h2 className="cms-page-title">Terms and Condition</h2> */}
          <div dangerouslySetInnerHTML={{ __html: he.decode(content) }} />
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
