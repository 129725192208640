/*eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getUserChatList } from "firebaseConfig";
import { useAuth } from "context/AuthContext";
import { listenForUserChatListUpdates } from "firebaseConfig";
import { useLocation } from "react-router-dom";
import { deleteUserFromUserChatList } from "firebaseConfig";
import { getChatListItemByChatId } from "firebaseConfig";
import { listenForNewMessages } from "firebaseConfig";
import { toast } from "react-toastify";
import { get_api } from "api";
import { addChatsRecord } from "firebaseConfig";
import { getMessagesForChat } from "firebaseConfig";
import { addMessageToChat } from "firebaseConfig";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { moveChatToArchive } from "firebaseConfig";
import { storage } from "firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import imageCompression from "browser-image-compression";
import { post_api } from "api";

dayjs.extend(utc);
dayjs.extend(timezone);

const useMessagesHook = () => {
  const { companyProfile, userData } = useAuth();
  const location = useLocation();

  const [locumUserData, setLocumUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [receiverObj, setReceiverObj] = useState({});
  const [receiverLoader, setReceiverLoader] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [text, setText] = useState("");
  const [chatUsersList, setChatUsersList] = useState([]);
  const [archiveCount, setArchiveCount] = useState(0);
  const [archiveView, setArchiveView] = useState(false);

  const getAllChatUsersList = async (isArchived) => {
    try {
      isArchived = isArchived ?? archiveView;
      setIsLoading(true);
      const { chatList, archiveRecordCount } = await getUserChatList(
        userData.id,
        isArchived
      );
      setChatUsersList(chatList);
      setArchiveCount(archiveRecordCount);
      setIsLoading(false);
    } catch (err) {
      console.log("🚀 ~ getAllChatUsersList ~ err:", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllChatUsersList();
    const unsubscribe = listenForUserChatListUpdates(
      userData.id,
      ({ chatList, archivedCount }) => {
        setChatUsersList(chatList);
        setArchiveCount(archivedCount);
      },
      archiveView
    );
    return () => unsubscribe();
  }, []);

  const getLocumUserById = async (id) => {
    try {
      const res = await get_api(`profile/${id}/locum`);
      setLocumUserData(res?.data?.locumUsersProfile);
      return true;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const showMessagesDirectly = async () => {
    const recData = await getChatListItemByChatId(
      companyProfile.userId,
      location?.state?.chatId
    );
    await handleAddChatsRecord(location?.state?.chatId, recData);
  };

  useEffect(() => {
    if (location?.state?.chatId) {
      showMessagesDirectly();
    }
  }, [location?.state?.chatId]);

  useEffect(() => {
    getLocumUserById(receiverObj?.chat_user_id);
  }, [receiverObj?.chat_user_id]);

  const handleNewMessages = async (newMessages) => {
    setAllMessages(newMessages);
    const { chatList, archiveRecordCount } = await getUserChatList(
      companyProfile.userId,
      archiveView
    );
    setChatUsersList(chatList);
    setArchiveCount(archiveRecordCount);
  };

  useEffect(() => {
    // Start listening for new messages
    const unsubscribe = listenForNewMessages(
      receiverObj.chat_id,
      handleNewMessages
    );

    // Clean up the listener when the component unmounts
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [receiverObj?.chat_id]);

  const handleAddChatsRecord = async (chatId, chatObj) => {
    setReceiverLoader(true);
    await addChatsRecord(chatId, companyProfile.userId);
    setReceiverObj(chatObj);
    const getAllMsgs = await getMessagesForChat(chatId);
    setAllMessages(getAllMsgs);
    setReceiverLoader(false);
  };

  const handleUpload = async (file) => {
    // Compress the image
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);

    // Add a timestamp to the file name
    const timestamp = Date.now();
    const fileName = `${timestamp}_${file.name}`;

    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `uploads/${fileName}`);
    // const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, compressedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.error(error);
        console.log("Upload failed");
      },
      () => {
        // Handle successful uploads
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await handleSendMessage(null, downloadURL);
        });
      }
    );

    // Prevent default upload behavior by returning false
    return false;
  };

  const handleSendMessage = async (_e, image_url = "") => {
    if (!text.trim() && !image_url.trim()) return;
    const msgObj = {
      image_url,
      text,
      profile_photo: receiverObj?.chat_user_profile_picture,
      receiver_id: receiverObj?.chat_user_id,
      receiver_name: receiverObj?.chat_user_name,
      sender_id: companyProfile?.userId,
      read: false,
      time: new Date(),
    };
    await addMessageToChat(receiverObj.chat_id, msgObj);
    await post_api(`chat/send-notification`, { locumUserId: receiverObj?.chat_user_id , chat_id: receiverObj?.chat_id });
    setText("");
    const getAllMsgs = await getMessagesForChat(receiverObj.chat_id);
    setAllMessages(getAllMsgs);
  };

  const formatMessageTime = (timestamp) => {
    const milliseconds =
      (timestamp.seconds || 0) * 1000 + (timestamp.nanoseconds || 0) / 1000000;
    const date = dayjs(milliseconds);

    // Check if the date is valid
    if (!date.isValid()) {
      return "Invalid Time";
    }

    // Format the date to show only the time
    return date.tz("Asia/Kolkata").format("h:mm A"); // Adjust timezone if needed
  };

  const handleDeleteChat = async (recData) => {
    if (companyProfile?.userId && recData?.chat_id) {
      setIsLoading(true);
      await deleteUserFromUserChatList(companyProfile.userId, recData.chat_id);
      const { chatList, archiveRecordCount } = await getUserChatList(
        companyProfile.userId,
        archiveView
      );
      setChatUsersList(chatList);
      setArchiveCount(archiveRecordCount);
      setReceiverObj({});
      setIsLoading(false);
    }
  };

  const handleArchive = async (chatId) => {
    await moveChatToArchive(
      companyProfile.userId,
      chatId,
      !archiveView ? true : false
    );
    setArchiveView(false);
  };

  const onSearch = (value, _e, info) => {
    console.log("🚀 ~ onSearch ~ value, _e, info:", value, _e, info);
  };

  return {
    chatUsersList,
    isLoading,
    locumUserData,
    open,
    setOpen,
    handleSendMessage,
    formatMessageTime,
    handleDeleteChat,
    receiverObj,
    receiverLoader,
    allMessages,
    text,
    setText,
    companyProfile,
    handleAddChatsRecord,
    handleArchive,
    archiveCount,
    archiveView,
    setArchiveView,
    setChatUsersList,
    // getAllArchiveUsersList,
    getAllChatUsersList,
    onSearch,
    handleUpload,
  };
};

export default useMessagesHook;
