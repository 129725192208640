/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import AdminLayout from "layouts/Admin";
import SignIn from "views/signin/SignIn";
import { useAuth } from "context/AuthContext";
import SignUp from "views/signup/SignUp";
import ForgotPassword from "views/forgot_password/ForgotPassword";
import ResetPassword from "views/reset_password/ResetPassword";
import RoutesName from "variables/route";
import ShiftContext from "components/PostShift/ShiftContext";
import usePostShiftListHook from "components/PostShift/usePostShiftListHook";
import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
import { put_api } from "api";
import { requestToken } from "firebaseConfig";
import TermsAndConditions from "components/TermsAndConditions";
import PrivacyPolicy from "components/PrivacyPolicy";
import ReconnectingWebSocket from "reconnecting-websocket"; // Add this line
import Guidelines from "components/Guidelines";
import { updateFirebaseUserByUserId } from "firebaseConfig";
import useLocations from "components/Location/useLocations";
import LocationContext from "components/Location/LocationContext";
import useUsers from "components/Users/useUsers";
import UserContext from "components/Users/UserContext";
import { USER_ROLE } from "variables/helper";

const {
  signin,
  signup,
  forgotPassword,
  resetPassword,
  dashboard,
  company_profile_setup,
  privacy_policy,
  terms_and_conditions,
  guidelines,
  payment,
} = RoutesName;

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace />;
  }
  return children;
};

const SubUserAccessedRoute = ["/post-shifts", "/shift-requests", "/shifts"];

const SubProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (
    JSON.parse(localStorage.getItem("user"))?.role === USER_ROLE.SUB_USER &&
    SubUserAccessedRoute.includes(location.pathname)
  ) {
    return children;
  } else {
    navigate("/post-shifts");
    return;
  }
};

function App() {
  const navigate = useNavigate();
  const { isAuthenticated, companyProfile, userData } = useAuth();
  const location = useLocation();
  const { refreshShifts, data } = usePostShiftListHook();
  const { refreshLocation, locData } = useLocations();
  const { refreshUser, allUsers } = useUsers();
  const [ws, setWs] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  useEffect(() => {
    const socket = new ReconnectingWebSocket(process.env.REACT_APP_WEB_SOCKET);

    socket.onopen = () => {
      // console.log("WebSocket connection opened.");
      // Send a register event with the user's ID
      if (userData && userData?.id) {
        socket.send(JSON.stringify({ type: "register", userId: userData.id }));
      } else {
        // console.error("User data is missing or not loaded");
      }
    };

    socket.onmessage = (event) => {
      // console.log("WebSocket message received:", event);
      try {
        const data = JSON.parse(event.data);
        if (data.type === "dashboardUpdate") {
          console.log("Dashboard update received:", data.payload);
          // Handle the dashboard update
        }
      } catch (error) {
        // console.error("Error parsing WebSocket message:", error);
      }
    };

    socket.onerror = (error) => {
      // console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      // console.log("WebSocket connection closed");
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, [userData]);

  let attempts = 5;

  function connectWebSocket() {
    const socket = new WebSocket(process.env.REACT_APP_WEB_SOCKET);

    socket.onopen = () => {
      // console.log("Successfully connected to the WebSocket server");
    };

    socket.onerror = (error) => {
      // console.log("WebSocket error:", error);
    };

    socket.onclose = (event) => {
      if (!event.wasClean && attempts > 0) {
        // console.log(
        //   `Socket is closed. Reconnect will be attempted in 3 second.`,
        //   event.reason
        // );
        setTimeout(() => {
          attempts--;
          connectWebSocket();
        }, 3000);
      } else {
        // console.log("Socket is closed. No reconnect will be attempted.");
      }
    };
  }

  connectWebSocket();

  const updateFcmToken = async () => {
    try {
      const token = await requestToken();
      if (token && userData && userData.deviceToken !== token) {
        await put_api("auth/update-fcm-token", {
          deviceToken: token,
        });
        userData.deviceToken = token;
        localStorage.setItem("user", JSON.stringify(userData));
        return true;
      }
      return false;
    } catch (err) {
      console.log("🚀 ~ updateFcmToken ~ err:", err);
    }
  };

  // Existing useEffect for Firebase and token update remains unchanged
  useEffect(() => {
    updateFcmToken();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      // Show notification using browser's Notification API
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: "/firebase-logo.png",
      };
      new Notification(notificationTitle, notificationOptions);
      setNotifications((prevNotifications) => [...prevNotifications, payload]);
    });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    // const company_profile = localStorage.getItem("company_profile");
    if (token && isAuthenticated) {
      if (
        JSON.parse(localStorage.getItem("user"))?.role === USER_ROLE.SUB_USER &&
        !SubUserAccessedRoute.includes(location.pathname)
      ) {
        navigate("/post-shifts");
        return;
      }
      
      let route;
      const cancelAt = companyProfile?.pricingPlan?.cancelAt;
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight to only compare dates

      // Log values for debugging
      // console.log('cancelAt (raw):', cancelAt);
      // console.log('cancelAt (date):', new Date(cancelAt * 1000));
      // console.log('today:', today);
      // console.log('Subscription status:', companyProfile?.pricingPlan?.subscriptionStatus);

      // Check if the subscription is active and if the cancelAt date is today or has passed
      if (
        (cancelAt !== null &&
          new Date(cancelAt * 1000).getTime() <= today.getTime()) ||
        companyProfile?.pricingPlan?.subscriptionStatus === "canceled" ||
        companyProfile?.pricingPlan?.invoiceStatus === "failed"
      ) {
        console.log(
          "Redirecting due to cancelAt date being today or in the past..."
        );
        navigate("/company-profile-setup", { replace: true });
        return; // Exit the useEffect to prevent further routing
      }

      if (!companyProfile) {
        if (location.pathname === company_profile_setup) {
          localStorage.removeItem("payment_url");
        }

        if (
          location.pathname !== company_profile_setup &&
          localStorage.getItem("payment_url")
        ) {
          navigate("/dashboard", { replace: true });
          localStorage.removeItem("payment_url");
          localStorage.removeItem("profile_step");
          return; // Exit the useEffect to prevent further routing
        }
        route = company_profile_setup;
      } else if (
        location.pathname !== dashboard &&
        !["/", signin, signup, forgotPassword, resetPassword].includes(
          location.pathname
        )
      ) {
        route = location.pathname;
      } else {
        route = dashboard || privacy_policy || terms_and_conditions;
      }

      navigate(route, { replace: true });
    }
  }, [isAuthenticated]);
  const contextValue = useMemo(
    () => ({
      refreshShifts,
      data,
    }),
    [refreshShifts, data]
  );
  const locationContextValue = useMemo(
    () => ({
      refreshLocation,
      locData,
    }),
    [refreshLocation, locData]
  );
  const userContextValue = useMemo(
    () => ({
      refreshUser,
      allUsers,
    }),
    [refreshUser, allUsers]
  );

  const handleVisibilityChange = () => {
    // update user status in firebase
    const status =
      document.visibilityState === "visible" ? "online" : "offline";
    updateFirebaseUserByUserId(userData?.id, { status });
  };

  useEffect(() => {
    if (userData) {
      // Add event listener for visibility change (tab change)
      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [userData?.id]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              {JSON.parse(localStorage.getItem("user"))?.role ===
              USER_ROLE.SUB_USER ? (
                <SubProtectedRoute>
                  <ShiftContext.Provider value={contextValue}>
                    <LocationContext.Provider value={locationContextValue}>
                      <UserContext.Provider value={userContextValue}>
                        <AdminLayout notifications={notifications} />
                      </UserContext.Provider>
                    </LocationContext.Provider>
                  </ShiftContext.Provider>
                </SubProtectedRoute>
              ) : (
                <ShiftContext.Provider value={contextValue}>
                  <LocationContext.Provider value={locationContextValue}>
                    <UserContext.Provider value={userContextValue}>
                      <AdminLayout notifications={notifications} />
                    </UserContext.Provider>
                  </LocationContext.Provider>
                </ShiftContext.Provider>
              )}
            </ProtectedRoute>
          }
        />
        <Route path={signin} element={<SignIn />} />
        <Route path={signup} element={<SignUp />} />
        <Route path={forgotPassword} element={<ForgotPassword />} />
        <Route path={resetPassword} element={<ResetPassword />} />
        <Route path={terms_and_conditions} element={<TermsAndConditions />} />
        <Route path={privacy_policy} element={<PrivacyPolicy />} />
        <Route path={guidelines} element={<Guidelines />} />
        <Route path="*" element={<Navigate to={signin} replace />} />
      </Routes>
    </div>
  );
}

export default App;
