import React, { useEffect, useState } from "react";
import { Card, Tag, Tabs, Avatar, Spin } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { Button } from "reactstrap";
import dayjs from "dayjs";
// import { useNavigate } from "react-router-dom";
import { toHoursAndMinutes, timeAgo } from "components/GeneralComponents/Utils";
import { startCase } from "lodash";

const ShiftOverview = ({
  shiftOverview,
  unPaidHoursList,
  setOpenPostShiftModal,
  resetStates,
  updatePostShift,
  loading,
}) => {
  // const navigate = useNavigate();
  const { companyDetail, postShiftDetail } = shiftOverview ?? {};
  const userDataFromLocalStorage = JSON.parse(localStorage.getItem("user"));

  const [timeAgoText, setTimeAgoText] = useState(
    timeAgo(postShiftDetail?.postShiftDetail?.createdAt)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeAgoText(timeAgo(postShiftDetail?.postShiftDetail?.createdAt));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [postShiftDetail?.postShiftDetail?.createdAt]);

  const unPaidHour = unPaidHoursList.find(
    (uph) => uph.value === postShiftDetail?.unpaid_hours
  );
  function convertDateFormat(dateStr, format) {
    const date = dayjs(dateStr);
    if (date.isValid()) {
      if (format === "DD-MM-YYYY") {
        const date = dayjs(dateStr, "YYYY-MM-DD");
        return date.format("DD-MM-YYYY");
      } else {
        const date = dayjs(dateStr, "DD-MM-YYYY");
        return date.format("YYYY-MM-DD");
      }
    }
  }
  const uphours = toHoursAndMinutes(unPaidHour.value.split("-")[0]);
  // console.log("postShiftDetaildetail-------",postShiftDetail)
  const formattedDate = convertDateFormat(postShiftDetail.date, "DD-MM-YYYY");

  return (
    <div className="modal-popup-content">
      <div className="shift-select-content shift-overview">
        <Card
          title={
            <h3 className="shift-overview-title shift-stepper-sub-title">
              Shift Overview
            </h3>
          }
          bordered={false}
        >
          <div className="shift-header">
            <div className="shift-left-side">
              <div className="shift-avatar-wrap">
                <Avatar src={companyDetail?.logo} />
              </div>
              <div className="shift-user-details">
                <h5>{companyDetail.name}</h5>
                <p>
                  <i className="fas fa-map-marker-alt" />{" "}
                  {`${
                    userDataFromLocalStorage?.role === "user"
                      ? companyDetail?.location?.locationName
                      : postShiftDetail?.location.locationName
                  }, ${
                    userDataFromLocalStorage?.role === "user"
                      ? companyDetail?.location?.address
                      : postShiftDetail?.location.address
                  }`}
                </p>
              </div>
            </div>
            <div className="shift-right-side">
              <Tag className="common-tag" color="green">
                {postShiftDetail?.day_type}
              </Tag>
              <p className="shift-time">{timeAgoText}</p>
            </div>
          </div>

          <div className="shift-details">
            <div className="shift-details-left">
              <div className="shift-details-wrap">
                <i className="far fa-calendar-alt" />
                <span className="shift-value">{formattedDate}</span>
              </div>
              <div className="shift-details-wrap">
                <i className="far fa-clock" />
                <span className="shift-value">
                  {postShiftDetail?.start_time} - {postShiftDetail?.end_time}
                </span>
              </div>
            </div>
            <div className="shift-details-right">
              <div className="shift-hour-rate">
                ${postShiftDetail?.hourly_rate} hr{" "}
                <span className="shift-hour-type">
                  {startCase(postShiftDetail?.hourly_rate_type)}
                </span>
              </div>
            </div>
          </div>
          <div className="shit-hours-update">
            <div className="shit-hours-grid">
              <h4>{parseInt(postShiftDetail?.total_hours)}</h4>
              <p>Hours</p>
            </div>
            <div className="shit-hours-grid">
              <h4>{uphours}</h4>
              <p>Unpaid Hours</p>
            </div>
            <div className="shit-hours-grid">
              <h4>$ {postShiftDetail?.hourly_rate}</h4>
              <p>Hourly Rate</p>
            </div>
            <div className="shit-hours-grid">
              <h4>$ {postShiftDetail?.total_rate}</h4>
              <p>Total</p>
            </div>
          </div>
          <div className="shift-tabs">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Requirements" key="1">
                <ul>
                  {companyDetail?.additionalRequirements.map((ar, i) => (
                    <li key={`${ar}`}>{ar}</li>
                  ))}
                </ul>
              </TabPane>
              <TabPane tab="Services" key="2">
                <ul>
                  {companyDetail?.services.map((ser, i) => (
                    <li key={`${ser._id}`}>{ser.name}</li>
                  ))}
                </ul>
              </TabPane>
              <TabPane tab="Equipment" key="3">
                <ul>
                  {companyDetail?.equipment.map((eq, i) => (
                    <li key={`${eq._id}`}>{eq.name}</li>
                  ))}
                </ul>
              </TabPane>
            </Tabs>
          </div>
          <div className="shit-testing-time">
            <h6>Testing Time</h6>
            <ul>
              <li>{companyDetail?.testingTime}</li>
            </ul>
          </div>
          <div className="shift-overview-footer">
            <Button
              className="alt-btn-stye"
              onClick={() => {
                setOpenPostShiftModal(false);
                // navigate("/dashboard");
                resetStates();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="main-btn-stye"
              onClick={updatePostShift}
              disabled={loading ? true : false}
            >
              {loading && <Spin fullscreen />} Post Shift
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ShiftOverview;
