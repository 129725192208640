import React from 'react';

const PostShiftIcon = () => (
    
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.2877 18.6309H12.0183C11.0033 18.6309 10.1816 19.4525 10.1816 20.4675V28.7163H16.2877V18.6309V18.6309Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.56 11.3003H18.1111C17.0961 11.3003 16.2744 12.122 16.2744 13.137V28.7004H22.3805V13.137C22.3805 12.122 21.575 11.3003 20.56 11.3003Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.661 21.3701H22.3916V28.7008H28.4977V23.2068C28.4816 22.1918 27.6599 21.3701 26.661 21.3701Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.5004 36.1109H24.1671C32.2227 36.1109 35.4449 32.8887 35.4449 24.8331V15.1664C35.4449 7.11089 32.2227 3.88867 24.1671 3.88867H14.5004C6.44488 3.88867 3.22266 7.11089 3.22266 15.1664V24.8331C3.22266 32.8887 6.44488 36.1109 14.5004 36.1109Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default PostShiftIcon;