import React from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import useForgotPasswordHook from "./useForgotPasswordHook";
import { getFieldClassName } from "components/GeneralComponents/Utils";
import { ErrorDisplay } from "components/GeneralComponents/Utils";
import CommonForm from "views/CommonForm";
import { Link } from "react-router-dom";
import "./ForgotPassword.scss";
import { Spin } from "antd";

function ForgotPassword() {
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    signin,
    loading,
  } = useForgotPasswordHook();

  return (
    <CommonForm>
      <div className="forgot-form-wrap">
        <h2 className="form-title">Forgot Password</h2>
        <Form
          className="forgot-form common-form-content"
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <Label className="form-label" for="email">
              Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={getFieldClassName("email", errors, touched)}
            />
            <ErrorDisplay name="email" errors={errors} touched={touched} />
          </FormGroup>
          <Button
            color="primary"
            className="common-submit-btn"
            type="submit"
            disabled={loading}
          >
            {loading && <Spin fullscreen/>} Forgot Password
          </Button>
          <div className="form-footer-account-link">
            <Link to={signin} className="footer-redirect-link" type="Sign Up">
              Back to Sign In
            </Link>
          </div>
        </Form>
      </div>
    </CommonForm>
  );
}

export default ForgotPassword;
