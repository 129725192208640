import React from 'react';

const LogOutIcon = () => (
    
    <svg width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 18.8321L9.99913 26.8314V20.8319H27.9976V16.8322H9.99913V10.8328L0 18.8321Z" fill="currentColor"/>
        <path d="M21.9996 0.831123C19.6348 0.824564 17.2922 1.2873 15.1076 2.19253C12.923 3.09775 10.9397 4.42748 9.27264 6.10467L12.1004 8.93241C14.7442 6.28865 18.2599 4.83078 21.9996 4.83078C25.7392 4.83078 29.2549 6.28865 31.8987 8.93241C34.5425 11.5762 36.0004 15.0919 36.0004 18.8315C36.0004 22.5712 34.5425 26.0869 31.8987 28.7307C29.2549 31.3745 25.7392 32.8323 21.9996 32.8323C18.2599 32.8323 14.7442 31.3745 12.1004 28.7307L9.27264 31.5584C12.6704 34.9581 17.19 36.832 21.9996 36.832C26.8092 36.832 31.3288 34.9581 34.7265 31.5584C38.1262 28.1607 40 23.6411 40 18.8315C40 14.022 38.1262 9.50236 34.7265 6.10467C33.0594 4.42748 31.0761 3.09775 28.8915 2.19253C26.7068 1.2873 24.3643 0.824564 21.9996 0.831123Z" fill="currentColor"/>
    </svg>

);

export default LogOutIcon;