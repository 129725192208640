import React, { useEffect, useState, useRef } from "react";
import { Card, Avatar } from "antd";
import { EnvironmentFilled } from "@ant-design/icons";
import { startCase } from "lodash";
import dayjs from "dayjs";

const LocumProfileCard = ({ locumUserData }) => {
  const {
    first_name,
    last_name,
    profile_photo,
    bio,
    createdAt,
    ahpra,
    language,
    special_interests,
    travel,
    abn,
    qualification,
    location,
    is_vaccinated
  } = locumUserData;

  const priLanguage = language[0]?.language;

  const locationStr =
    location?.locationName && location?.address
      ? `${location?.locationName}, ${location?.address}`
      : location?.locationName
      ? location?.locationName
      : location?.address
      ? location?.address
      : "";

  const [contentHeight, setContentHeight] = useState(0);

  const headerRef = useRef(null);
  const descDetailsRef = useRef(null);

  const calculateHeight = () => {
    const windowHeight = window.innerHeight;

    // Get the height of the locum-profile-header-wrap
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;

    // Get the padding of ant-drawer-header and ant-drawer-body
    const drawerHeader = document.querySelector('.ant-drawer-header');
    const drawerBody = document.querySelector('.ant-drawer-body');
    const drawerHeaderPadding = drawerHeader ? parseFloat(window.getComputedStyle(drawerHeader).paddingTop) + parseFloat(window.getComputedStyle(drawerHeader).paddingBottom) : 0;
    const drawerBodyPadding = drawerBody ? parseFloat(window.getComputedStyle(drawerBody).paddingTop) + parseFloat(window.getComputedStyle(drawerBody).paddingBottom) : 0;

    // Calculate the remaining height
    const remainingHeight = windowHeight - (headerHeight + drawerHeaderPadding + drawerBodyPadding + 80);
    
    // Set the height for the locum-profile-desc-details
    setContentHeight(remainingHeight);

    
    console.log("Total Height:", window.innerHeight);
    console.log("header height:", window.headerHeight);
  };

  useEffect(() => {
    // Delay the calculation to ensure elements are fully rendered
    const timeoutId = setTimeout(() => {
      calculateHeight();
    }, 100); // 100ms delay

    // Recalculate on window resize
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
      clearTimeout(timeoutId); // Cleanup timeout
    };
  }, []);

  return (
    <Card>
      <div ref={headerRef} className="locum-profile-header-wrap locum-section-seperate">
        <div className="locum-profile-header-left">
          <span className="locum-profile-img">
            <Avatar src={profile_photo} />
          </span>
          <div className="locum-profile-details">
            <h6 className="locum-profile-user-name">
              {`${startCase(first_name)} ${startCase(last_name)}`}
            </h6>
            <span className="locum-profile-location">
              {locationStr && (
                <>
                  <EnvironmentFilled />
                  <span className="spec-location">{locationStr}</span>
                </>
              )}
            </span>
          </div>
        </div>
        <div className="locum-profile-header-right">
          <span className="locum-profile-date-label">Locum since:</span>
          <span className="locum-profile-date">
            {dayjs(createdAt).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div ref={descDetailsRef} className="locum-profile-desc-details" style={{ height: `${contentHeight}px`}}>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Bio</h6>
          {bio}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Qualifications</h6>
          {qualification || "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">APHRA #</h6>
          {ahpra || "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">ABN</h6>
          {abn || "-"}
      </div>
        {/* <div className="profile-sec">
          <h6 className="locum-subtitle">Expiry</h6>
          I'm not going to fail, a valuable lesson has been learned
        </div> */}
        <div className="profile-sec">
          <h6 className="locum-subtitle">Primary Language</h6>
          {priLanguage || "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Secondary Language</h6>
          {language.slice(1).length > 0 ? language.slice(1).map((lang, i) => (
            <React.Fragment key={`${lang}-${i}`}>
              <span>{lang?.language}</span>
              {i < language.length - 2 && ", "}
            </React.Fragment>
          )) : "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Therapeutically Endorsed</h6>
          {special_interests?.therapeutically_endored ? special_interests?.therapeutically_endored === true ? "Yes" : 'No' : "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Willing to Travel</h6>
          {travel ? travel === true ? "Yes" : "No" : "-"}
        </div>
        <div className="profile-sec">
          <h6 className="locum-subtitle">Is vaccinated</h6>
          {is_vaccinated ? is_vaccinated === true ? "Yes" : "No" : "-"}
      </div>
      </div>
  </Card>
  );
};

export default LocumProfileCard;
