/*eslint-disable no-unused-vars */
/*eslint-disable array-callback-return */
import { Modal, Radio, Select, Space, Upload } from "antd";
import { get_api } from "api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Label } from "reactstrap";
import Map from "views/Map";
import { ReactComponent as CustomArrowIcon } from "../../assets/img/dropdown-icon.svg";
import deleteIcon from "../../assets/img/delete-icon.svg";
import uploadIcon from "../../assets/img/upload-icon.svg";
import popularIcon from "../../assets/img/popular-icon.svg";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useAuth } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import { post_api } from "api";
import { getFirebaseUser } from "firebaseConfig";
import { put_api } from "api";

const { Option } = Select;

const useCompanyProfileSetupHook = () => {
  const { setCompanyProfile, userData, companyProfile } = useAuth();
  const navigate = useNavigate();
  // const [current, setCurrent] = useState(userData?.companyProfileDetails?.last_completed_step || 0);
  const [current, setCurrent] = useState(
    userData?.companyProfileDetails?.last_completed_step ||
    Number(localStorage.getItem("profile_step")) ||
    0
  );
  const [countryData, setCountryData] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [discountCode, setDiscountCode] = useState('');
  const [discountCodeMessage, setDiscountCodeMessage] = useState({});
  const [isVerfiedDiscountCode, setIsVerfiedDiscountCode] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const canceledSubscription = companyProfile?.pricingPlan?.subscriptionStatus === 'canceled' || companyProfile?.pricingPlan?.invoiceStatus === 'failed' //|| companyProfile?.pricingPlan?.subscriptionStatus === 'trialing'

  const initCompanyProfileObj = {
    pricingPlan: {
      plan: "year",
      discountCode: null,
    },
    companyName: companyProfile?.companyName || null,
    location: companyProfile?.location || {
      lat: -33.86882,
      long: 151.2093,
      address: "",
      locationName: "",
    },
    contact: companyProfile?.contact || {
      email: userData?.email,
      phone: "",
      website: "",
    },
    language: companyProfile?.language || ["", ""],
    equipment: companyProfile?.equipment || [],
    services: companyProfile?.services || [],
    software: companyProfile?.software || [],
    testingTime: companyProfile?.testingTime || "30-mins",
    isVaccinated: companyProfile?.isVaccinated || false,
    logo: "",
    additionalRequirements: companyProfile?.additionalRequirements || [""],
  }

  const [companyProfileObj, setCompanyProfileObj] = useState({ ...initCompanyProfileObj, ...userData?.companyProfileDetails });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const {
    pricingPlan,
    companyName,
    location,
    contact,
    language,
    equipment,
    services,
    software,
    testingTime,
    isVaccinated,
    logo,
    additionalRequirements,
  } = companyProfileObj;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);


  useEffect(() => {
    if (userData?.companyProfileDetails?.companyLogo && userData?.companyProfileDetails?.companyLogo !== "https://optom-connect-dev.s3.ap-southeast-2.amazonaws.com/default_images/optom_default.png") {
      setFileList([
        {
          uid: '-1', // Unique identifier
          name: 'uploaded-image.png', // File name (can be dynamic)
          status: 'done', // File upload status (done indicates it's already uploaded)
          url: userData?.companyProfileDetails?.companyLogo, // URL of the existing file
        },
      ])
    }
  }, [userData?.companyProfileDetails?.companyLogo]);

  // Helper function to convert file to base64 for preview purposes
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        reject(new Error("File is undefined or null"));
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handlePreview = async (file) => {
    if (userData?.companyProfileDetails?.companyLogo) {
      const src = file.url || (await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      }));
      const img = new Image();
      img.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(img.outerHTML);
    } else {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewVisible(true);
    }
  };
  const handleOpenPaymentForm = () => {
    setShowPaymentForm(true);
  };

  const handleClosePaymentForm = () => {
    setShowPaymentForm(false);
  };

  const handleComplete = async (value) => {
    if (loadingSpinner) return; // Prevent multiple clicks while loading
    setLoadingSpinner(true); // Start loading

    try {
      if (pricingPlan?.plan !== "free") {
        const getProductByType = allProducts.find(
          (ap) => ap?.interval === value
        );
        setSelectedProduct(getProductByType);

        const { data } = await post_api("stripe/subscription", {
          productId: getProductByType.productId,
        });
        localStorage.setItem("payment_url", true);
        window.location.href = data.checkoutSession;
      }

      // await createCompanyProfile(); // Ensure this executes properly
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingSpinner(false); // Stop loading
    }
  };

  const createCompanyProfile = async () => {
    try {
      const formData = new FormData();
      // Object.entries(pricingPlan).forEach(([key, value]) => {
      //   formData.append(`pricingPlan[${key}]`, value);
      // });
      formData.append("companyName", companyName);
      Object.entries(location).forEach(([key, value]) => {
        if (value !== null) {
          formData.append(`location[${key}]`, value);
        }
      });
      Object.entries(contact).forEach(([key, value]) => {
        if (value !== null) {
          formData.append(`contact[${key}]`, value);
        }
      });

      const removeEmptyLang = language.filter((lang) => lang !== "");
      removeEmptyLang.forEach((val, index) => {
        formData.append(`language[${index}]`, val);
      });
      equipment.forEach((val, index) => {
        formData.append(`equipment[${index}]`, val);
      });
      services.forEach((val, index) => {
        formData.append(`services[${index}]`, val);
      });
      software.forEach((val, index) => {
        formData.append(`software[${index}]`, val);
      });

      const removeEmptyAR = additionalRequirements.filter((adr) => adr !== "");
      removeEmptyAR.forEach((val, index) => {
        formData.append(`additionalRequirements[${index}]`, val);
      });
      formData.append("testingTime", testingTime);
      formData.append("isVaccinated", isVaccinated);

      if (logo) {
        formData.append("logo", logo);
      }

      let updatedData, updatedMessage
      if (canceledSubscription) {
        const { data, message } = await put_api("company-profile", formData);
        updatedData = data
        updatedMessage = message
      } else {
        const { data, message } = await post_api("company-profile", formData);
        updatedData = data
        updatedMessage = message
      }

      setCompanyProfile(JSON.stringify(updatedData.profile));
      localStorage.setItem("company_profile", JSON.stringify(updatedData.profile));
      localStorage.setItem("user", JSON.stringify(updatedData.user));

      // Show success toast message only once
      toast.success(updatedMessage);

      // Run getFirebaseUser in the background without blocking
      getFirebaseUser(updatedData.user, updatedData.profile)
        .then(() => {
          console.log("Firebase user updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating Firebase user:", error);
        });

      // Redirect to dashboard after successful creation/update
      navigate("/dashboard");

      return updatedData;
    } catch (err) {
      toast.error(err.message);
    }
  };



  const getLanguages = async () => {
    try {
      const { data } = await get_api("language/get-all-languages");
      setCountryData(data.allLanguages);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getServices = async () => {
    try {
      const { data } = await get_api("service/all");
      setAllServices(data.allServices);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getSoftwares = async () => {
    try {
      const { data } = await get_api("software/all");
      setAllSoftwares(data.allSoftwares);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getEquipments = async () => {
    try {
      const { data } = await get_api("equipment/all");
      setAllEquipments(data.allEquipments);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getAllProducts = async () => {
    try {
      const { data } = await get_api("stripe/all-products");
      setAllProducts(data?.allStripeProducts);
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    getLanguages();
    getAllProducts();
    getServices();
    getSoftwares();
    getEquipments();
  }, []);

  const handlePricingPlanChange = (e) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      pricingPlan: {
        ...prev.pricingPlan,
        discountCode: e.target.value,
      },
    }));
  };

  const handleCompanyChange = (e) => {
    const isCompanyNameValid = e.target.value.length <= 100;
    if (isCompanyNameValid) {
      setCompanyProfileObj((prev) => ({
        ...prev,
        companyName: e.target.value,
      }));
    }
  };

  const handleContactChange = (key, val) => {
    // Validations
    const isPhoneValid = key === "phone" && /^\d{0,10}$/.test(val);
    const isWebsiteOrEmailValid =
      ["website", "email"].includes(key) && val.length <= 100;

    if (isPhoneValid || isWebsiteOrEmailValid) {
      setCompanyProfileObj((prev) => ({
        ...prev,
        contact: {
          ...prev.contact,
          [key]: val,
        },
      }));
    }
  };
  const discountValue = async (code) => {
    try {
      const payload = {
        couponCode: code,
      };
      const { message } = await post_api(`stripe-coupon`, payload);
      setIsVerfiedDiscountCode(true);
      setDiscountCodeMessage({ message, type: 'success' })
      toast.success(message);
    } catch (error) {
      setIsVerfiedDiscountCode(false);
      console.log('error: ', error);
      setDiscountCodeMessage({ message: error.message, type: 'error' })
      toast.error(error.message);
    }
    // Add your logic here for applying the discount
  };

  const renderPricingPlan = () => {
    const monthlyProduct = allProducts.find((ap) => ap.interval === "month");
    const yearlyProduct = allProducts.find((ap) => ap.interval === "year");

    return (
      <>
        <div className="price-discount-wrapper">
          <div className="discount-header">
            <Label className="common-stepper-label start-free-label">Your Next Locum is Waiting. Start Free Today!</Label>
            <span className="common-stepper-sub-text">Start your <b>28-day free trial</b> today!</span>
            {companyProfile?.pricingPlan?.invoiceStatus === 'failed' && (
              <div
                className="common-stepper-label error-message"
                style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}
              >
                Payment Failed Reason: {companyProfile?.pricingPlan?.failureReason}, Please retry payment
              </div>
            )}
            {/* <span className="discount-sub-header">
              Annual - $420 <br />
              $99 one time admin fee.
            </span> */}
          </div>
          {/* <div className="input-with-button" style={{ position: 'relative', width: '100%' }}>
            <Input
              type="text"
              name="discountCode"
              id="discountCode"
              className="stepper-input-field"
              placeholder="Enter discount code"
              onChange={(e) => {
                setDiscountCode(e.target.value)
                setDiscountCodeMessage({})
              }}
              style={{ paddingRight: '80px', width: '100%' }} // Add right padding to make space for the button
            />
            <Button
              className="btn btn-primary"
              onClick={() => discountValue(discountCode)}
              style={{
                position: 'absolute',
                right: '0',
                top: '0',
                height: '100%',
                borderRadius: '0 5px 5px 0', // Adjust button border to fit inside input
              }}
            >
              Apply
            </Button>
          </div> */}
          {discountCodeMessage.message &&
            <span style={{ color: discountCodeMessage.type === 'error' ? 'red' : 'green' }}>
              {discountCodeMessage.message}
            </span>
          }
        </div>
        <div className="discount-option-wrapper">
          <Radio.Group
            // defaultValue="year"
            onChange={(e, v) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                pricingPlan: {
                  ...prev.pricingPlan,
                  plan: e.target.value,
                },
              }));
            }}
          >
            {/* <Radio.Button value="free">
              <div className="discount-opt">
                <span className="discount-type">Free</span>
                <h3 className="discount-price">$0<span>/month</span></h3>
                <span className="discount-trial">7 Days free trial</span>
                <span className="select-plan-label">Select Plan</span>
                <span className="separator"></span>
                <div className="discount-feature-listing">
                  <h6 className="benefits-title">Features & Benefits:</h6>
                  <ul className="feature-listing">
                    <li>Efficient Shift Filling</li>
                    <li>Analytics Insights</li>
                    <li>Comprehensive Dashboard</li>
                    <li>Dedicated Support</li>
                  </ul>
                </div>
                <span className="select-plan-label">Select Plan</span>
              </div>
            </Radio.Button> */}
            <label class="ant-radio-button-wrapper css-dev-only-do-not-override-1x0dypw">
              {/* <span class="ant-radio-button">
                <input
                  class="ant-radio-button-input"
                  type="radio"
                  value="month"
                />
                <span class="ant-radio-button-inner"></span>
              </span> */}
              <span>
                <div className="discount-opt">
                  <span className="discount-type">Monthly</span>
                  <h3 className="discount-price">
                    ${monthlyProduct?.unit_amount ? monthlyProduct?.unit_amount : 0}
                    <span>/month</span>
                  </h3>
                  <span className="separator"></span>
                  <div className="discount-feature-listing">
                    <ul className="feature-listing">
                      <li className="bold-listing">
                        28 Day Free Trial – <span>No Upfront Payment</span>
                      </li>
                      <li>Cancel Anytime</li>
                      <li>Full Access to All Features</li>
                      <li>Dedicated Support</li>
                    </ul>
                  </div>
                  <Button
                    className="select-plan-label"
                    onClick={() => handleComplete("month")}
                    disabled={loadingSpinner || companyProfile?.pricingPlan?.invoiceStatus === 'failed'}
                  >
                    Start Free
                  </Button>
                </div>
              </span>
            </label>

            <label class="ant-radio-button-wrapper css-dev-only-do-not-override-1x0dypw">
              {/* <span class="ant-radio-button ant-radio-button-checked">
                <input
                  class="ant-radio-button-input"
                  type="radio"
                  value="year"
                />
                <span class="ant-radio-button-inner"></span>
              </span> */}
              <span>
                <div className="discount-opt">
                  <span className="discount-type">
                    Annual
                    <div className="annual-discount-label">
                      <img src={popularIcon} alt="most popular icon" /> Most
                      popular
                    </div>
                  </span>
                  <h3 className="discount-price annual-discount-price">
                    ${yearlyProduct?.unit_amount ? yearlyProduct?.unit_amount / 12 : 0}
                    <span>/month x12</span>
                  </h3>
                  <span className="discount-trial">
                    $492/year billed annually
                  </span>
                  <span className="separator"></span>
                  <div className="discount-feature-listing">
                    <ul className="feature-listing">
                      <li className="bold-listing">
                        28 Day Free Trial – Pay Nothing Today
                      </li>
                      <li>3 Months Free – Best Value</li>
                      <li>Full Access to All Features</li>
                      <li>Dedicated Support</li>
                    </ul>
                  </div>
                  <Button
                    className="select-plan-label"
                    onClick={() => handleComplete("year")}
                    disabled={loadingSpinner || companyProfile?.pricingPlan?.invoiceStatus === 'failed'}
                  >
                    Start Free & Save
                  </Button>
                </div>
              </span>
            </label>
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderCompany = () => {
    return (
      <>
        <Label className="common-stepper-label">Company Name</Label>
        <Input
          type="text"
          name="company_name"
          id="company_name"
          className="stepper-input-field"
          placeholder="Company/Store Name"
          onChange={handleCompanyChange}
          value={companyName || ""}
        />
      </>
    );
  };

  const handleSearch = async (value) => {
    setInputValue(value);
    if (value.length > 2) {
      try {
        const response = await get_api(`location/get-locations/${value}`);
        const locations = response.data.locations.predictions.map(
          (prediction) => ({
            label: prediction.description,
            value: prediction.place_id,
          })
        );
        setOptions(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const getLocationDetails = async (placeId) => {
    try {
      const response = await get_api(
        `location/get-location-details/${placeId}`
      );
      const locationData = response.data.locationDetails;
      const cityName = locationData.address_components.find((component) =>
        component.types.includes("locality")
      )["long_name"];
      const stateName = locationData.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )["long_name"];

      setCompanyProfileObj((prev) => ({
        ...prev,
        location: {
          lat: locationData.geometry.location.lat,
          long: locationData.geometry.location.lng,
          address: `${cityName}, ${stateName}`,
          locationName: locationData.name,
        },
      }));
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleChangeMap = async (val, event) => {
    const selectedOption = options.find((option) => option.value === val);
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
    await getLocationDetails(val);
  };

  const handleClearSelection = () => {
    setInputValue("");
    setOptions([]);
  };

  const renderLocation = () => {
    return (
      <>
        <Label className="common-stepper-label">Location</Label>
        <div className="location-stepper">
          <Select
            showSearch
            className="common-dropdown common-antd-component"
            value={inputValue || undefined}
            placeholder="Search a location"
            onSearch={handleSearch}
            onChange={handleChangeMap}
            filterOption={false}
            notFoundContent={null}
            suffixIcon={
              inputValue && (
                <CloseCircleOutlined onClick={handleClearSelection} />
              )
            }
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <div className="location-map-wrapper">
            <Map lat={location.lat} long={location.long} />
          </div>
        </div>
      </>
    );
  };

  const renderContact = () => {
    return (
      <>
        <div className="common-field-group">
          <Label className="common-stepper-label">Email</Label>
          <Input
            type="text"
            name="email"
            id="email"
            className="stepper-input-field"
            placeholder="Enter email"
            onChange={(e) => handleContactChange("email", e.target.value)}
            value={contact?.email || ""}
          />
        </div>
        <div className="common-field-group">
          <Label className="common-stepper-label">Phone</Label>
          <Input
            type="number"
            name="phone"
            id="phone"
            className="stepper-input-field"
            placeholder="Enter phone"
            onChange={(e) => handleContactChange("phone", e.target.value)}
            value={contact?.phone || ""}
            maxLength={10}
          />
        </div>
        <div className="common-field-group">
          <Label className="common-stepper-label">Website</Label>
          <Input
            type="text"
            name="website"
            id="website"
            className="stepper-input-field"
            placeholder="Enter website"
            onChange={(e) => handleContactChange("website", e.target.value)}
            value={contact?.website || ""}
          />
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!companyProfile?.language && !userData?.companyProfileDetails?.language) {
      const englishCountry = countryData.find(
        (country) => country.language === "English"
      );
      if (englishCountry) {
        setCompanyProfileObj((prev) => ({
          ...prev,
          language: [englishCountry._id, ""],
        }));
      }
    }
  }, [companyProfile?.language, countryData]);

  useEffect(() => {
    if (companyProfileObj?.additionalRequirements.length <= 0) {
      setCompanyProfileObj((prev) => ({
        ...prev,
        additionalRequirements: [...additionalRequirements, ""],
      }));
    }
  }, [])

  const handlePrimaryChange = (val) => {
    setCompanyProfileObj((prev) => {
      const secondaryLanguages = prev.language
        .slice(1)
        .filter((lang) => lang !== val);
      return {
        ...prev,
        language: [val, ...secondaryLanguages],
      };
    });
  };

  const handleSecondaryChange = (vals) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: [
        prev.language[0],
        ...vals.filter((val) => val !== prev.language[0]),
      ],
    }));
  };

  const handleAddSelectBox = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: [...prev.language, ""],
    }));
  };

  const handleRemoveLanguage = (id) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      language: prev.language.filter((lang) => lang !== id),
    }));
  };

  const renderLanguages = () => {
    return (
      <>
        <div className="language-selector-wrapper common-field-group">
          <label className="common-stepper-label">Primary Language</label>
          <Select
            className="common-dropdown common-antd-component"
            placeholder="Primary Language"
            optionFilterProp="children"
            onChange={handlePrimaryChange}
            value={language[0]}
            suffixIcon={<CustomArrowIcon />}
          >
            {countryData.map((country) => (
              <Option key={country._id} value={country._id}>
                <img
                  src={country.flag}
                  alt=""
                  style={{ width: "20px", marginRight: "8px" }}
                />
                {country.language}
              </Option>
            ))}
          </Select>
        </div>

        {language.slice(1).length > 0 && (
          <div className="language-selector-wrapper common-field-group">
            <label className="common-stepper-label">Secondary Language</label>
          </div>
        )}

        {language.slice(1).map((secondaryLanguage, index) => {
          return (
            <div
              key={index + 1}
              className="language-selector-wrapper common-field-group"
            >
              {language.slice(1).length > 1 && (
                <Button
                  className="delete-icon-wrap"
                  color="link"
                  onClick={() => handleRemoveLanguage(secondaryLanguage)}
                >
                  <img src={deleteIcon} alt="delete icon" />
                </Button>
              )}
              <Select
                className="common-dropdown common-antd-component"
                placeholder="Secondary Languages"
                optionFilterProp="children"
                onChange={(value) =>
                  handleSecondaryChange(
                    language
                      .slice(1)
                      .map((lang, idx) => (idx === index ? value : lang))
                  )
                }
                value={secondaryLanguage || undefined}
                suffixIcon={<CustomArrowIcon />}
              >
                {countryData.map((country) => (
                  <Option
                    key={country._id}
                    value={country._id}
                    disabled={
                      country._id === language[0] ||
                      language.slice(1).includes(country._id)
                    }
                  >
                    <img
                      src={country.flag}
                      alt=""
                      style={{ width: "20px", marginRight: "8px" }}
                    />
                    {country.language}
                  </Option>
                ))}
              </Select>

            </div>
          );
        })}

        <div className="add-other-field">
          <Button
            className="add-field-btn"
            color="link"
            onClick={handleAddSelectBox}
            disabled={language.includes("")}
          >
            Add <span className="add-icon">+</span>
          </Button>
        </div>
      </>
    );
  };

  const renderEquipment = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Equipment Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={equipment}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allEquipments.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                equipment: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderService = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Services Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={services}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allServices.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                services: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderSoftware = () => {
    return (
      <>
        <div className="common-dropdown-stepper">
          <Label className="common-stepper-label">Software Provided</Label>
          <Select
            className="common-dropdown common-antd-component"
            value={software}
            mode="multiple"
            placeholder="Select all that apply"
            suffixIcon={<CustomArrowIcon />}
            options={allSoftwares.map((service) => ({
              value: service._id,
              label: service.name,
            }))}
            onChange={(val) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                software: val,
              }));
            }}
            showSearch={false}
          />
        </div>
      </>
    );
  };

  const renderTestingTime = () => {
    return (
      <>
        <div className="common-radio-option">
          <Label className="common-stepper-label">Testing Time</Label>
          <Radio.Group
            className="common-single-select"
            onChange={(e) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                testingTime: e.target.value,
              }));
            }}
            value={testingTime}
          >
            <Space className="radio-wrapper" direction="vertical">
              <Radio value={"20-mins"}>20 Min</Radio>
              <Radio value={"30-mins"}>30 Min</Radio>
              {/* <Radio value={"60-mins"}>1 Hour</Radio> */}
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  };

  const renderVaccination = () => {
    return (
      <>
        <div className="common-radio-option">
          <Label className="common-stepper-label">Vaccination Required</Label>
          <Radio.Group
            className="common-single-select"
            onChange={(e) => {
              setCompanyProfileObj((prev) => ({
                ...prev,
                isVaccinated: e.target.value,
              }));
            }}
            value={isVaccinated}
          >
            <Space className="radio-wrapper" direction="vertical">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  };

  const handleChange = ({ fileList }) => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      logo: fileList[0]?.originFileObj,
    }));
    setFileList(fileList)
  };

  const handleRemove = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      logo: null,
    }));
    setFileList([]);
  };
  const handleChangeModal = ({ fileList }) => setFileList(fileList);

  const handleRemoveModal = () => {
    setFileList([]);
  };
  const uploadButton = (
    <button className="logo-upload-btn" type="button">
      <span className="btn-upload-icon">
        <img src={uploadIcon} alt="upload Logo" />
      </span>
      <div className="btn-upload-label">Upload Logo</div>
    </button>
  );
  // Handle file validation before upload (avoid error triggers)
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt10M = file.size / 1024 / 1024 < 10;

    if (!isJpgOrPng || !isLt10M) {
      // Return false to reject file upload (no error tooltip)
      return false;
    }
    return true;
  };
  // This is a custom request handler to prevent the POST request that may fail
  const customRequest = ({ onSuccess }) => {
    // Simply call onSuccess to mock success and prevent actual POST request
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // const handlePreview = async (file) => {
  //   const src = file.url || (await new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file.originFileObj);
  //     reader.onload = () => resolve(reader.result);
  //   }));
  //   const img = new Image();
  //   img.src = src;
  //   const imgWindow = window.open(src);
  //   imgWindow?.document.write(img.outerHTML);
  // };

  const renderUploadLogo = () => {
    return (
      <>
        <div>
          <Label className="common-stepper-label">Logo</Label>
          <div className="logo-uploader-wrapper">
            <Upload
              listType="picture-circle"
              customRequest={customRequest} // Prevent actual POST request
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={handleRemove}
              maxCount={1}
              accept="image/png, image/jpeg, image/jpg"
              onPreview={handlePreview}
              fileList={fileList}
            >
              {uploadButton}
            </Upload>
            <span className="upload-img-info">
              Image must be no larger than 10 Mb*
            </span>
          </div>
        </div>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
        >
          <img alt="logo preview" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  };

  const handleAddTextbox = () => {
    setCompanyProfileObj((prev) => ({
      ...prev,
      additionalRequirements: [...additionalRequirements, ""],
    }));
  };

  const handleTextboxChange = (index, value) => {
    const updatedRequirements = additionalRequirements.map((item, i) => {
      if (i === index) {
        return value;
      }
      return item;
    });
    setCompanyProfileObj((prev) => ({
      ...prev,
      additionalRequirements: updatedRequirements,
    }));
  };

  const handleRemoveAdditionalRequirement = (arr, idx) => {
    if (idx !== -1) {
      const newArr = [...arr];
      newArr.splice(idx, 1);
      setCompanyProfileObj((prev) => ({
        ...prev,
        additionalRequirements: newArr,
      }));
    }
  };
  const renderAdditionalRequirements = () => {
    return (
      <>
        <div className="add-requirement-field">
          <Label className="common-stepper-label">
            Additional Requirements
          </Label>
          {additionalRequirements?.map((requirement, index) => (
            <div className="add-required-field-wrapper" key={index}>
              {additionalRequirements.length > 1 && (
                <Button
                  className="delete-icon-wrap"
                  color="link"
                  onClick={() =>
                    handleRemoveAdditionalRequirement(
                      additionalRequirements,
                      index
                    )
                  }
                >
                  <img src={deleteIcon} alt="delete icon" />
                </Button>
              )}
              <div className="add-requirement-input-wrap">
                <Input
                  key={index}
                  type="text"
                  className="stepper-input-field"
                  placeholder="Enter Additional Requirements"
                  value={requirement}
                  onChange={(e) => handleTextboxChange(index, e.target.value)}
                  maxLength={250}
                />
              </div>
            </div>
          ))}
        </div>
        {additionalRequirements.length < 3 && (
          <div className="add-other-field">
            <Button
              className="add-field-btn"
              color="link"
              onClick={handleAddTextbox}
            >
              Add <span className="add-icon">+</span>
            </Button>
          </div>
        )
        }
      </>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const steps = [
    {
      title: "Company",
      miniTitle: "Company Name",
      content: renderCompany(),
    },
    {
      title: "Store Location",
      miniTitle: "Location",
      content: renderLocation(),
    },
    {
      title: "Company Contact Details",
      miniTitle: "Contact",
      content: renderContact(),
    },
    {
      title: "Languages",
      miniTitle: "Languages",
      content: renderLanguages(),
    },
    {
      title: "Equipment Provided",
      miniTitle: "Equipment",
      content: renderEquipment(),
    },
    {
      title: "Services Provided",
      miniTitle: "Services",
      content: renderService(),
    },
    {
      title: "Software Used",
      miniTitle: "Software",
      content: renderSoftware(),
    },
    {
      title: "Testing Time",
      miniTitle: "Testing",
      content: renderTestingTime(),
    },
    {
      title: "Vaccination",
      miniTitle: "Vaccination",
      content: renderVaccination(),
    },
    {
      title: "Company Logo",
      miniTitle: "Logo",
      content: renderUploadLogo(),
    },
    {
      title: "Additional Requirements",
      miniTitle: "Requirements",
      content: renderAdditionalRequirements(),
    },
    {
      title: "Fill Shifts Instantly. Simplify Scheduling",
      miniTitle: "Start Free Trial",
      content: renderPricingPlan(),
    },
  ];

  const next = async () => {
    setCurrent(current + 1);
    localStorage.setItem("profile_step", JSON.stringify(Number(current) + 1));


    const formData = new FormData();
    formData.append("companyName", companyName);

    Object.entries(location).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(`location[${key}]`, value);
      }
    });
    Object.entries(contact).forEach(([key, value]) => {
      console.log('key, value: ', key, value);
      if (value !== null) {
        formData.append(`contact[${key}]`, value);
      }
    });

    const removeEmptyLang = language.filter((lang) => lang !== "");
    removeEmptyLang.forEach((val, index) => {
      formData.append(`language[${index}]`, val);
    });
    equipment.forEach((val, index) => {
      formData.append(`equipment[${index}]`, val);
    });
    services.forEach((val, index) => {
      formData.append(`services[${index}]`, val);
    });
    software.forEach((val, index) => {
      formData.append(`software[${index}]`, val);
    });

    const removeEmptyAR = additionalRequirements.filter((adr) => adr !== "");
    removeEmptyAR.forEach((val, index) => {
      formData.append(`additionalRequirements[${index}]`, val);
    });
    formData.append("testingTime", testingTime);
    formData.append("isVaccinated", isVaccinated);

    if (logo) {
      formData.append("companyLogo", logo);
    }

    formData.append("last_completed_step", current + 1);

    const { data } = await put_api("company-profile/user-profile", formData);
    console.log('data: ', data);
  };

  const prev = () => {
    setCurrent(current - 1);
    localStorage.setItem("profile_step", JSON.stringify(Number(current) - 1));
  };

  const skip = () => {
    setCurrent(current + 1);
    localStorage.setItem("profile_step", JSON.stringify(Number(current) + 1));
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const isCurrentStepEmpty = () => {
    switch (current) {
      case 0:
        return !companyName;
      default:
        return false;
    }
  };


  useEffect(() => {
    if (canceledSubscription) {
      const pricingIndex = steps.map(el => el.miniTitle).lastIndexOf("Start Free Trial");
      setCurrent(pricingIndex)
      localStorage.setItem("profile_step", JSON.stringify(pricingIndex));
    }
  }, [canceledSubscription, companyProfile, steps])

  return {
    current,
    next,
    prev,
    skip,
    steps,
    items,
    handleClosePaymentForm,
    handleComplete,
    showPaymentForm,
    selectedProduct,
    createCompanyProfile,
    discountCode,
    isVerfiedDiscountCode,
    isCurrentStepEmpty,
    canceledSubscription,
    companyProfile,
    loadingSpinner
  };
};

export default useCompanyProfileSetupHook;
