import { Col, Drawer, Empty, Row, Table } from "antd";
import React, { useLayoutEffect, useState, useRef } from "react";
import useShiftRequestsListHook from "./useShiftRequestsListHook";
import Loader from "components/Loader/Loader";
import { isEmpty } from "lodash";
import LocumProfileCard from "./LocumProfileCard";
import { Button } from "reactstrap";

const ShiftRequestsList = () => {
  const {
    shiftsColumns,
    shiftData,
    isLoading,
    requestsColumns,
    requestedUsersList,
    requestedUserLoading,
    locumUserData,
    locumUserLoading,
    open,
    setOpen,
    getShiftRequests,
    locumUserRecord,
    handleInitiateChat
  } = useShiftRequestsListHook();

  const NoDataComponent = () => (
    <div className="empty-table-content" style={{ height: `${noDatatableHeight}px`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Empty />
    </div>
  );

  const [tableHeight, setTableHeight] = useState(240); // Initial height
  const [noDatatableHeight, setNoDataTableHeight] = useState(240);
  const headerRef = useRef(null);
  const contentRef = useRef(null);

  // You can add logic here to dynamically update the table height
  const updateTableHeight = () => {
    if (window.innerWidth > 767) {
      //content spacing
      const contentMarginTop = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).marginTop) : 0;
      const contentPaddingBottom = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).paddingBottom) : 0;
      const contentOuterHeight = contentMarginTop + contentPaddingBottom;

      //page title spaceing
      const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;
      const titleMarginBottom = headerRef.current ? parseFloat(getComputedStyle(headerRef.current).marginBottom) : 0;
      const pageTitleWrap = headerHeight + titleMarginBottom;

      //calculate table header spacing and height
      const tableWrapper = document.querySelector('.ant-table-wrapper');
      const tableHeader = document.querySelector('.ant-table-header, .ant-table-thead');

      const tableWrapperPaddingTop = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingTop) : 0;
      const tableWrapperPaddingBottom = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingBottom) : 0;
      const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0;
      const tableWrapperPadding = tableWrapperPaddingTop + tableWrapperPaddingBottom;

      const newHeight = window.innerHeight - contentOuterHeight - pageTitleWrap - tableHeaderHeight - tableWrapperPadding - 4
      const noDataTableHeight = window.innerHeight - contentOuterHeight - pageTitleWrap - 4

      setTableHeight(newHeight);
      setNoDataTableHeight(noDataTableHeight);

      //dynamic height of table under tabs
      const tableBodies = document.querySelectorAll('.ant-table-body');
      tableBodies.forEach((tableBody) => {
        tableBody.style.height = `${tableHeight}px`;
        tableBody.style.overflowY = 'auto';
      });
    } else {
      // Reset to default height or any specific height if required
      const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = '';
        tableBody.style.overflowY = '';
      }
    }
  };

  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();

    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [shiftData, requestedUsersList, tableHeight]);

  const scrollXValue = window.innerWidth < 991 ? 1000 : undefined;

  // Custom title for the Drawer with the Message button
  const drawerTitle = (
    <div className="locum-info-drawer-header">
      <span>Locum Info</span>
      {!isEmpty(locumUserRecord) && (
        <Button
          className="main-btn-stye"
          size="small"
          key={locumUserRecord?.postShiftData?._id}
          onClick={() =>
            handleInitiateChat(
              locumUserRecord?.chatRoomData,
              locumUserRecord?.locumUser?.userId?._id,
              locumUserRecord
            )
          }
        >
          Message
        </Button>
      )}
    </div>
  );

  return (
    <div ref={contentRef} className="content mb-0">
      <h2 ref={headerRef} className="page-main-title">Shift Request</h2>
      <Row gutter={[20,20]}>
        <Col xl={16} md={14} xs={24}>
          <div className="cmn-card-content">
            {shiftData?.length === 0 ? (
              <NoDataComponent />
            ) : (
              <Table
                columns={shiftsColumns}
                dataSource={shiftData}
                rowKey="_id"
                className="shift-req-tbl"
                pagination={false}
                loading={isLoading}
                scroll={shiftData.length > 0 ? { x: scrollXValue, y: tableHeight } : undefined}
                rowClassName={() => 'custom-row'}
                onRow={(record) => ({
                  onClick: () => getShiftRequests(record._id), 
                  style: { cursor: "pointer" } 
                })}
              
              />
            )}
          </div>  
        </Col>
        <Col xl={8} md={10} xs={24}>
          <div className="cmn-card-content">
            {requestedUsersList?.length === 0 ? (
              <NoDataComponent />
            ) : (
              <Table
                columns={requestsColumns}
                dataSource={requestedUsersList}
                rowKey="_id"
                pagination={false}
                loading={requestedUserLoading}
                className="ltd-column-table"
                scroll={{ y: tableHeight }}
                locale={{ emptyText: <NoDataComponent /> }}
              />
            )}
          </div>  
        </Col>
        <Drawer
          title={drawerTitle}
          placement="right"
          closable={true}
          onClose={() => setOpen(false)}
          open={open}
        >
          {/* {!isEmpty(locumUserRecord) && (
              <Button
                className="green-tbl-btn"
                size="small"
                key={locumUserRecord?.postShiftData?._id}
                onClick={() =>
                  handleInitiateChat(
                    locumUserRecord?.chatRoomData,
                    locumUserRecord?.locumUser?.userId?._id,
                    locumUserRecord
                  )
                }
              >
                Message
            </Button>
            )} */}
          <div className="locum-info-wrap">
            {locumUserLoading ? (
              <Loader />
            ) : isEmpty(locumUserData) ? (
              <Empty
                className="empty-nodata"
                description={<span>No Locum Data</span>}
              />
            ) : (
              <LocumProfileCard locumUserData={locumUserData} />
            )}
          </div>
        </Drawer>
      </Row>
    </div>
  );
};

export default ShiftRequestsList;
