/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Space, Switch, Tag } from "antd";
import { get_api } from "api";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import usePostShiftHook from "./usePostShiftHook";
import { toHoursAndMinutes } from "components/GeneralComponents/Utils";
import { pick, startCase } from "lodash";
import { delete_api } from "api";
import ShiftContext from "./ShiftContext";
import { useAuth } from "context/AuthContext";
import isBetween from "dayjs/plugin/isBetween";
import { put_api } from "api";

dayjs.extend(isBetween);

const usePostShiftListHook = (setOpenPostShiftModal) => {
  const { unPaidHoursList, resetStates } = usePostShiftHook();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shiftData, setShiftData] = useState(null);
  const { refreshShifts } = useContext(ShiftContext);
  const { isAuthenticated } = useAuth();
  const [loadingSwitchId, setLoadingSwitchId] = useState(null);

  const getAllPostShifts = async () => {
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        const { data: shiftData } = await get_api("post-shifts");
        setData(shiftData);
        setIsLoading(false);
        return data;
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    refreshShifts(); // Initial fetch
  }, []);

  const warning = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this post shift?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deletePostShift(id);
      },
      onCancel: () => {
        console.log("Cancel clicked..");
      },
    });
  };
  const deletePostShift = async (id) => {
    try {
      const { data, message } = await delete_api(`post-shifts/${id}`);
      await getAllPostShifts();
      toast.success(message);
      refreshShifts();
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updatePostShift = async (record, checked) => {
    try {
      const psData = record;
      const obj = pick(psData, [
        "_id",
        "date",
        "start_time",
        "end_time",
        "unpaid_hours",
        "hourly_rate_type",
        "hourly_rate",
        "isPosted",
      ]);
      const { data, message } = await put_api("post-shifts/update", {
        ...obj,
        isPosted: checked,
      });
      toast.success(message);
      resetStates();
      refreshShifts();
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSwitchChange = async (record, checked) => {
    setLoadingSwitchId(record._id);
    try {
      await updatePostShift(record, checked);
    } finally {
      setLoadingSwitchId(null);
    }
  };

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        const currentDateTime = dayjs();
        const eventDate = dayjs(text);
        const eventStartTime = dayjs(
          `${dayjs(text).format("YYYY-MM-DD")} ${record.start_time}`,
          "YYYY-MM-DD h:mm A"
        );
        const eventEndTime = dayjs(
          `${dayjs(text).format("YYYY-MM-DD")} ${record.end_time}`,
          "YYYY-MM-DD h:mm A"
        );
        const isToday = eventDate.isSame(currentDateTime, "day");
        const isUpcoming = isToday && eventStartTime.isAfter(currentDateTime);
        const isOngoing =
          isToday &&
          currentDateTime.isBetween(eventStartTime, eventEndTime, null, "[]");

        return (
          <div className="fields-wrapper" key={record._id}>
            <p>{dayjs(text).locale("en").format("ddd DD MMM YYYY")}</p>
            <p>
              {record.start_time} - {record.end_time}
            </p>
            {isUpcoming && (
              <Tag className="upcoming-tag" color="green">
                Upcoming
              </Tag>
            )}
            {isOngoing && (
              <Tag className="ongoing-tag" color="blue">
                Ongoing
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => <div key={record._id}>{text}</div>,
      align: "center",
    },
    {
      title: "Unpaid",
      dataIndex: "unpaid_hours",
      key: "unpaid_hours",
      render: (text, record) => {
        const unPaidHour = unPaidHoursList.find((uph) => uph?.value === text);
        const uphours = toHoursAndMinutes(unPaidHour?.value.split("-")[0]);
        return <div key={record._id}>{uphours}</div>;
      },
      align: "center",
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      key: "hourly_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {text}</div>;
      },
      align: "center",
    },
    {
      title: "Total Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {text}</div>;
      },
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return <div key={record._id}>{startCase(text)}</div>;
      },
      align: "center",
    },
    {
      title: "Posted",
      dataIndex: "isPosted",
      key: "isPosted",
      render: (text, record) => {
        return (
          <div key={record._id}>
            <Switch
              className="cmn-switch"
              checked={text}
              loading={loadingSwitchId === record._id}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => handleSwitchChange(record, checked)}
              style={{
                backgroundColor: text ? "#023A28" : undefined,
              }}
            />
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Day",
      dataIndex: "day_type",
      key: "day_type",
      render: (text, record) => {
        return (
          <div
            style={{ display: "inline-flex", justifyContent: "center" }}
            key={record._id}
          >
            <Tag className="common-tag" color="green">
              {text}
            </Tag>
          </div>
        );
      },
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          <i
            className="fas fa-edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShiftData(record);
              setOpenPostShiftModal(true);
            }}
          />
          <i
            className="fas fa-trash-alt text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => warning(record._id)}
          />
        </Space>
      ),
      align: "right",
    },
  ];

  return {
    columns,
    data,
    isLoading,
    shiftData,
    setShiftData,
    refreshShifts: getAllPostShifts,
  };
};

export default usePostShiftListHook;
