import { Col, Empty, Row, Table } from "antd";
import React, { useLayoutEffect, useState, useRef } from "react";
import useLocumListHook from "./useLocumsListHook";
import LocumProfileCard from "components/ShiftRequests/LocumProfileCard";
import { isEmpty } from "lodash";
import Loader from "components/Loader/Loader";
import { Button } from "reactstrap";

const LocumsList = () => {
  const { columns, data, isLoading, locumUserData, locumUserLoading, handleInitiateChat, selectedLocumUserData } =
    useLocumListHook();

  const [tableHeight, setTableHeight] = useState(240); // Initial height
  const [contentHeight, setContentHeight] = useState(240); // Initial height for locum-content-wrap
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const locumHeader = useRef(null);

  const NoDataComponent = () => (
    <div className="empty-table-content" style={{ height: `${tableHeight}px`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Empty />
    </div>
  );

  const updateTableHeight = () => {
    if (window.innerWidth > 767) {
      //common elements height and spacing
      const contentMarginTop = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).marginTop) : 0;
      const contentPaddingBottom = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).paddingBottom) : 0;
      const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;
      const titleMarginBottom = headerRef.current ? parseFloat(getComputedStyle(headerRef.current).marginBottom) : 0;
      
      //combination of content height and margin
      const contentOuterHeight = contentMarginTop + contentPaddingBottom;

      //calculate table header spacing and height
      const tableWrapper = document.querySelector('.ant-table-wrapper');
      const tableHeader = document.querySelector('.ant-table-header, .ant-table-thead');

      const tableWrapperPaddingTop = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingTop) : 0;
      const tableWrapperPaddingBottom = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingBottom) : 0;
      const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0;
      const tableWrapperPadding = tableWrapperPaddingTop + tableWrapperPaddingBottom;

      const ContentPadTop = document.querySelector('.locum-info-wrap')?.paddingTop || 0;
      const ContentPadBot = document.querySelector('.locum-info-wrap')?.PaddingBottom || 0;
      const contentPadding = ContentPadTop + ContentPadBot;

      //locum header spacing and margin
      const locumBioHeight = locumHeader.current ? locumHeader.current.getBoundingClientRect().height : 0;
      const locumBioPadTop = locumHeader.current ? parseFloat(getComputedStyle(locumHeader.current).paddingTop) : 0;
      const locumBioPadBot = locumHeader.current ? parseFloat(getComputedStyle(locumHeader.current).paddingBottom) : 0;
      const locumBioHead = locumBioPadTop + locumBioPadBot;

      const availableTableHeight = window.innerHeight - headerHeight - titleMarginBottom - contentOuterHeight - tableWrapperPadding - tableHeaderHeight - 2;
      const availableHeight = window.innerHeight - contentOuterHeight - headerHeight - titleMarginBottom - contentPadding - locumBioHeight - locumBioHead - 8;

      setTableHeight(availableTableHeight);
      setContentHeight(availableHeight);

      //dynamic height of table under tabs
      const tableBodies = document.querySelectorAll('.ant-table-body');
      tableBodies.forEach((tableBody) => {
        tableBody.style.height = `${tableHeight}px`;
        tableBody.style.overflowY = 'auto';
      });
    } else {
      // Reset to default height or any specific height if required
      const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = '';
        tableBody.style.overflowY = '';
      }
    }  
  };

  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();

    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [data, tableHeight]);

  const scrollXValue = window.innerWidth < 991 ? 1200 : 1200;

  return (
    <div ref={contentRef} className="content mb-0">
      <h2 ref={headerRef} className="page-main-title">Locums</h2>
      <Row gutter={[20,20]}>
        <Col lg={16} md={14} xs={24}>
          <div className="cmn-card-content">
            {data?.length === 0 ? (
              <NoDataComponent />
            ) : (
              <Table
                columns={columns}
                dataSource={data}
                rowKey="_id"
                loading={isLoading}
                pagination={false}
                scroll={data.length > 0 ? {x: scrollXValue, y: tableHeight } : undefined}
              />
            )}
          </div>  
        </Col>
        <Col lg={8} md={10} xs={24}>
          <div className="locum-info-wrap">
            <div ref={locumHeader} className="d-flex justify-content-between align-items-center locum-bio-head">
              <h6> Locum Info </h6>
              {!isEmpty(selectedLocumUserData) && (
                <Button
                  className="main-btn-stye"
                  size="small"
                  key={selectedLocumUserData?.postShiftData?._id}
                  onClick={() =>
                    handleInitiateChat(
                      selectedLocumUserData?.chatRoomData,
                      selectedLocumUserData?.locumUser?.userId?._id,
                      selectedLocumUserData
                    )
                  }
                >
                  Message
                </Button>
              )}
            </div>
            <div className="locum-content-wrap">
              {locumUserLoading ? (
                <Loader />
              ) : isEmpty(locumUserData) ? (
                <div className="locum-empty-div" style={{ height: `${contentHeight}px`}}>
                  <Empty className="empty-nodata" description={<span>No Locum Data</span>} />
                </div>
              ) : (
                <LocumProfileCard locumUserData={locumUserData} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LocumsList;
