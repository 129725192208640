import React from 'react';

const ShiftRequestIcon = () => (
  
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8892 36.3613H25.7781C32.2548 36.3613 33.4148 33.7674 33.7531 30.6096L34.9614 17.7207C35.3964 13.7896 34.2687 10.5835 27.3892 10.5835H11.2781C4.39865 10.5835 3.27087 13.7896 3.70587 17.7207L4.91421 30.6096C5.25254 33.7674 6.41254 36.3613 12.8892 36.3613Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.8896 10.5836V9.29472C12.8896 6.44305 12.8896 4.13916 18.0452 4.13916H20.623C25.7785 4.13916 25.7785 6.44305 25.7785 9.29472V10.5836" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.5548 21.8616V23.4727C22.5548 23.4888 22.5548 23.4888 22.5548 23.5049C22.5548 25.261 22.5387 26.6949 19.3326 26.6949C16.1426 26.6949 16.1104 25.2772 16.1104 23.521V21.8616C16.1104 20.2505 16.1104 20.2505 17.7215 20.2505H20.9437C22.5548 20.2505 22.5548 20.2505 22.5548 21.8616Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M34.8807 18.6392C31.159 21.3458 26.9057 22.9569 22.5557 23.5047" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.22168 19.0737C7.84668 21.5548 11.9389 23.0532 16.1117 23.5204" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default ShiftRequestIcon;