import axios from "axios";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailRegex } from "variables/regex";
import RoutesName from "variables/route";
import * as yup from "yup";

const useForgotPasswordHook = () => {
  const { signin } = RoutesName;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Required")
      .matches(emailRegex, "Invalid email"),
  });

  const handleForgotPassword = async (values) => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}auth/forgot-password`,
        values
      );
      toast.success(resp.data.message);
      setLoading(false);
      navigate(signin);
    } catch (err) {
      console.log("🚀 ~ handleForgotPassword ~ err:", err);
      setLoading(false);
      toast.error(err.response.data.message);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await handleForgotPassword(values);
    },
  });

  const { handleSubmit, handleBlur, handleChange, values, touched, errors } =
    formik;

  return {
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    signin,
    handleSubmit,
    loading,
  };
};

export default useForgotPasswordHook;
