import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import he from 'he';

const Guidelines = () => {
  const [content, setContent] = useState("");

  const getGuidelines = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}cms-pages/guidelines`
      );
      setContent(response.data.cmsPages.content);
    } catch (err) {
      console.log("🚀 ~ getGuidelines ~ err:", err);
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getGuidelines();
  }, []);

  return (
    <div className="cms-page-content-wrapper">
      <div className="cms-content-sec">
        <div className="cms-content">
          {/* <h2 className="cms-page-title">Privacy Policy</h2> */}
          <div dangerouslySetInnerHTML={{ __html: he.decode(content) }} />
        </div>
      </div>
    </div>
  );
};

export default Guidelines;
