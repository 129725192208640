import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { get, startCase } from "lodash";
import { Avatar } from "antd";
import routes from "../../routes"; // Adjust the path as needed
import { useAuth } from "../../context/AuthContext";
import LogOutIcon from "./sideBarIcons/LogOutIcon";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebar = React.useRef();
  const { logout } = useAuth();
  const companyProfile =
    typeof props?.companyProfile === "string"
      ? JSON.parse(props?.companyProfile)
      : props?.companyProfile;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  const planType =
    companyProfile?.pricingPlan?.productIntervalType === "month"
      ? "Monthly"
      : companyProfile?.pricingPlan?.productIntervalType === "year"
      ? "Yearly"
      : "";

  const subStatus = companyProfile?.pricingPlan?.cancelAt
    ? "Cancelled"
    : get(companyProfile, "pricingPlan.subscriptionStatus", "") !== "trialing"
    ? startCase(get(companyProfile, "pricingPlan.subscriptionStatus", ""))
    : "7 Days Free Trial";

    const handleLogout = () => {
      logout();
    };
  return (
    <div
      className="sidebar admin-sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="sidebar-user-details">
        <span className="user-img-wrap">
          <Avatar
            size={"large"}
            src={get(companyProfile, "companyLogo")}
            alt="logo"
          />
        </span>
        <div className="sidebar-logged-user">
          <h3 className="logged-user-name">{`${get(
            companyProfile,
            "companyName"
          )}`}</h3>
          <span className="logged-user-pack">
            {`Plan: ${planType} - ${subStatus}`}
          </span>
        </div>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {routes.map((route, key) => {
            if (!route.inSidebar) {
              return null;
            }
            const IconComponent = route.icon;
            return (
              <li
                className={
                  activeRoute(route.path) + (route.pro ? " active-pro" : "")
                }
                key={key}
              >
                <NavLink to={route.path} className="nav-NavLink">
                  <IconComponent /> {/* Render the icon component */}
                  <p>{route.name}</p>
                </NavLink>
              </li>
            );
          })}
          <li className="logout-menu">
            <a className="nav-NavLink" href="" onClick={handleLogout}>
              <LogOutIcon />
              Logout
            </a>
          </li>
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
