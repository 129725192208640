// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap {
  max-width: 400px;
  width: 100%;
}
.common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap .forgot-form .form-group {
  margin-bottom: 35px;
}
.common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap .forgot-form .form-footer-account-link {
  margin-top: 40px;
}
@media (max-width: 1600px) {
  .common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap .forgot-form .form-footer-account-link {
    margin-top: 35px;
  }
}
@media (max-width: 1399px) {
  .common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap .forgot-form .form-group {
    margin-bottom: 30px;
  }
  .common-form-wrapper .form-content-wrap .form-wrap .forgot-form-wrap .forgot-form .form-footer-account-link {
    margin-top: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/forgot_password/ForgotPassword.scss"],"names":[],"mappings":"AAGY;EACI,gBAAA;EACA,WAAA;AAFhB;AAIoB;EACI,mBAAA;AAFxB;AAIoB;EACI,gBAAA;AAFxB;AAQI;EAKoB;IACI,gBAAA;EAV1B;AACF;AAgBI;EAKoB;IACI,mBAAA;EAlB1B;EAoBsB;IACI,gBAAA;EAlB1B;AACF","sourcesContent":[".common-form-wrapper{\n    .form-content-wrap{\n        .form-wrap{\n            .forgot-form-wrap{\n                max-width: 400px;\n                width: 100%;\n                .forgot-form{\n                    .form-group{\n                        margin-bottom: 35px;\n                    }\n                    .form-footer-account-link{\n                        margin-top: 40px;\n                    }\n                }\n            }\n        }\n    }\n    @media (max-width: 1600px){\n        .form-content-wrap{\n            .form-wrap{\n                .forgot-form-wrap{\n                    .forgot-form{\n                        .form-footer-account-link{\n                            margin-top: 35px;\n                        }\n                    }\n                }\n            }\n        }\n    }\n    @media (max-width: 1399px){\n        .form-content-wrap{\n            .form-wrap{\n                .forgot-form-wrap{\n                    .forgot-form{\n                        .form-group{\n                            margin-bottom: 30px;\n                        }\n                        .form-footer-account-link{\n                            margin-top: 30px;\n                        }\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
