import React, { useEffect, useState } from 'react';
import { Steps } from "antd";
import { Button, Col, Row, Spinner } from "reactstrap";
import useCompanyProfileSetupHook from "./useCompanyProfileSetupHook";
import "./CompanyProfileSetup.scss";
import PaymentMethodForm from "./PaymentMethodForm";
import classNames from "classnames";
import { get_api } from 'api';
import { useNavigate } from 'react-router-dom';

const { Step } = Steps;

const CompanyProfileSetup = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {
    current,
    skip,
    next,
    prev,
    steps,
    items,
    handleClosePaymentForm,
    handleComplete,
    showPaymentForm,
    selectedProduct,
    createCompanyProfile,
    isCurrentStepEmpty,
    canceledSubscription,
    discountCode,
    isVerfiedDiscountCode,
    companyProfile,
    loadingSpinner
  } = useCompanyProfileSetupHook();

  useEffect(() => {
    const companySetUpWrapper = () => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (windowWidth < 768) {
        const navBarSpace = document.querySelector('.inner-main-navbar');
        const navBarSpacePadTop = navBarSpace ? parseFloat(getComputedStyle(navBarSpace).paddingTop) : 0;
        const navBarSpacePadBot = navBarSpace ? parseFloat(getComputedStyle(navBarSpace).paddingBottom) : 0;
        const navBarSpacetotal = navBarSpacePadTop + navBarSpacePadBot;

        const companySetupSpace = document.querySelector('.company-setup-wrapper');
        const companySetupPadTop = companySetupSpace ? parseFloat(getComputedStyle(companySetupSpace).paddingTop) : 0;
        const companySetupPadBot = companySetupSpace ? parseFloat(getComputedStyle(companySetupSpace).paddingBottom) : 0;
        const companySetupPadding = companySetupPadTop + companySetupPadBot;

        const setupSpace = document.querySelector('.setup-stepper-sec');
        const setupSpaceMarTop = setupSpace ? parseFloat(getComputedStyle(setupSpace).marginTop) : 0;

        const profileStepHeader = document.querySelector('.profile-stepper-header');
        const profileStepTotalHeight = profileStepHeader ? profileStepHeader.offsetHeight : 0;

        const stepperFooterSpace = document.querySelector('.stepper-footer');
        const stepperFooterHeight = stepperFooterSpace ? stepperFooterSpace.offsetHeight : 0;
        const stepperFooterTotal = stepperFooterHeight;

        const calculatedHeight = windowHeight - navBarSpacetotal - companySetupPadding - setupSpaceMarTop - profileStepTotalHeight - stepperFooterTotal - 0;

        const formWrapElement = document.querySelector('.stepper-content-section');
        if (formWrapElement) {
          formWrapElement.style.height = `${calculatedHeight}px`;
        }
      }
    };

    companySetUpWrapper();
    window.addEventListener('resize', companySetUpWrapper);

    const handleEnterKey = (event) => {
      if (event.keyCode === 13 && !isCurrentStepEmpty()) {
        event.preventDefault();
        next();
      }
    };

    document.addEventListener('keydown', handleEnterKey);

    return () => {
      window.removeEventListener('resize', companySetUpWrapper);
      document.removeEventListener('keydown', handleEnterKey);
    };
  }, [isCurrentStepEmpty, next]);

  // if (loading) {
  //   return (
  //     <div className="loading-screen">
  //       <Spinner color="primary" />
  //     </div>
  //   );
  // }

  return (
    <div id="companystepper" className="content company-setup-wrapper">
      <div className="container">
        <Row>
          <Col xs={12}>
            <div className="setup-stepper-sec">
              <div id="stepperhead" className="profile-stepper-header">
                <h2 className="stepper-title">{steps[current].title}</h2>
                <div className="stepper-header-details">
                  <h3 className="stepper-sub-title">
                    {steps[current].miniTitle}
                  </h3>
                  {!["Company Name", "Start Free Trial"].includes(steps[current].miniTitle) && (
                    <Button
                      className="stepper-skip-btn"
                      color="link"
                      onClick={skip}
                      disabled={current === steps.length - 1 || isCurrentStepEmpty()}
                    >
                      Skip For Now
                    </Button>
                  )}
                </div>
                <Steps current={current} progressDot className="custom-steps">
                  {items.map((item) => (
                    <Step key={item.key} />
                  ))}
                </Steps>
              </div>
              <div
                id="stepperbody"
                className={classNames("stepper-main-body", {
                  "pricing-step": steps[current].miniTitle === "Start Free Trial",
                  "language-step": steps[current].miniTitle === "Languages",
                  "requirements-step": steps[current].miniTitle === "Requirements",
                })}
              >
                <div className="stepper-content-section">
                  <PaymentMethodForm
                    discountCode={discountCode}
                    isVerfiedDiscountCode={isVerfiedDiscountCode}
                    visible={showPaymentForm}
                    onClose={handleClosePaymentForm}
                    selectedProduct={selectedProduct}
                    createCompanyProfile={createCompanyProfile}
                  />
                  <>{steps[current].content}</>
                </div>
                <div className="stepper-footer">
                  {current > 0 && (
                    <Button className="alt-btn-stye" onClick={prev} disabled={loadingSpinner || canceledSubscription}>
                      Previous
                    </Button>
                  )}
                  {current < steps.length - 1 && (
                    <Button className="main-btn-stye" onClick={next} disabled={isCurrentStepEmpty()}>
                      Next
                    </Button>
                  )}
                  {/* {current == steps.length - 1 && (
                    <Button
                      className="main-btn-stye"
                      onClick={handleComplete}
                      disabled={loadingSpinner || companyProfile?.pricingPlan?.invoiceStatus === 'failed'}
                    >
                      Complete
                    </Button>
                  )} */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanyProfileSetup;
