/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Button,
  Card,
  Col,
  List,
  Row,
  Skeleton,
  Input,
  Spin,
  Drawer,
  Upload,
  Popconfirm,
  Empty,
  Badge,
} from "antd";
import { ReactComponent as MyCustomIcon } from "assets/img/search-icon.svg";
import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import useMessagesHook from "./useMessagesHook";
import "./Messages.scss";
import archive from "assets/img/archive-icon.svg";
import deleteIcon from "assets/img/chat-delete-icon.svg";
import {
  UserOutlined,
  EnvironmentFilled,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import cameraIcon from "assets/img/camera-icon.svg";
import SendIcon from "assets/img/send-icon.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isEmpty } from "lodash";
import LocumProfileCard from "./LocumProfileCard";
import noMessageIcon from "assets/img/message-remove.svg";
import backArrow from "assets/img/back-arrow.svg";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { getFirebaseUserImageByUserId } from "firebaseConfig";

dayjs.extend(utc);
dayjs.extend(timezone);

const { Search } = Input;

const Messages = () => {
  const {
    chatUsersList,
    isLoading,
    locumUserData,
    open,
    setOpen,
    handleSendMessage,
    formatMessageTime,
    handleDeleteChat,
    receiverObj,
    receiverLoader,
    allMessages,
    text,
    setText,
    companyProfile,
    handleAddChatsRecord,
    handleArchive,
    archiveCount,
    archiveView,
    setArchiveView,
    getAllChatUsersList,
    onSearch,
    handleUpload,
  } = useMessagesHook();

  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const chatEndRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [userProfiles, setUserProfiles] = useState({}); // Store the profiles in a state object
  useEffect(() => {
    const fetchUserProfiles = async () => {
      const profileData = {};
      
      for (const user of chatUsersList) {
        const picture = await getFirebaseUserImageByUserId(user.chat_user_id);
        profileData[user.chat_user_id] = picture; // Map user_id to the fetched profile picture
      }
      
      setUserProfiles(profileData);
    };

    fetchUserProfiles();
  }, [chatUsersList]); // Fetch profiles when `users` changes
  const updateDivHeight = () => {
    const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;
    const contentMargin = headerRef.current ? parseFloat(getComputedStyle(headerRef.current).marginBottom) : 0;
    const contentMarginTop = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).marginTop) : 0;
    const contentPaddingBottom = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).paddingBottom) : 0;

    const newHeight = window.innerHeight - contentMarginTop - contentPaddingBottom - headerHeight - contentMargin - 0; // Adjust the -40 as needed
    setDivHeight(newHeight);
    setIsSmallScreen(window.innerWidth > 767);
  };

  useLayoutEffect(() => {
    updateDivHeight();
    window.addEventListener("resize", updateDivHeight);

    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  const locationStr =
    locumUserData?.location?.locationName && locumUserData?.location?.address
      ? `${locumUserData?.location?.locationName}, ${locumUserData?.location?.address}`
      : locumUserData?.location?.locationName
      ? locumUserData?.location?.locationName
      : locumUserData?.location?.address
      ? locumUserData?.location?.address
      : "";

  return (
    <div ref={contentRef} className="content mb-0">
      <h2 ref={headerRef} className="page-main-title">
        Messages
      </h2>
      <Row gutter={[20,20]}>
        <Col
          xl={8}
          md={9}
          xs={24}
          className="messages-list-wrapper common-card-style"
          style={isSmallScreen ? { height: divHeight } : {}}
        >
          <Card>
            <Search
              placeholder="Search"
              onSearch={onSearch}
              enterButton={<MyCustomIcon />}
            />
            <div className="archived-wrapper">
              {!archiveView ? (
                <div
                  className="archived-div"
                  onClick={async () => {
                    setArchiveView(true);
                    getAllChatUsersList(true);
                  }}
                >
                  <img
                    className="archive-icon"
                    src={archive}
                    alt="archive icon"
                  />{" "}
                  Archived
                </div>
              ) : (
                <div
                  className="archived-div"
                  onClick={async () => {
                    setArchiveView(false);
                    getAllChatUsersList(false);
                  }}
                >
                  <img className="back-icon" src={backArrow} alt="back arrow" />
                  Back
                </div>
              )}
              <span>{archiveCount}</span>
            </div>
            <List
              className="demo-loadmore-list"
              loading={isLoading}
              itemLayout="horizontal"
              // loadMore={loadMore}
              dataSource={chatUsersList}
              renderItem={(item) => (
                <List.Item
                  className={`${
                    receiverObj?.chat_id === item?.chat_id && "user-highlighted"
                  }`}
                  actions={[
                    <div className="list-action-wrap">
                      <div className="list-action-btn">
                        <Button onClick={() => handleArchive(item?.chat_id)}>
                          <img src={archive} alt="archive icon" />
                        </Button>
                        <Popconfirm
                          title="Delete Chat"
                          description="Are you sure to delete chat?"
                          icon={
                            <QuestionCircleOutlined
                              style={{
                                color: "red",
                              }}
                            />
                          }
                          onConfirm={() => handleDeleteChat(item)}
                        >
                          <Button>
                            <img src={deleteIcon} alt="delete icon" />
                          </Button>
                        </Popconfirm>
                      </div>
                      {!!item?.unreadCount && (
                        <Badge
                          className="msg-count"
                          count={item?.unreadCount}
                        />
                      )}
                    </div>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={
                        <div
                          className={`${
                            item?.userStatus === "online" ? "online" : "offline"
                          }`}
                        >
                          {userProfiles[item.chat_user_id] && (
                            <Avatar src={userProfiles[item.chat_user_id]} />
                          )}
                          {/* <Avatar src={item.chat_user_profile_picture} /> */}
                        </div>
                      }
                      title={<>{item?.chat_user_name}</>}
                      description={<span>{item?.relativeTime}</span>}
                      onClick={() => handleAddChatsRecord(item.chat_id, item)}
                      style={{ cursor: "pointer" }}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col
          xl={16}
          md={15}
          xs={24}
          className="message-body common-card-style"
          style={isSmallScreen ? { height: divHeight } : {}}
        >
          <Card>
            {receiverLoader && <Spin />}
            {!isEmpty(receiverObj) ? (
              <div className="message-details-wrapper">
                <div className="message-header-part">
                  <div
                    className="author-details"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpen(true)}
                  >
                    {userProfiles[receiverObj.chat_user_id] && (
                        <Avatar
                        src={userProfiles[receiverObj.chat_user_id]}
                        icon={<UserOutlined />}
                      />
                      )}
                    {/* <Avatar
                      src={receiverObj.chat_user_profile_picture}
                      icon={<UserOutlined />}
                    /> */}
                    <div className="author-bio-details">
                      <div className="author-description">
                        <h4>{receiverObj.chat_user_name}</h4>
                        <span className="author-location">
                          {locationStr && (
                            <>
                              <EnvironmentFilled /> 
                              <span className="spec-location">{locationStr}</span>
                            </>
                          )}
                        </span>
                      </div>
                      {/* <div className="message-date-amount">
                        <span className="message-date">17 April 2024</span>
                        <span className="pricing">$50</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="message-details">
                    <div className="message-action">
                      <Button
                        className="message-action"
                        onClick={() => handleArchive(receiverObj?.chat_id)}
                      >
                        <img src={archive} alt="archive icon" />
                      </Button>
                      <Popconfirm
                        title="Delete Chat"
                        description="Are you sure to delete chat?"
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                        onConfirm={() => handleDeleteChat(receiverObj)}
                      >
                        <Button className="message-action">
                          <img src={deleteIcon} alt="delete icon" />
                        </Button>
                      </Popconfirm>
                    </div>
                  </div>
                </div>
                <div className="message-chat-screen">
                  <div className="user-chat-area">
                    <div className="history-chat">
                      <ul className="chat-html-ul">
                        {allMessages.length === 0 ? (
                          <div className="no-messages">
                            <img src={noMessageIcon} alt="no msg icon" />
                            <h5 className="no-msg-title">
                              No messages to display!!
                            </h5>
                          </div>
                        ) : (
                          allMessages.map((msg, idx) => {
                            return (
                              <li
                                key={idx}
                                className={`list-group-item ${
                                  msg.sender_id === companyProfile.userId
                                    ? "right-side-cls"
                                    : "left-side-cls"
                                }`}
                              >
                                {msg.sender_id !== companyProfile.userId && (
                                  <div className="msg-user-img">
                                     {userProfiles[receiverObj.chat_user_id] && (
                                        <Avatar src={userProfiles[receiverObj.chat_user_id]} icon={<UserOutlined />} />
                                      )}

                                    {/* <Avatar
                                      src={
                                        receiverObj.chat_user_profile_picture
                                      }
                                      icon={<UserOutlined />}
                                    /> */}
                                  </div>
                                )}
                                <div className="msg-chat-body">
                                  <p className="chat-msg">{msg.text}</p>
                                  {msg?.image_url && (
                                    <p className="uploaded-img-wrap">
                                      <Zoom>
                                        <img
                                          src={msg.image_url}
                                          alt="uploaded img"
                                        />
                                      </Zoom>
                                    </p>
                                  )}
                                  <span className="msg-time">
                                    {formatMessageTime(msg.time)}
                                  </span>
                                </div>
                              </li>
                            );
                          })
                        )}
                        <div ref={chatEndRef} />
                      </ul>
                    </div>
                  </div>
                </div>
                <form id="submit-chat-form" className="chat-message-submit">
                  <div className="send-chat-div">
                    <div className="chat-message-textarea">
                      <Upload
                        className="msg-file-upload"
                        showUploadList={false}
                        beforeUpload={handleUpload}
                      >
                        <Button>
                          <img src={cameraIcon} alt="camera icon" />
                        </Button>
                      </Upload>
                      <Input.TextArea
                        autoSize={{ minRows: 1.5, maxRows: 2 }}
                        value={text}
                        className="chat-message-input"
                        placeholder="Type a message.."
                        rows="2"
                        name="message"
                        id="message"
                        onPressEnter={handleSendMessage}
                        onChange={(e) => setText(e.target.value)}
                      ></Input.TextArea>
                    </div>
                    <div className="message-submit-btn">
                      <Button
                        type="submit"
                        name="send"
                        value="Send"
                        className="msg-send"
                        onClick={handleSendMessage}
                      >
                        <img src={SendIcon} alt="send icon" />
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <Drawer
          title="Locum Info"
          placement="right"
          closable={true}
          onClose={() => setOpen(false)}
          open={open}
        >
          <div className="locum-info-wrap">
            <LocumProfileCard locumUserData={locumUserData} />
          </div>
        </Drawer>
      </Row>
    </div>
  );
};

export default Messages;
