/*eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { startCase } from "lodash";
import {
  unPaidHoursList,
  toHoursAndMinutes,
} from "components/GeneralComponents/Utils";
import { post_api } from "api";
// import { DbStatus } from "components/GeneralComponents/Utils";
import { get_api } from "api";
import { Badge, Select } from "antd";
import { ReactComponent as DownOutlined } from "../../assets/img/dropdown-icon.svg";

const useShiftsHook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [locumShifts, setLocumShifts] = useState([]);
  const [activeTab, setActiveTab] = useState("accepted");

  const getShiftsByLocumIdAndStatus = useCallback(async (status) => {
    setIsLoading(true);
    try {
      const { data } = await post_api(`my-shift/get-my-shift-by_status`, {
        status,
      });
      setLocumShifts(data.locumShiftData);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div key={record._id} className="tbl-user-details">
          {/* <Avatar src={record?.locumProfile?.profile_photo} size="large" /> */}
          <h6 className="m-0 text-primary text-capitalize">
            {`${startCase(record?.locumUser?.first_name)} ${startCase(
              record?.locumUser?.last_name
            )}`}
          </h6>
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div className="fields-wrapper" key={record._id}>
          <p>{dayjs(text).locale("en").format("ddd DD MMM YYYY")}</p>
          <p>
            {record?.start_time} - {record?.end_time}
          </p>
        </div>
      ),
      align: "center",
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => (
        <div key={record._id}>{record?.total_hours}</div>
      ),
      align: "center",
    },
    {
      title: "Unpaid",
      dataIndex: "unpaid_hours",
      key: "unpaid_hours",
      render: (text, record) => {
        const unPaidHour = unPaidHoursList.find(
          (uph) => uph?.value === record?.unpaid_hours
        );
        const uphours = toHoursAndMinutes(unPaidHour?.value.split("-")[0]);
        return <div key={record._id}>{uphours}</div>;
      },
      align: "center",
    },
    {
      title: "Rate",
      dataIndex: "total_rate",
      key: "total_rate",
      render: (text, record) => {
        return <div key={record._id}>$ {record?.total_rate}</div>;
      },
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return (
          <div key={record._id}>{startCase(record?.hourly_rate_type)}</div>
        );
      },
      align: "right",
    },
  ];
  if (activeTab === "cancelled") {
    columns.push({
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (text, record) => <div key={record._id}>{record.reason}</div>,
      align: "center",
    });
  }
  const [count, setCount] = useState({});

  const getDashboardCount = async (monthYear) => {
    try {
      const { data } = await get_api(`my-shift/calendar/${monthYear}`);
      setCount(data.calenderData);
      return data;
    } catch (err) {
      console.error("Error fetching dashboard count:", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardCount(dayjs().format("DD-MM-YYYY"));
  }, []);

  const dateCellRender = (value) => {
    const dateString = value.format("YYYY-MM-DD");
    const dateData =
      count && count?.calender?.find((item) => item.date === dateString);
    const count2 = dateData ? dateData.count : 0;

    return (
      <div className="events">
        {Array.from({ length: count2 }, (_, index) => (
          <Badge key={index} status="success" />
        ))}
      </div>
    );
  };

  const CustomSelect = ({ value, options, onChange }) => (
    <Select
      value={value}
      onChange={onChange}
      suffixIcon={<DownOutlined />}
      options={options.map((option) => ({ label: option, value: option }))}
    />
  );

  const CustomHeader = ({ value, onChange, type }) => {
    const year = value.year();
    const month = value.month();

    const yearOptions = Array.from({ length: 20 }, (_, i) => year - 10 + i);
    const monthOptions = dayjs.months();

    const onYearChange = (newYear) => {
      const newValue = value.clone().year(newYear);
      onChange(newValue);
    };

    const onMonthChange = (newMonth) => {
      const newValue = value.clone().month(monthOptions.indexOf(newMonth));
      onChange(newValue);
    };

    return (
      <div className="calendar-header-wrapper">
        <CustomSelect
          value={year}
          options={yearOptions}
          onChange={onYearChange}
        />
        <CustomSelect
          value={monthOptions[month]}
          options={monthOptions}
          onChange={onMonthChange}
        />
      </div>
    );
  };

  const onPanelChange = async (value, mode) => {
    await getDashboardCount(dayjs(value).format("DD-MM-YYYY"));
  };

  return {
    columns,
    locumShifts,
    isLoading,
    getShiftsByLocumIdAndStatus,
    activeTab,
    setActiveTab,
    dateCellRender,
    CustomHeader,
    onPanelChange,
    count,
    setLocumShifts,
    setIsLoading,
  };
};

export default useShiftsHook;
