import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import {
  ErrorDisplay,
  getFieldClassName,
} from "components/GeneralComponents/Utils";
import useSignInHook from "./useSignInHook";
import CommonForm from "views/CommonForm";
// import { GoogleLogin } from "@react-oauth/google";
import "./SignIn.scss";
import googleIcon from "assets/img/google-icon.svg";
import FaEye from "assets/img/eye-password-show.svg";
import FaEyeSlash from "assets/img/eye-password-hide.svg";

function SignIn() {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    signup,
    forgotPassword,
    googleLogin,
  } = useSignInHook();

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown => !passwordShown);
  };

  return (
    <CommonForm>
      <div className="signin-form-wrap">
        <h2 className="form-title">Sign In</h2>
        <Form
          className="sign-in-form common-form-content"
          onSubmit={handleSubmit}
        >
          <FormGroup>
            <Label className="form-label" for="email">
              Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.email}
              className={getFieldClassName("email", errors, touched)}
            />
            <ErrorDisplay name="email" errors={errors} touched={touched} />
          </FormGroup>
          <FormGroup>
            <Label className="form-label" for="password">
              Password
            </Label>
            <div className="password-input-container">
              <Input
                type={passwordShown ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.password}
                className={getFieldClassName("password", errors, touched)}
              />
              <span className="password-toggle-icon" onClick={togglePasswordVisiblity}>
                {passwordShown ? <img src={FaEyeSlash} alt="Google sign-in" /> : <img src={FaEye} alt="Google sign-in" />}
              </span>
            </div>
            <ErrorDisplay name="password" errors={errors} touched={touched} />
          </FormGroup>
          <div className="cstm-checkbox">
            <div className="cstm-checkbox-link">
              <Input
                type="checkbox"
                name="remember_me"
                id="remember_me"
                onBlur={handleBlur}
                value={values.remember_me}
                className={getFieldClassName("remember_me", errors, touched)}
              />
              <Label for="remember_me" className="cstm-checkbox-text">
                Remember Me
              </Label>
            </div>
            <Link to={forgotPassword} className="forgot-pass-link">
              Forgot Password?
            </Link>
          </div>
          <div className="signform-footer-option">
            <Button className="common-submit-btn" type="submit" block>
              Sign In
            </Button>
            <span className="form-seperator">OR</span>
            <div className="google-login">
              <Button className="google-signin-btn" onClick={googleLogin}>
                <img src={googleIcon} alt="Google sign-in" />
                Sign in with Google
              </Button>
            </div>
          </div>
          <div className="form-footer-account-link">
            Don't have an account?{" "}
            <Link to={signup} className="footer-redirect-link">
              Click here to Sign Up
            </Link>
          </div>
        </Form>
      </div>
    </CommonForm>
  );
}

export default SignIn;