import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import { post_api } from 'api'; // Adjust the import path according to your project structure
import { useAuth } from 'context/AuthContext';
import { get, startCase } from 'lodash';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Card } from "reactstrap";

const AddForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { userData } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Load user data from localStorage and set as default form values
    console.log(userData)
    if (userData) {
      form.setFieldsValue({
        email: (get(userData, "email", "")),
        first_name: startCase(get(userData, "first_name", "")),
        last_name: startCase(get(userData, "last_name", "")),
        locumId: startCase(get(userData, "id", ""))
      });
    }
  }, [form, userData]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        enquiry_type: 'store',
      };
      const { message } = await post_api('/contact-us', payload);
      toast.success(message);
      form.resetFields();
      form.setFieldsValue({
        email: (get(userData, "email", "")),
        first_name: startCase(get(userData, "first_name", "")),
        last_name: startCase(get(userData, "last_name", "")),
        locumId: startCase(get(userData, "id", ""))
      });
      navigate("/dashboard")
    } catch (error) {
      toast.error(error.message);
      console.error('Form submission error:', error);
    }
    setLoading(false);
  };

  return (
    <div className="content mb-0">
        <div className="page-header-wrap">
          <h2 className="page-main-title">Help</h2>
        </div>
        <Card className="profile-card-wrapper">
            <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
            className="user-profile-body"
            >
                <Row gutter={[20]}>
                    <Col sm={8} xs={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
                            className='card-sub-title'
                        >
                            <Input placeholder="Enter email" className="stepper-input-field"/>
                        </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                        <Form.Item
                            name="first_name"
                            label="First Name"
                            className='card-sub-title'
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input placeholder="Enter first name" className="stepper-input-field"/>
                        </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                        <Form.Item
                            name="last_name"
                            label="Last Name"
                            className='card-sub-title'
                            rules={[{ required: true, message: 'Please input your last name!' }]}
                        >
                            <Input placeholder="Enter last name" className="stepper-input-field"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="message"
                            label="Message"
                            className='card-sub-title'
                            rules={[{ required: true, message: 'Please input your message!' }]}
                        >
                            <Input.TextArea rows={4} placeholder="Enter your message" className="stepper-input-field"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className='profile-page-footer'>
                    <Col span={24}>
                        <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                            <Button
                            className="common-footer-btn cancel-btn-wrap"
                            onClick={() => navigate("/dashboard")}
                            >
                            Cancel
                            </Button>
                            <Button className="common-footer-btn save-btn-wrap" htmlType="submit" loading={loading}>
                                Submit
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Card>
    </div>
  );
};

export default AddForm;
