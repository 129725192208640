import React from 'react';

const HelpIcon = () => (
  
<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.7927 19.6362C19.7438 19.6362 19.7113 19.6362 19.6625 19.6362C19.5811 19.62 19.4671 19.62 19.3694 19.6362C14.649 19.4897 11.0842 15.7785 11.0842 11.2045C11.0842 6.54918 14.8769 2.75653 19.5322 2.75653C24.1876 2.75653 27.9802 6.54918 27.9802 11.2045C27.9639 15.7785 24.3829 19.4897 19.8415 19.6362C19.8252 19.6362 19.809 19.6362 19.7927 19.6362ZM19.5322 5.19815C16.2279 5.19815 13.5259 7.9002 13.5259 11.2045C13.5259 14.46 16.0652 17.0806 19.3043 17.1946C19.3857 17.1784 19.6136 17.1784 19.8252 17.1946C23.0156 17.0481 25.5223 14.4274 25.5386 11.2045C25.5386 7.9002 22.8366 5.19815 19.5322 5.19815Z" fill="currentColor"/>
    <path d="M19.8093 37.4255C16.6189 37.4255 13.4123 36.6116 10.987 34.9839C8.72442 33.4864 7.48734 31.4353 7.48734 29.2054C7.48734 26.9753 8.72442 24.9081 10.987 23.3944C15.8702 20.1551 23.7811 20.1551 28.6317 23.3944C30.878 24.8919 32.1314 26.9428 32.1314 29.1729C32.1314 31.4028 30.8943 33.47 28.6317 34.9839C26.1901 36.6116 22.9997 37.4255 19.8093 37.4255ZM12.3381 25.4453C10.7754 26.487 9.92895 27.8218 9.92895 29.2216C9.92895 30.6053 10.7916 31.94 12.3381 32.9655C16.391 35.6838 23.2276 35.6838 27.2807 32.9655C28.8434 31.9237 29.6897 30.589 29.6897 29.1891C29.6897 27.8055 28.827 26.4708 27.2807 25.4453C23.2276 22.7432 16.391 22.7432 12.3381 25.4453Z" fill="currentColor"/>
</svg>


);

export default HelpIcon;