import React from 'react';

const ShiftIcon = () => (
  
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.4437 33.2554H10.8881C4.66591 33.2554 3.11035 31.6998 3.11035 25.4776V13.0331C3.11035 6.81093 4.66591 5.25537 10.8881 5.25537H26.4437C32.6659 5.25537 34.2215 6.81093 34.2215 13.0331V25.4776C34.2215 31.6998 32.6659 33.2554 26.4437 33.2554Z" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.7773 13.0337H29.5551" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23.333 19.2554H29.5552" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.4443 25.4775H29.5554" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.2228 18.1511C14.7778 18.1511 16.0383 16.8906 16.0383 15.3356C16.0383 13.7806 14.7778 12.52 13.2228 12.52C11.6678 12.52 10.4072 13.7806 10.4072 15.3356C10.4072 16.8906 11.6678 18.1511 13.2228 18.1511Z" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.6662 25.9911C18.4485 23.7355 16.6596 21.9622 14.404 21.76C13.6262 21.6822 12.8329 21.6822 12.0396 21.76C9.78401 21.9778 7.99512 23.7355 7.77734 25.9911" stroke="currentColor" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default ShiftIcon;