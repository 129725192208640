/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordRegex } from "variables/regex";
import RoutesName from "variables/route";
import * as yup from "yup";

const useResetPasswordHook = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { token, device } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { signin, forgotPassword } = RoutesName;

  const initialValues = {
    password: "",
    confirm: "",
  };

  const verifyToken = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URI}auth/${token}/${device}`
      );
      return true;
    } catch (err) {
      console.log("🚀 ~ verifyToken ~ err:", err);
      toast.error("Link Expired");
      navigate(forgotPassword);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("Required")
      .matches(
        passwordRegex,
        "Password must contain at least one letter, one number, one special character (!@#$%^&*), and be at least 8 characters long."
      ),
    confirm: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const handleResetPassword = async (values) => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BACKEND_URI}auth/resetpassword`,
        {
          token,
          password: values.password,
          device,
        }
      );
      toast.success(resp.data.message);
      setLoading(false);
      navigate(signin);
      return true;
    } catch (err) {
      console.log("🚀 ~ handleResetPassword ~ err:", err);
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      await handleResetPassword(values);
    },
  });

  const { handleSubmit, handleBlur, handleChange, values, touched, errors } =
    formik;

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    touched,
    errors,
    handleShowPassword,
    showPassword,
    signin,
    loading,
  };
};

export default useResetPasswordHook;
