const RoutesName = {
  dashboard: "/dashboard",
  roles: "/roles",
  add_role: "/roles/create",
  update_role: "/roles/update/:roleId",
  icons: "/icons",
  maps: "/maps",
  signup: "/signup",
  signin: "/signin",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:token/:device",
  company_profile_setup: "/company-profile-setup",
  post_shifts: "/post-shifts",
  shift_requests: "/shift-requests",
  locums: "/locums",
  privacy_policy: "/privacy-policy",
  terms_and_conditions: "/terms-and-conditions",
  profile: "/profile",
  messages: "/messages",
  shifts: "/shifts",
  guidelines: "/guidelines",
  help: "/help",
  payment: "/payment",
  locations: "/locations",
  users: "/users",
};

export default RoutesName;
