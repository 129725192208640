import { useState, useEffect, useContext } from "react";
import { post_api, get_api, delete_api, put_api } from "api";
import { toast } from "react-toastify";
import { Space, Modal, Tooltip } from "antd";
import { useAuth } from "context/AuthContext";
import LocationContext from "./LocationContext";

const useLocations = (setOpenLocationModal, onClose) => {
  const [inputValue, setInputValue] = useState("");
  const [isLocationId, setIsLocationId] = useState(null);
  const [options, setOptions] = useState([]);
  const [locData, setLocData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locationsData, setLocationsData] = useState(null);
  const { refreshLocation } = useContext(LocationContext);
  const [location, setLocation] = useState({
    lat: "-33.8636284",
    long: "151.2102526",
    address: "",
    locationName: "",
  });
  const { isAuthenticated } = useAuth();

  const getAllLocations = async () => {
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        const { data } = await get_api("multilocation/all");
        setLocData(
          data.location.map((loc) => {
            return {
              ...loc.location,
              ...loc,
            };
          })
        );
        setIsLoading(false);
        return locData;
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    refreshLocation(); // Initial fetch
  }, []);

  const handleSearch = async (value) => {
    setInputValue(value);
    if (value.length > 2) {
      try {
        const response = await get_api(`location/get-locations/${value}`);
        const locations = response.data.locations.predictions.map(
          (prediction) => ({
            label: prediction.description,
            value: prediction.place_id,
          })
        );
        setOptions(locations);
      } catch (error) {
        console.error("Error fetching locations:", error);
        setOptions([]);
      }
    } else {
      setOptions([]);
    }
  };

  const resetState = () => {
    setInputValue("");
    setOptions([]);
    setLocation({
      lat: "-33.8636284",
      long: "151.2102526",
      address: "",
      locationName: "",
    });
    setLocationsData(null);
  };

  const getLocationDetails = async (placeId) => {
    try {
      const response = await get_api(
        `location/get-location-details/${placeId}`
      );
      const locationData = response.data.locationDetails;
      const cityName = locationData.address_components.find((component) =>
        component.types.includes("locality")
      )["long_name"];
      const stateName = locationData.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      )["long_name"];

      setLocation({
        lat: `${locationData.geometry.location.lat}`,
        long: `${locationData.geometry.location.lng}`,
        address: `${cityName}, ${stateName}`,
        locationName: locationData.name,
      });
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleChangeMap = async (val, event) => {
    const selectedOption = options.find((option) => option.value === val);
    if (selectedOption) {
      setInputValue(selectedOption.label);
    }
    await getLocationDetails(val);
  };

  const createLocation = async () => {
    try {
      const { message } = await post_api(`multilocation/create`, {
        location,
      });
      toast.success(message);
      refreshLocation();
      setInputValue("");
      setLocationsData(null);
      setLocation({
        lat: "-33.8636284",
        long: "151.2102526",
        address: "",
        locationName: "",
      })
      onClose();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateLocation = async (id) => {
    try {
      const { message } = await put_api(
        `multilocation/update/${id}`,
        {
          location,
        }
      );
      toast.success(message);
      refreshLocation();
      setLocationsData(null);
      setLocation({
        lat: "-33.8636284",
        long: "151.2102526",
        address: "",
        locationName: "",
      })
      onClose();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleClearSelection = () => {
    setInputValue("");
    setOptions([]);
  };

  const warning = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this location ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        deleteLocation(id);
      },
      onCancel: () => {
        console.log("Cancel clicked..");
      },
    });
  };

  const deleteLocation = async (id) => {
    try {
      const { data, message } = await delete_api(`post-shifts/${id}`);
      await getAllLocations();
      toast.success(message);
      refreshLocation();
      return data;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const columns = [
    // {
    //   title: "Latitude",
    //   dataIndex: "latitude",
    //   key: "latitude",
    //   render: (text, record) => <div key={record._id}>{text}</div>,
    //   align: "center",
    // },
    // {
    //   title: "Longitude",
    //   dataIndex: "longitude",
    //   key: "longitude",
    //   render: (text, record) => {
    //     return <div key={record._id}>$ {text}</div>;
    //   },
    //   align: "center",
    // },
    {
      title: "Location Name",
      dataIndex: "locationName",
      key: "location_name",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Users",
      dataIndex: "subUsers",
      key: "users",
      render: (text, record) => {
        return text.length > 1 ? (
          <Tooltip title={text.join(", ")}>
            <span>
              {text[0]}
              {text.length > 1 && <span> +{text.length - 1} more</span>}
            </span>
          </Tooltip>
        ) : (
          <div key={record._id}>{text}</div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          <i
            className="fas fa-edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setLocationsData(record);
              setIsLocationId(() => {
                return record._id
              });
              setOpenLocationModal(true);
            }}
          />
          <i
            className="fas fa-trash-alt text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => warning(record._id)}
          />
        </Space>
      ),
      align: "right",
    },
  ];

  return {
    inputValue,
    setInputValue,
    options,
    setOptions,
    location,
    setLocation,
    handleSearch,
    getLocationDetails,
    handleChangeMap,
    handleClearSelection,
    createLocation,
    columns,
    isLoading,
    locationsData,
    setLocationsData,
    locData,
    updateLocation,
    isLocationId,
    refreshLocation: getAllLocations,
    resetState
  };
};

export default useLocations;
