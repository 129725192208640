import dayjs from "dayjs";

export const ErrorDisplay = ({ name, errors, touched }) => {
  return errors[name] && touched[name] ? (
    <div
      className="error-message"
      style={{ color: "#dc3545", fontSize: "0.875rem", marginTop: "0.25rem" }}
    >
      {errors[name]}
    </div>
  ) : null;
};

export const getFieldClassName = (name, errors, touched) => {
  return errors[name] && touched[name]
    ? "is-invalid"
    : touched[name] && !errors[name]
    ? "is-valid"
    : "";
};

export const fileToBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result);
  reader.onerror = (error) => console.log("Error: ", error);
};

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
};

const padToTwoDigits = (num) => {
  return num.toString().padStart(2, "0");
};

export const timeAgo = (date) => {
  const now = dayjs();
  const createdAt = dayjs(date);
  const diff = now.diff(createdAt, "second");

  if (diff < 60) return `${diff} seconds ago`;
  const diffMinutes = Math.floor(diff / 60);
  if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) return `${diffHours} hours ago`;
  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 30) return `${diffDays} days ago`;
  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths < 12) return `${diffMonths} months ago`;
  const diffYears = Math.floor(diffMonths / 12);
  return `${diffYears} years ago`;
};

export const unPaidHoursList = [
  {
    value: "15-mins",
    label: "15 Mins",
  },
  {
    value: "30-mins",
    label: "30 Mins",
  },
  {
    value: "45-mins",
    label: "45 Mins",
  },
  {
    value: "60-mins",
    label: "1 Hour",
  },
  {
    value: "90-mins",
    label: "1.5 hour",
  },
  {
    value: "120-mins",
    label: "2 Hours",
  },
];

export const DbStatus = {
  accepted: 'active_shifts',
  requested: 'requested_shifts',
  completed: 'requested_shifts',
  saved: 'saved_shifts',
  declined: 'declined_shifts'
}