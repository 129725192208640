import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { Button } from "reactstrap";
import { Empty, Table } from "antd";
import User from "./User";
import UserContext from "./UserContext";
import useUsers from "./useUsers";

const Users = () => {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [tableHeight, setTableHeight] = useState(240);
  const headerRef = useRef(null);
  const contentRef = useRef(null);
  const { refreshUser } = useContext(UserContext);

  const { columns, isLoading, payload, setPayload } = useUsers(
    setOpenUserModal,
    refreshUser
  );

  const { allUsers } = useContext(UserContext);

  const NoDataComponent = () => (
    <div
      className="empty-table-content"
      style={{
        height: `${tableHeight}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Empty />
    </div>
  );

  const updateTableHeight = () => {
    const headerHeight = headerRef.current
      ? headerRef.current.getBoundingClientRect().height
      : 0;
    const titleMarginBottom = headerRef.current
      ? parseFloat(getComputedStyle(headerRef.current).marginBottom)
      : 0;
    const contentMarginTop = contentRef.current
      ? parseFloat(getComputedStyle(contentRef.current).marginTop)
      : 0;
    const contentPaddingBottom = contentRef.current
      ? parseFloat(getComputedStyle(contentRef.current).paddingBottom)
      : 0;

    //calculate table header spacing and height
    const tableWrapper = document.querySelector(".ant-table-wrapper");
    const tableHeader = document.querySelector(
      ".ant-table-header, .ant-table-thead"
    );

    const tableWrapperPaddingTop = tableWrapper
      ? parseFloat(getComputedStyle(tableWrapper).paddingTop)
      : 0;
    const tableWrapperPaddingBottom = tableWrapper
      ? parseFloat(getComputedStyle(tableWrapper).paddingBottom)
      : 0;
    const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0;
    const tableWrapperPadding =
      tableWrapperPaddingTop + tableWrapperPaddingBottom;

    const contentOuterHeight = contentMarginTop + contentPaddingBottom;

    const newHeight =
      window.innerHeight -
      contentOuterHeight -
      tableWrapperPadding -
      tableHeaderHeight -
      headerHeight -
      titleMarginBottom -
      2; // Adjust the -85 as needed
    setTableHeight(newHeight);

    const tableBody = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = `${tableHeight}px`;
      tableBody.style.overflowY = "auto";
    }
  };

  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();

    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, tableHeight]);

  const scrollXValue = columns.length * 150;

  return (
    <div ref={contentRef} className="content mb-0">
      <div ref={headerRef} className="page-header-wrap">
        <h2 className="page-main-title">Users</h2>
        <div className="post-shift-btn-wrap">
          <Button
            color="transparent"
            outline
            className="header-btn-wrap"
            onClick={() => setOpenUserModal(true)}
          >
            <i className="fa fa-plus" />
            Add User
          </Button>
        </div>
      </div>
      <div className="cmn-card-content">
        {allUsers?.length === 0 ? (
          <NoDataComponent />
        ) : (
          <Table
            className="post-shift-tbl"
            columns={columns}
            dataSource={allUsers}
            rowKey="_id"
            loading={isLoading}
            pagination={false}
            scroll={
              allUsers?.length > 0
                ? { x: scrollXValue, y: tableHeight }
                : undefined
            } // Use dynamic height
            rowClassName={() => "custom-row"}
          />
        )}
      </div>
      <User
        payload={payload}
        openUserModal={openUserModal}
        onClose={() => {
          setPayload();
          refreshUser();
          setOpenUserModal(false);
        }}
        setOpenUserModal={setOpenUserModal}
        refreshUser={refreshUser}
      />
    </div>
  );
};

export default Users;
