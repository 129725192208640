/*eslint-disable react-hooks/exhaustive-deps*/
import { Calendar, Table, Tabs, Tag, Row, Col, Empty } from "antd";
import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import useShiftsHook from "./useShiftsHook";
import { startCase } from "lodash";
import dayjs from "dayjs";
import { Card, CardBody } from "reactstrap";

const { TabPane } = Tabs;

const Shifts = () => {
  const {
    columns,
    locumShifts,
    isLoading,
    getShiftsByLocumIdAndStatus,
    activeTab,
    setActiveTab,
    dateCellRender,
    CustomHeader,
    onPanelChange,
    count,
    setLocumShifts,
    setIsLoading,
  } = useShiftsHook();

  const [tableHeight, setTableHeight] = useState(240);
  const [noDatatableHeight, setNoDataTableHeight] = useState(240);
  const [activeShiftHeight, setActiveShiftHeight] = useState(240);
  const calendarRef = useRef(null);

  const NoDataComponent = () => (
    <div className="empty-table-content" style={{ height: `${noDatatableHeight}px` }}>
      <Empty />
    </div>
  );

  const updateTableHeight = () => {
    if (window.innerWidth > 1199) {
      //content height(including margin/padding)
      const contentSpace = document.querySelector('.content');
      const contentSpaceMargin = contentSpace ? parseFloat(getComputedStyle(contentSpace).marginTop) : 0;
      const contentSpacePadding = contentSpace ? parseFloat(getComputedStyle(contentSpace).paddingBottom) : 0;
      const contentSpaceTotal = contentSpaceMargin + contentSpacePadding;

      //tabbing height(including margin/padding)
      const tabbingComp = document.querySelector('.ant-tabs-nav');
      const tabbingHeight = tabbingComp ? tabbingComp.offsetHeight : 0;
      const tabbingMarBot = tabbingComp ? parseFloat(getComputedStyle(tabbingComp).marginBottom) : 0;
      const tabbingStyle = tabbingHeight + tabbingMarBot

      //spacing and height of table
      const tableWrapper = document.querySelector('.ant-table-wrapper');
      const tableWrapperPaddingTop = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingTop) : 0;
      const tableWrapperPaddingBottom = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingBottom) : 0;
      const tableWrapperPadding = tableWrapperPaddingTop + tableWrapperPaddingBottom;

      //sidebar spacing
      const dashRightSideSpace = document.querySelector('.dash-card-comp .card-body');
      const sidebarPaddingTop = dashRightSideSpace ? parseFloat(getComputedStyle(dashRightSideSpace).paddingTop) : 0;
      const sidebarPaddingBottom = dashRightSideSpace ? parseFloat(getComputedStyle(dashRightSideSpace).paddingBottom) : 0;
      const dashRightSideHeight = sidebarPaddingTop + sidebarPaddingBottom

      //calculate table height
      const availableHeight = window.innerHeight - contentSpaceTotal - tabbingStyle - tableWrapperPadding - 53;

      //calculate sidebar active shifts div
      const activesidebarHeight = window.innerHeight - contentSpaceTotal - tabbingStyle - dashRightSideHeight - 430;

      const availableNoDataHeight = window.innerHeight - contentSpaceTotal - tabbingStyle;

      //set dynamic height of table and sidebar
      setTableHeight(availableHeight);
      setNoDataTableHeight(availableNoDataHeight);
      setActiveShiftHeight(activesidebarHeight);

      //dynamic height of table under tabs
      const tableBodies = document.querySelectorAll('.ant-table-body');
      tableBodies.forEach((tableBody) => {
        tableBody.style.height = `${availableHeight}px`;
        tableBody.style.overflowY = 'auto';
      });
    } else {
      // Reset to default height or any specific height if required
      const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = '';
        tableBody.style.overflowY = '';
      }
    }
  };

  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();
  
    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [locumShifts]);

  useEffect(() => {
    setIsLoading(true);
    setLocumShifts([]);
    getShiftsByLocumIdAndStatus(activeTab).finally(() => {
      setIsLoading(false);
      updateTableHeight(); // Update height after shifts are fetched
    });
  }, [activeTab, getShiftsByLocumIdAndStatus]);

  const scrollXValue = columns.length * 150;

  const renderTable = () => (
    <Row gutter={[20,20]}>
      <Col xl={16} lg={24} xs={24}>
        <div className="cmn-card-content">
          {locumShifts?.length === 0 ? (
            <NoDataComponent />
          ) : (  
            <Table
              pagination={false}
              loading={isLoading}
              columns={columns}
              dataSource={locumShifts}
              rowKey="_id"
              scroll={locumShifts.length > 0 ? { x: scrollXValue, y: tableHeight } : undefined}
            />
          )}
        </div>
      </Col>
      <Col xl={8} lg={24} xs={24}>
        <Card className="common-card-sec dash-card-comp">
          <CardBody>
            <div ref={calendarRef} className="shifts-calendar-picker">
              <Calendar
                fullscreen={false}
                onChange={onPanelChange}
                onPanelChange={onPanelChange}
                cellRender={dateCellRender}
                headerRender={({ value, type, onChange }) => (
                  <CustomHeader value={value} type={type} onChange={onChange} />
                )}
              />
            </div>
            <h5 className="dash-title-wrapper">Active Shifts</h5>
            <div className="active-shifts-wrapper" style={{ height: `${activeShiftHeight}px`}}>
              {count?.shifts?.length > 0 ? (
                count.shifts.map((shfts, idx) => (
                  <Card
                    key={idx}
                    className="active-shift-card"
                    title={
                      <h3 className="shift-overview-title shift-stepper-sub-title">
                        Shift Overview
                      </h3>
                    }
                    bordered={false}
                  >
                    <div className="active-shift-header">
                      <div className="active-shift-left-side">
                        <div className="active-shift-user-details">
                          <h5>{shfts?.company_profile_id?.companyName}</h5>
                          <p>
                            <i className="fas fa-map-marker-alt" />{" "}
                            {`${shfts?.company_profile_id?.location?.locationName}, ${shfts?.company_profile_id?.location?.address}`}
                          </p>
                        </div>
                      </div>
                      <div className="active-shift-right-side">
                        <Tag className="common-tag" color="green">
                          {shfts?.day_type}
                        </Tag>
                      </div>
                    </div>

                    <div className="active-shift-details">
                      <div className="active-shift-details-left">
                        <div className="active-shift-details-wrap">
                          <i className="far fa-calendar-alt" />
                          <span className="active-shift-value">
                            {dayjs(shfts.date).locale("en").format("ddd DD MMM YYYY")}
                          </span>
                        </div>
                        <div className="active-shift-details-wrap">
                          <i className="far fa-clock" />
                          <span className="active-shift-value">
                            {shfts?.start_time} - {shfts?.end_time}
                          </span>
                        </div>
                      </div>
                      <div className="active-shift-details-right">
                        <div className="active-shift-hour-rate">
                          ${shfts?.hourly_rate}hr{" "}
                          <span className="active-shift-hour-type">
                            /{startCase(shfts?.hourly_rate_type)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))
              ) : (
                <div className="no-active-shifts">
                  <Empty />
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );

  return (
    <div className="content mb-0">
      <Tabs
        className="antd-tabs-wrapper"
        type="card"
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        {["accepted", "requested", "completed", "saved", "declined", "cancelled"].map(
          (key) => (
            <TabPane tab={key.charAt(0).toUpperCase() + key.slice(1)} key={key}>
              {renderTable()}
            </TabPane>
          )
        )}
      </Tabs>
    </div>
  );
};

export default Shifts;
