import React, { useEffect, useState } from "react";
import { Label, Button, Input } from "reactstrap";
import { Select, Modal, Spin } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import useUsers from "./useUsers";
import "./Users.scss";

const User = ({
  payload,
  openUserModal,
  onClose,
  setOpenUserModal,
  refreshUser,
}) => {
  const {
    isLoading,
    updateUser,
    createUser,
    setPayload,
    allLocation,
    payload: updatedPayload,
  } = useUsers(setOpenUserModal, onClose);

  useEffect(() => {
    setPayload(payload);
  }, [payload]);

  return (
    <div>
      <Modal
        open={openUserModal}
        onCancel={() => {
          if (!isLoading) {
            if (onClose) onClose();
          }
        }}
        width="50%"
        style={{ overflow: "hidden" }}
        styles={{ body: { height: "calc(80vh - 100px)", overflow: "auto" } }}
        centered
        footer={
          <div className="ant-modal-footer">
            <Button className="cancel-btn-footer" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="main-btn-stye"
              onClick={payload ? updateUser : createUser}
              disabled={
                !updatedPayload?.first_name ||
                !updatedPayload?.last_name ||
                !updatedPayload?.email ||
                !updatedPayload?.phone ||
                updatedPayload?.multiLocationIds?.length <= 0
              }
            >
              {isLoading && <Spin fullscreen />}
              {payload ? "Update" : "Add"}
            </Button>
          </div>
        }
        destroyOnClose
      >
        <div className="modal-popup-content">
          <h3 className="popup-user-form-title">
            {payload ? "Update User" : "Add User"}
          </h3>
          <div className="user-form-select-content">
            <div className="user-form-stepper-field-group">
              <div className="user-form-stepper-field-box">
                <Label className="common-stepper-label">First Name</Label>
                <div className="user-form-stepper-field-wrap">
                  <Input
                    placeholder="Enter first name"
                    className="stepper-input-field"
                    onChange={(e) => {
                      const isValidRate = /^[A-Za-z]+$/.test(e.target.value);
                      if (isValidRate) {
                        setPayload((prev) => ({
                          ...prev,
                          first_name: e.target.value,
                        }));
                      }
                    }}
                    defaultValue={payload?.first_name}
                    type="text"
                  />
                </div>
              </div>
              <div className="user-form-stepper-field-box">
                <Label className="common-stepper-label">Last Name</Label>
                <div className="user-form-stepper-field-wrap">
                  <Input
                    placeholder="Enter last name"
                    className="stepper-input-field"
                    onChange={(e) => {
                      const isValidRate = /^[A-Za-z]+$/.test(e.target.value);
                      if (isValidRate) {
                        setPayload((prev) => ({
                          ...prev,
                          last_name: e.target.value,
                        }));
                      }
                    }}
                    defaultValue={payload?.last_name}
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div className="user-form-stepper-field-box">
              <Label className="common-stepper-label">Email</Label>
              <div className="user-form-stepper-field-wrap">
                <Input
                  placeholder="Enter email"
                  className="stepper-input-field"
                  onChange={(e) => {
                    const isValidRate =
                      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                        e.target.value
                      );
                    if (isValidRate) {
                      setPayload((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }));
                    }
                  }}
                  defaultValue={payload?.email}
                  type="email"
                />
              </div>
            </div>
            <div className="user-form-stepper-field-box">
              <Label className="common-stepper-label">Phone</Label>
              <div className="user-form-stepper-field-wrap">
                <Input
                  placeholder="Enter phone"
                  className="stepper-input-field"
                  onChange={(e) => {
                    // const isValidRate = /^[A-Za-z]+$/.test(e.target.value);
                    // if (isValidRate) {
                    setPayload((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }));
                    // }
                  }}
                  inputMode="numeric"
                  defaultValue={payload?.phone}
                  type="text"
                />
              </div>
            </div>
            <div className="user-form-stepper-field-box">
              <Label className="common-stepper-label">Locations</Label>
              <div className="user-form-stepper-field-wrap">
                <Select
                  mode="multiple"
                  className="common-dropdown common-antd-component"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select locations"
                  defaultValue={payload?.multiLocationIds?.map((location) => location._id)}
                  onChange={(e) => {
                    setPayload((prev) => ({
                      ...prev,
                      multiLocationIds: e,
                    }));
                  }}
                  options={allLocation.map((location) => ({
                    value: location._id,
                    label: location.location.locationName + " " + location.location.address,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default User;
