import React from 'react';

const LocumsIcon = () => (
      
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.2258 35.8203C27.8081 36.2392 26.1325 36.4325 24.167 36.4325H14.5003C12.5347 36.4325 10.8592 36.2392 9.44141 35.8203C9.79585 31.6314 14.0975 28.3286 19.3336 28.3286C24.5697 28.3286 28.8714 31.6314 29.2258 35.8203Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.1671 4.21045H14.5004C6.44488 4.21045 3.22266 7.43267 3.22266 15.4882V25.1549C3.22266 31.2449 5.05932 34.5799 9.44155 35.8204C9.79599 31.6316 14.0977 28.3287 19.3338 28.3287C24.5699 28.3287 28.8715 31.6316 29.226 35.8204C33.6082 34.5799 35.4449 31.2449 35.4449 25.1549V15.4882C35.4449 7.43267 32.2227 4.21045 24.1671 4.21045ZM19.3338 23.8176C16.1438 23.8176 13.566 21.2238 13.566 18.0338C13.566 14.8438 16.1438 12.266 19.3338 12.266C22.5238 12.266 25.1015 14.8438 25.1015 18.0338C25.1015 21.2238 22.5238 23.8176 19.3338 23.8176Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.101 18.0339C25.101 21.2239 22.5232 23.8178 19.3332 23.8178C16.1432 23.8178 13.5654 21.2239 13.5654 18.0339C13.5654 14.8439 16.1432 12.2661 19.3332 12.2661C22.5232 12.2661 25.101 14.8439 25.101 18.0339Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default LocumsIcon;