import React, { useEffect, useState } from "react";
import { Label, Button } from "reactstrap";
import { Select, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import Map from "views/Map";
import "./Location.scss";
import useLocations from "./useLocations";

const { Option } = Select;

const Location = ({
  openLocationModal,
  setOpenLocationModal,
  locationData,
  onClose,
  refreshLocation,
}) => {
  const {
    inputValue,
    options,
    setLocation,
    location,
    handleSearch,
    handleChangeMap,
    handleClearSelection,
    createLocation,
    updateLocation,
    resetState
  } = useLocations(
    setOpenLocationModal,
    refreshLocation,
  );

  useEffect(() => {
    setLocation({
      lat: locationData ? locationData.lat : location.lat,
      long: locationData ? locationData.long : location.long,
      address: locationData ? locationData.address : location.address,
      locationName: locationData ? locationData.locationName : location.locationName,
    });
  }, [locationData]);

  return (
    <div>
      <Modal
        open={openLocationModal}
        onCancel={() => {
          resetState()
          onClose();
        }}
        width="100%"
        style={{ overflow: "hidden" }}
        styles={{ body: { height: "calc(90vh - 72px)", overflow: "auto" } }}
        centered
        footer={
          <div className="ant-modal-footer">
            <Button className="cancel-btn-footer" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="main-btn-stye"
              disabled={!locationData?.address && !inputValue}
              onClick={() => {
                if(locationData){
                  updateLocation(locationData._id)
                }
                else{
                  createLocation();
                }
                onClose();
              }}
            >
             {locationData ? "Update" : "Add"}
            </Button>
          </div>
        }
        destroyOnClose
      >
        <Label className="common-stepper-label">Location</Label>
        <div className="popup-location-stepper">
          <Select
            showSearch
            className="common-dropdown common-antd-component"
            value={inputValue ? inputValue : (locationData?.locationName && locationData?.address && (locationData?.locationName + " " + locationData?.address)) || undefined}
            placeholder="Search a location"
            onSearch={handleSearch}
            onChange={handleChangeMap}
            filterOption={false}
            notFoundContent={null}
            suffixIcon={
              inputValue && (
                <CloseCircleOutlined onClick={handleClearSelection} />
              )
            }
          >
            {options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <div className="location-map-wrapper">
            <Map lat={location.lat} long={location.long} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Location;
