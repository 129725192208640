/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Line, Pie , Bar} from "react-chartjs-2";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
  borderRadiusPlugin
} from "variables/charts.js";
import { get_api } from "api";
import {
  Avatar,
  Badge,
  Calendar,
  Divider,
  Spin,
  Table,
  Tag,
  Select,
  Row, 
  Col,
  Empty
} from "antd";
import badgeUpArrow from "../../assets/img/up-arrow.svg";
import badgeDownArrow from "../../assets/img/down-arrow.svg";
import dayjs from "dayjs";
import { startCase } from "lodash";
import { ReactComponent as DownOutlined } from "../../assets/img/dropdown-icon.svg";
import { toHoursAndMinutes } from "components/GeneralComponents/Utils";
import { unPaidHoursList } from "components/GeneralComponents/Utils";
import { useAuth } from "../../context/AuthContext";

function Dashboard() {
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState({});

  const [tableHeight, setTableHeight] = useState(240);
  const [noDatatableHeight, setNoDataTableHeight] = useState(240);
  const [contentHeight, setContentHeight] = useState(240);

  const contentRef = useRef(null);

  const { setCompanyProfile, companyProfile } = useAuth();

  const NoDataComponent = () => (
    <div className="empty-table-content" style={{ height: `${noDatatableHeight}px`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Empty />
    </div>
  );
  const [stats, setStats] = useState({
    shiftPosted: 'Calculating...',
    shiftViews: 'Calculating...',
    shiftRequest: 'Calculating...',
    shiftCompleted: 'Calculating...'
  });

  const updateTableHeight = () => {
    if (window.innerWidth > 1199) {
      const dashCardRowSpace = document.querySelector('.dash-card-row');
      const dashCardRowSpaceHeight = dashCardRowSpace ? dashCardRowSpace.offsetHeight : 0;
  
      const activeShiftTitle = document.querySelector('.dash-title-wrapper');
      const activeShiftTitleHeight = activeShiftTitle ? activeShiftTitle.offsetHeight : 0;
      const activeShiftTitleMargin = activeShiftTitle ? parseFloat(getComputedStyle(activeShiftTitle).marginBottom) : 0;
      const activeShiftTitleSpace = activeShiftTitleHeight + activeShiftTitleMargin;
      
      const availableHeight = window.innerHeight - dashCardRowSpaceHeight;
      const noDataTableHeight = window.innerHeight - dashCardRowSpaceHeight + 50;
      const availableContentHeight = window.innerHeight - dashCardRowSpaceHeight + activeShiftTitleSpace;
  
      setTableHeight(availableHeight);
      setNoDataTableHeight(noDataTableHeight);
      setContentHeight(availableContentHeight);
  
      const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = `${availableHeight}px`;
        tableBody.style.overflowY = 'auto';
      }
    } else {
      // Reset to default height or any specific height if required
      const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = '';
        tableBody.style.overflowY = '';
      }
    }
  };
  
  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();
  
    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [count?.requestedShifts]);

  const getDashboardCount = async (monthYear) => {
    try {
      setLoading(true);
      const { data } = await get_api(
        monthYear ? `dashboard/${monthYear}` : "dashboard"
      );
      setCount(data);
      const updatedStats = {
        shiftPosted: await calculatePercentageChange(data.userDetail?.dashboardAnalytics?.shiftPosted  ? data.userDetail?.dashboardAnalytics?.shiftPosted : new Array(12).fill(0)),
        shiftViews: await calculatePercentageChange(data.userDetail?.dashboardAnalytics?.shiftViews  ? data.userDetail?.dashboardAnalytics?.shiftViews : new Array(12).fill(0)),
        shiftRequest: await calculatePercentageChange(data.userDetail?.dashboardAnalytics?.shiftRequest  ? data.userDetail?.dashboardAnalytics?.shiftRequest : new Array(12).fill(0)),
        shiftCompleted: await calculatePercentageChange(data.userDetail?.dashboardAnalytics?.shiftCompleted  ? data.userDetail?.dashboardAnalytics?.shiftCompleted : new Array(12).fill(0))
    };
      // Format the stats for display
      Object.keys(updatedStats).forEach(key => {
        updatedStats[key] = (updatedStats[key]=== 0 || updatedStats[key]=== 100) ? `${updatedStats[key]}%` : `${updatedStats[key].toFixed(2)}%`;
      });
      setStats(updatedStats);
      data.profileDetail && setCompanyProfile(data.profileDetail)
      
      if(data?.profileDetail?.pricingPlan?.cancelAt !== companyProfile?.profileDetail?.pricingPlan?.cancelAt || data?.profileDetail?.pricingPlan?.subscriptionStatus !== companyProfile?.profileDetail?.pricingPlan?.subscriptionStatus) {
        localStorage.setItem("company_profile", JSON.stringify(data?.profileDetail));
      }

      setLoading(false);
      return data;
    } catch (err) {
      console.error("Error fetching dashboard count:", err);
      setLoading(false);
    }
  };
  const calculatePercentageChange = async (shiftData, ) => {
    try {
      setLoading(true);
      const data = shiftData || new Array(12).fill(0);
        const today = new Date();
        const currentMonthIndex = today.getMonth();
        const previousMonthIndex = currentMonthIndex - 1;
        
        const currentMonthValue = data[currentMonthIndex];
        const previousMonthValue = currentMonthIndex > 0 ? data[previousMonthIndex] : 0;

        if (previousMonthValue === 0) {
            setLoading(false);
            return currentMonthValue === 0 ? 0 : 100;
        } else if(previousMonthValue === 0 && currentMonthValue === 0){
          setLoading(false);
          return 0;
        }
        setLoading(false);
        const increase = currentMonthValue - previousMonthValue;
        const maxPossibleIncrease = Math.max(previousMonthValue, currentMonthValue); 
        let change = (increase / maxPossibleIncrease) * 100;
        return Math.min(change, 100); 
        // return ((currentMonthValue - previousMonthValue) / previousMonthValue) * 100;
    } catch (err) {
      console.error("Error fetching dashboard count:", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDashboardCount(dayjs().format("DD-MM-YYYY"));    
  // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, []); // Recalculate when analyticsData changes

  const dateCellRender = (value) => {
    const dateString = value.format("YYYY-MM-DD");
    const dateData =
      count &&
      count?.calenderShiftDetails?.calender?.find(
        (item) => item.date === dateString
      );
    const count2 = dateData ? dateData.count : 0;

    return (
      <div className="events">
        {Array.from({ length: count2 }, (_, index) => (
          <Badge key={index} status="success" />
        ))}
      </div>
    );
  };

  const CustomSelect = ({ value, options, onChange }) => (
    <Select
      value={value}
      onChange={onChange}
      suffixIcon={<DownOutlined />}
      options={options.map((option) => ({ label: option, value: option }))}
    />
  );

  const CustomHeader = ({ value, onChange, type }) => {
    const year = value.year();
    const month = value.month();

    const yearOptions = Array.from({ length: 20 }, (_, i) => year - 10 + i);
    const monthOptions = dayjs.months();

    const onYearChange = (newYear) => {
      const newValue = value.clone().year(newYear);
      onChange(newValue);
    };

    const onMonthChange = (newMonth) => {
      const newValue = value.clone().month(monthOptions.indexOf(newMonth));
      onChange(newValue);
    };

    return (
      <div className="calendar-header-wrapper">
        <CustomSelect
          value={year}
          options={yearOptions}
          onChange={onYearChange}
        />
        <CustomSelect
          value={monthOptions[month]}
          options={monthOptions}
          onChange={onMonthChange}
        />
      </div>
    );
  };
// console.log("count--------",count)
  const onPanelChange = async (value, mode) => {
    await getDashboardCount(dayjs(value).format("DD-MM-YYYY"));
  };
  const shiftsColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="cmn-user-detail" key={record?.locumUser?._id}>
          {/* <Avatar src={null} /> */}
          <span>
            {`${startCase(record?.locumUser?.first_name)} ${startCase(
              record?.locumUser?.last_name
            )}`}
          </span>
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "date",
      key: "date",
      render: (text, record) => (
        <div className="fields-wrapper"
          key={record._id}
          // onClick={() => getShiftRequests(record?._id)}
          style={{ cursor: "pointer" }}
        >
          <p>
            {dayjs(record?.requestedShift?.date)
              .locale("en")
              .format("ddd DD MMM YYYY")}
          </p>
          <p>
            {record?.requestedShift?.start_time} -{" "}
            {record?.requestedShift?.end_time}
          </p>
        </div>
      ),
      align: "center",
    },
    {
      title: "Total Hrs",
      dataIndex: "total_hours",
      key: "total_hours",
      render: (text, record) => (
        <div key={record._id}>{record?.requestedShift?.total_hours}</div>
      ),
      align: "center",
    },
    {
      title: "Unpaid",
      dataIndex: "unpaid_hours",
      key: "unpaid_hours",
      render: (text, record) => {
        const unPaidHour = unPaidHoursList.find(
          (uph) => uph?.value === record?.requestedShift?.unpaid_hours
        );
        const uphours = toHoursAndMinutes(unPaidHour?.value.split("-")[0]);
        return <div key={record._id}>{uphours}</div>;
      },
      align: "center",
    },
    {
      title: "Hourly Rate",
      dataIndex: "hourly_rate",
      key: "hourly_rate",
      render: (text, record) => {
        return (
          <div key={record._id}>$ {record?.requestedShift?.hourly_rate}</div>
        );
      },
      align: "center",
    },
    // {
    //   title: "Rate",
    //   dataIndex: "total_rate",
    //   key: "total_rate",
    //   render: (text, record) => {
    //     return (
    //       <div key={record._id}>$ {record?.requestedShift?.total_rate}</div>
    //     );
    //   },
    //   align: "center",
    // },
    {
      title: "Type",
      dataIndex: "hourly_rate_type",
      key: "hourly_rate_type",
      render: (text, record) => {
        return (
          <div key={record._id}>
            {startCase(record?.requestedShift?.hourly_rate_type)}
          </div>
        );
      },
      align: "right",
    },
  ];

  const scrollXValue = shiftsColumns.length * 150;
  
  return (
    <>
      <div className="content dashboard-content">
        {loading && <Spin fullscreen />}
        <Row gutter={20} className="dash-card-row">
          <Col md={6} xs={12}>
            <Card className="card-stats dashboard-cards bg-light-pink">
              <CardBody>
                <div className="card-head-wrap">
                  <h6 className="card-title">Shifts Posted</h6>
                  <span className={`card-badge ${parseFloat(stats.shiftPosted.replace('%', '')) >= 0 ? 'green-badge' : 'red-badge'}`}>
                    <img src={parseFloat(stats.shiftPosted.replace('%', '')) >= 0 ? badgeUpArrow : badgeDownArrow} alt="arrow" />
                    {`${Math.abs(parseFloat(stats.shiftPosted.replace('%', '')))}%`}
                  </span>
                </div>
                <div className="card-foot-details">
                  <h4 className="total-figure">{count.totalPostShifts}</h4>
                  <span className="card-label">Shifts</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12}>
            <Card className="card-stats dashboard-cards bg-light-green">
              <CardBody>
                <div className="card-head-wrap">
                  <h6 className="card-title">Shift views</h6>
                  <span className={`card-badge ${parseFloat(stats.shiftViews.replace('%', '')) >= 0 ? 'green-badge' : 'red-badge'}`}>
                    <img src={parseFloat(stats.shiftViews.replace('%', '')) >= 0 ? badgeUpArrow : badgeDownArrow} alt="arrow" />
                    {`${Math.abs(parseFloat(stats.shiftViews.replace('%', '')))}%`}
                  </span>
                </div>
                <div className="card-foot-details">
                  <h4 className="total-figure">{count.userDetail?.dashboardAnalytics?.shiftViews 
                    ? count.userDetail.dashboardAnalytics.shiftViews.reduce((sum, current) => sum + current, 0)
                    : 0}</h4>
                  <span className="card-label">Views</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12}>
            <Card className="card-stats dashboard-cards bg-dark-green">
              <CardBody>
                <div className="card-head-wrap">
                  <h6 className="card-title">Shift Requests</h6>
                  <span className={`card-badge ${parseFloat(stats.shiftRequest.replace('%', '')) >= 0 ? 'green-badge' : 'red-badge'}`}>
                    <img src={parseFloat(stats.shiftRequest.replace('%', '')) >= 0 ? badgeUpArrow : badgeDownArrow} alt="arrow" />
                    {`${Math.abs(parseFloat(stats.shiftRequest.replace('%', '')))}%`}
                  </span>
                </div>
                <div className="card-foot-details">
                  {/* <h4 className="total-figure">{count.requestedCount}</h4> */}
                  <h4 className="total-figure">{count.userDetail?.dashboardAnalytics?.shiftRequest 
                    ? count.userDetail.dashboardAnalytics.shiftRequest.reduce((sum, current) => sum + current, 0)
                    : 0}</h4>
                  <span className="card-label">Requested</span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12}>
            <Card className="card-stats dashboard-cards bg-green">
              <CardBody>
                <div className="card-head-wrap">
                  <h6 className="card-title">Completed</h6>
                  <span className={`card-badge ${parseFloat(stats.shiftCompleted.replace('%', '')) >= 0 ? 'green-badge' : 'red-badge'}`}>
                    <img src={parseFloat(stats.shiftCompleted.replace('%', '')) >= 0 ? badgeUpArrow : badgeDownArrow} alt="arrow" />
                    {`${Math.abs(parseFloat(stats.shiftCompleted.replace('%', '')))}%`}
                  </span>
                </div>
                <div className="card-foot-details">
                  <h4 className="total-figure">{count.completedCount}</h4>
                  <span className="card-label">Completed</span>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row gutter={[20,20]}>
          <Col xl={16} lg={24}>
            <div className="dashboard-lft-content">  
              <Row gutter={[20,20]}>
                <Col span={24}>
                  <Card className="common-card-sec shift-request-chart">
                    <CardHeader>
                      <CardTitle className="dashboard-card-title">Shift Statistics</CardTitle>
                      <div className="chart-differentiation">
                        <div className="chart-identity shifts-views">
                          <span></span>
                          Shift Views
                        </div>
                        <div className="chart-identity shifts-requests">
                          <span></span>
                          Shift Requests
                        </div>
                      </div>
                      {/* <p className="card-category">24 Hours performance</p> */}
                    </CardHeader>
                    <CardBody>
                    {Object.keys(count).length && count?.userDetail?.dashboardAnalytics?.shiftRequest && count.userDetail?.dashboardAnalytics?.shiftRequest?.length &&
                        <div className="chart-container">
                        <Bar
                         
                        data={{
                          labels: [
                            "Jan",
                            "Feb",
                            "Mar",
                            "Apr",
                            "May",
                            "Jun",
                            "Jul",
                            "Aug",
                            "Sep",
                            "Oct",
                            "Nov",
                            "Dec"
                          ],
                          datasets: [
                            {
                              label: 'Shift Requests',
                              data: Array.isArray(count?.userDetail?.dashboardAnalytics?.shiftRequest) ? count.userDetail?.dashboardAnalytics?.shiftRequest : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                              borderColor: "#023a28",
                              backgroundColor: "#023a28",
                              hoverBorderWidth: 0,
                              pointRadius: 0,
                              pointHoverRadius: 0,
                              borderWidth: 5,
                              // tension: 0.4,
                              fill: true,
                              barPercentage: 1.0, 
                              categoryPercentage: 0.6, 
                              inflateAmount:5,
                              borderRadius: {
                                topLeft: 14,
                                topRight: 14,
                                bottomLeft: 14,
                                bottomRight: 14
                              },
                              borderSkipped: false,
                            },
                            {
                              label: 'Shift Views',
                              data: Array.isArray(count?.userDetail?.dashboardAnalytics?.shiftViews) ? count.userDetail?.dashboardAnalytics?.shiftViews : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                              borderColor: "rgb(2, 58, 40, 0.15)",
                              backgroundColor: "rgb(2, 58, 40, 0.15)",
                              pointRadius: 0,
                              pointHoverRadius: 0,
                              hoverBorderWidth: 0,
                              borderWidth: 0,
                              // tension: 0.4,
                              fill: true,
                              inflateAmount:3,
                              barPercentage: 1.0, 
                              categoryPercentage: 0.6, 
                              borderRadius: {
                                topLeft: 14,
                                topRight: 14,
                                bottomLeft: 0,
                                bottomRight: 0
                              },
                              borderSkipped: false,
                            }
                          ]
                        }}
                        
                          options={{
                            ...dashboard24HoursPerformanceChart.options,
                            elements: {
                              bar: {
                                borderRadius: {
                                  topLeft: 14,
                                  topRight: 14,
                                  bottomLeft: 14,
                                  bottomRight: 14
                                },
                              },
                            },                      
                            responsive: true,
                            scales: {
                              y: {
                                stacked: true,
                                beginAtZero: true,
                                grid: {
                                  borderDash: [5, 5], // Dashed y-axis lines
                                  borderColor: 'transparent',
                                },
                                ticks: {
                                  font: {
                                    family: '"Manrope", "Helvetica Neue", Arial, sans-serif', // Font family for y-axis labels
                                  },
                                  color: '#BCBCBC',
                                },
                              },
                              x: {
                                stacked: true,
                                grid: {
                                  display: false, // Disable x-axis grid lines
                                  borderColor: 'transparent',
                                },
                                ticks: {
                                  font: {
                                    family: '"Manrope", "Helvetica Neue", Arial, sans-serif', // Font family for y-axis labels
                                    size: window.innerWidth <= 575 ? 10 : 16, 
                                  },
                                  color: '#BCBCBC',
                                  autoSkip: false, // Ensure all labels are shown
                                },
                              },
                            },
                            plugins: {
                              ...dashboard24HoursPerformanceChart.options?.plugins, // Spread existing plugins if any
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          height={110}
                        />
                        </div>
                      }
                    </CardBody>
                  </Card>
                </Col>
                <Col span={24}>
                  <div className="cmn-card-content">
                    {count?.requestedShifts?.length === 0 ? (
                      <NoDataComponent />
                    ) : (
                      <Table
                        columns={shiftsColumns}
                        dataSource={count?.requestedShifts}
                        pagination={false}
                        className="shift-request-tbl"
                        rowKey={(record) => record._id}
                        scroll={{ y: tableHeight, x: scrollXValue }}
                      />
                    )}
                  </div>
                  {/* <Table
                    className="shift-request-tbl"
                    columns={shiftsColumns}
                    dataSource={count?.requestedShifts}
                    rowKey="_id"
                    pagination={false}
                    scroll={
                      count?.requestedShifts?.length > 0
                        ? { y: 1200 , x: 950 }
                        : undefined
                    }
                    locale={{ emptyText: <NoDataComponent /> }}
                  /> */}
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={8} lg={24}>
            <Card className="common-card-sec dash-card-comp">
              <CardBody>
                <Calendar
                  fullscreen={false}
                  onChange={onPanelChange}
                  onPanelChange={onPanelChange}
                  cellRender={dateCellRender}
                  headerRender={({ value, type, onChange }) => (
                    <CustomHeader
                      value={value}
                      type={type}
                      onChange={onChange}
                    />
                  )}
                />
                <h5 className="dash-title-wrapper">Active Shifts</h5>
                <div className="active-shifts-wrapper" style={{ height: `${contentHeight}px`}}>
                  {count?.calenderShiftDetails?.shifts?.length > 0 ? (
                    count.calenderShiftDetails.shifts.map((shfts, idx) => (
                      <a className="active-shift-redirect" href="/post-shifts" key={idx}>
                        <Card
                          className="active-shift-card"
                          title={
                            <h3 className="shift-overview-title shift-stepper-sub-title">
                              Shift Overview
                            </h3>
                          }
                        >
                          <div className="active-shift-header">
                            <div className="active-shift-left-side">
                              <div className="active-shift-user-details">
                                <h5>{shfts?.company_profile_id?.companyName}</h5>
                                <p>
                                  <i className="fas fa-map-marker-alt" />{" "}
                                  {`${shfts?.company_profile_id?.location?.locationName}, ${shfts?.company_profile_id?.location?.address}`}
                                </p>
                              </div>
                            </div>
                            <div className="active-shift-right-side">
                              <Tag className="common-tag" color="green">
                                {shfts?.day_type}
                              </Tag>
                            </div>
                          </div>

                          <div className="active-shift-details">
                            <div className="active-shift-details-left">
                              <div className="active-shift-details-wrap">
                                <i className="far fa-calendar-alt" />
                                <span className="active-shift-value">
                                  {dayjs(shfts.date).locale("en").format("ddd DD MMM YYYY")}
                                </span>
                              </div>
                              <div className="active-shift-details-wrap">
                                <i className="far fa-clock" />
                                <span className="active-shift-value">
                                  {shfts?.start_time} - {shfts?.end_time}
                                </span>
                              </div>
                            </div>
                            <div className="active-shift-details-right">
                              <div className="active-shift-hour-rate">
                                ${shfts?.hourly_rate}hr{" "}
                                <span className="active-shift-hour-type">
                                  /{startCase(shfts?.hourly_rate_type)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </a>
                    ))
                  ) : (
                    <div className="no-active-shifts">
                      <Empty />
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
