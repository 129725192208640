import React from "react";
import RoutesName from "variables/route";
import CompanyProfileSetup from "views/company_profile_setup/CompanyProfileSetup";
import PostShiftList from "components/PostShift";
import ShiftRequestsList from "components/ShiftRequests";
import LocumsList from "components/Locums";
import Dashboard from "views/Dashboard/Dashboard";
import BusinessUserProfile from "components/Profile";
import Messages from "components/Messages";
import Shifts from "components/Shifts";
import DashboardIcon from "./components/Sidebar/sideBarIcons/DashboardIcon";
import PostShiftsIcon from "./components/Sidebar/sideBarIcons/PostShiftsIcon";
import ShiftsIcon from "./components/Sidebar/sideBarIcons/ShiftsIcon";
import ShiftRequestIcon from "./components/Sidebar/sideBarIcons/ShiftRequestIcon";
import LocumsIcon from "./components/Sidebar/sideBarIcons/LocumsIcon";
import MessagesIcon from "./components/Sidebar/sideBarIcons/MessagesIcon";
import SettingsIcon from "./components/Sidebar/sideBarIcons/SettingsIcon";
import Help from "components/Help";
import HelpIcon from "./components/Sidebar/sideBarIcons/HelpIcon";

const {
  dashboard,
  company_profile_setup,
  post_shifts,
  shift_requests,
  locums,
  profile,
  messages,
  shifts,
  help
} = RoutesName;

var routes = [
  {
    path: dashboard,
    name: "Dashboard",
    icon: DashboardIcon,
    component: <Dashboard />,
    inSidebar: true,
  },
  {
    path: post_shifts,
    name: "Post Shifts",
    icon: PostShiftsIcon,
    component: <PostShiftList />,
    inSidebar: true,
  },
  {
    path: shifts,
    name: "Shifts",
    icon: ShiftsIcon,
    component: <Shifts />,
    inSidebar: true,
  },
  {
    path: shift_requests,
    name: "Shift Requests",
    icon: ShiftRequestIcon,
    component: <ShiftRequestsList />,
    inSidebar: true,
  },
  {
    path: locums,
    name: "Locums",
    icon: LocumsIcon,
    component: <LocumsList />,
    inSidebar: true,
  },
  {
    path: messages,
    name: "Messages",
    icon: MessagesIcon,
    component: <Messages />,
    inSidebar: true,
  },
  {
    path: help,
    name: "Help",
    icon: HelpIcon,
    component: <Help />,
    inSidebar: true,
  },
  {
    path: profile,
    name: "Settings",
    icon: SettingsIcon,
    component: <BusinessUserProfile />,
    inSidebar: true,
  },
  {
    path: company_profile_setup,
    name: "Profile Setup",
    icon: "DashboardIcon",
    component: <CompanyProfileSetup />,
    inSidebar: false,
  },
];

export default routes;
