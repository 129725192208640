// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.ant-modal-footer .cancel-btn-footer {
  background-color: transparent !important;
  border: 1px solid rgb(0, 64, 43) !important;
  color: rgb(2, 58, 40) !important;
}
.ant-modal-footer .cancel-btn-footer:hover, .ant-modal-footer .cancel-btn-footer:active {
  background-color: rgb(2, 58, 40) !important;
  color: rgb(255, 255, 255) !important;
}

.popup-location-stepper {
  max-width: 550px;
  margin-inline: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Location/Location.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACJ;AACI;EACI,wCAAA;EACA,2CAAA;EACA,gCAAA;AACR;AACQ;EAEI,2CAAA;EACA,oCAAA;AAAZ;;AAKA;EACI,gBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".ant-modal-footer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 16px;\n\n    .cancel-btn-footer {\n        background-color: transparent !important;\n        border: 1px solid rgb(0, 64, 43) !important;\n        color: rgb(2, 58, 40) !important;\n\n        &:hover,\n        &:active {\n            background-color: rgb(2, 58, 40) !important;\n            color: rgb(255, 255, 255) !important;\n        }\n    }\n}\n\n.popup-location-stepper {\n    max-width: 550px;\n    margin-inline: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
