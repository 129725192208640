/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Spin, Steps } from "antd";
import usePostShiftHook from "./usePostShiftHook";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import ShiftOverview from "./ShiftOverview";
import "./PostShift.scss";
import { useEffect } from "react";

const { Step } = Steps;

const PostShift = ({
  openPostShiftModal,
  setOpenPostShiftModal,
  shiftData,
  onClose,
  refreshShifts,
}) => {
  const {
    current,
    items,
    steps,
    prev,
    next,
    createPostShift,
    shiftOverview,
    unPaidHoursList,
    loading,
    isCurrentStepEmpty,
    resetStates,
    updatePostShift,
    initializeShiftData,
  } = usePostShiftHook(openPostShiftModal, setOpenPostShiftModal, refreshShifts);

  // Initialize shift data if editing
  useEffect(() => {
    if (shiftData) {
      initializeShiftData(shiftData);
    }
  }, [shiftData]);

  return (
    <div className="post-shift-btn-wrap">
      <Modal
        open={openPostShiftModal}
        onCancel={() => {
          if (!loading) {
          resetStates();
          if (onClose) onClose();
          }
        }}
        width="100%"
        style={{ overflow: "hidden" }}
        styles={{ body: { height: "calc(90vh - 12px)", overflow: "auto" } }}
        centered
        footer={null}
        destroyOnClose
      >
        <div>
          {isEmpty(shiftOverview) ? (
            <>
              <div className="modal-popup-content">
                <h3 className="popup-step-title">{steps[current].title}</h3>
                <Steps current={current} labelPlacement="vertical">
                  {items.map((item) => (
                    <Step key={item.key} title={item.title} />
                  ))}
                </Steps>
                <div className="shift-select-content">
                  {steps[current].content}
                </div>
              </div>
              <div className="stepper-footer">
                {current > 0 && (
                  <Button
                    color="danger"
                    className="alt-btn-stye"
                    onClick={prev}
                  >
                    Previous
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    color="primary"
                    className="main-btn-stye"
                    onClick={next}
                    disabled={isCurrentStepEmpty()}
                  >
                    Next
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    color="primary"
                    className="main-btn-stye"
                    onClick={shiftData ? updatePostShift : createPostShift}
                    disabled={!shiftData ? isCurrentStepEmpty() : loading }
                  >
                    {loading && <Spin fullscreen />}{" "}
                    {shiftData ? "Update" : "Next"}
                  </Button>
                )}
              </div>
            </>
          ) : (
            <ShiftOverview
              shiftOverview={shiftOverview}
              unPaidHoursList={unPaidHoursList}
              setOpenPostShiftModal={setOpenPostShiftModal}
              resetStates={resetStates}
              updatePostShift={updatePostShift}
              loading={loading}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PostShift;
