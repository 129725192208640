import { DatePicker, Radio, Select, Space, TimePicker } from "antd";
import { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import dayjs from "dayjs";
import { post_api } from "api";
import { toast } from "react-toastify";
import { put_api } from "api";
import { debounce, pick } from "lodash";
import { ReactComponent as CustomArrowIcon } from "../../assets/img/dropdown-icon.svg";
import { unPaidHoursList } from "components/GeneralComponents/Utils";
import { useNavigate } from "react-router-dom";
import { get_api } from "api";
import { USER_ROLE } from "variables/helper";

const usePostShiftHook = (
  openPostShiftModal,
  setOpenPostShiftModal,
  refreshShifts
) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [availableLocation, setAvailableLocation] = useState([]);
  const [postShiftObj, setPostShiftObj] = useState({
    date: null,
    start_time: null,
    end_time: null,
    hourly_rate_type: "Fixed",
    unpaid_hours: null,
    hourly_rate: null,
    isPosted: false,
    location: null,
    role:
      JSON.parse(localStorage.getItem("user"))?.role === "user"
        ? "user"
        : "subuser",
  });
  const [shiftOverview, setShiftOverview] = useState({});
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const skip = () => {
    setCurrent(current + 1);
  };

  const getAllAvailableLocationForSubUser = async () => {
    try {
      setLoading(true);
      const { data, message } = await get_api(
        "multilocation/get-location-of-subuser"
      );
      if (data) {
        if(data.userLocation.length === 1) {
          setPostShiftObj((prev) => ({
            ...prev,
            location: data.userLocation[0]._id,
          }));
        }
        setAvailableLocation(
          data.userLocation.map((loc) => {
            return {
              value: loc._id,
              label: loc.location.locationName + " " + loc.location.address,
            };
          })
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))?.role === USER_ROLE.SUB_USER)
      getAllAvailableLocationForSubUser();
    if (availableLocation.length === 1) {
      setPostShiftObj((prev) => ({
        ...prev,
        location: availableLocation[0].value,
      }));
    }
  }, [openPostShiftModal]);

  const resetStates = () => {
    setOpenPostShiftModal && setOpenPostShiftModal(false);
    setPostShiftObj({
      date: null,
      start_time: null,
      end_time: null,
      hourly_rate_type: "Fixed",
      unpaid_hours: null,
      hourly_rate: null,
      isPosted: false,
      location: null,
      role:
        JSON.parse(localStorage.getItem("user"))?.role === "user"
          ? "user"
          : "subuser",
    });
    setShiftOverview({});
    setCurrent(0);
  };
  function convertDateFormat(dateStr, format) {
    const date = dayjs(dateStr);

    if (date.isValid()) {
      if (format === "DD-MM-YYYY") {
        const date = dayjs(dateStr, "YYYY-MM-DD");
        return date.format("DD-MM-YYYY");
      } else {
        const date = dayjs(dateStr, "DD-MM-YYYY");
        return date.format("YYYY-MM-DD");
      }
    }
  }
  const createPostShift = async () => {
    try {
      setLoading(true);
      postShiftObj.date = postShiftObj.date.split("-").reverse().join("-");
      setPostShiftObj((prev) => ({
        ...prev,
        date: postShiftObj.date,
      }));
      if (!postShiftObj.location) {
        delete postShiftObj.location;
      }
      // console.log("postShiftObj------",postShiftObj)
      // eslint-disable-next-line no-unused-vars
      const { data, message } = await post_api(
        "post-shifts/create",
        postShiftObj
      );
      setShiftOverview(data);
      setLoading(false);
      // toast.success(message);
      refreshShifts();
      return data;
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const updatePostShift = async () => {
    try {
      setLoading(true);
      const psData = postShiftObj?._id
        ? postShiftObj
        : shiftOverview?.postShiftDetail;
      if (postShiftObj?._id) {
        psData.date = psData.date.split("-").reverse().join("-");
      }

      const obj = pick(psData, [
        "_id",
        "date",
        "start_time",
        "end_time",
        "unpaid_hours",
        "hourly_rate_type",
        "hourly_rate",
        "isPosted",
      ]);
      const { data, message } = await put_api("post-shifts/update", {
        ...obj,
        isPosted: true,
      });
      toast.success(message);
      resetStates();
      refreshShifts();
      setLoading(false);
      navigate("/post-shifts");
      return data;
    } catch (err) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const initializeShiftData = (data) => {
    setPostShiftObj({
      date: convertDateFormat(data.date, "DD-MM-YYYY"),
      start_time: data.start_time,
      end_time: data.end_time,
      hourly_rate_type: data.hourly_rate_type,
      unpaid_hours: data.unpaid_hours,
      hourly_rate: data.hourly_rate,
      isPosted: data.isPosted,
      location: data.location._id,
      role: data.role,
      _id: data._id,
    });
  };

  const disabledDate = (current) => current && current < dayjs().startOf("day");

  const handleStartTimeChange = debounce((time, timeString) => {
    setPostShiftObj((prev) => ({
      ...prev,
      start_time: timeString,
    }));
  }, 300);

  const handleEndTimeChange = debounce((time, timeString) => {
    if (postShiftObj?.start_time) {
      const startTime = dayjs(postShiftObj?.start_time, "h:mm A");
      const endTime = time;

      // Check if the start time and end time are the same
      if (startTime.isSame(endTime)) {
        toast.error("Start time and End time cannot be the same.");
        return;
      }

      // Check if the time difference is less than 1 hour
      const diffInHours = endTime.diff(startTime, "hour", true);
      if (diffInHours < 1) {
        toast.error("The time difference should be at least 1 hour.");
        return;
      }
    }

    // Update the end time
    setPostShiftObj((prev) => ({
      ...prev,
      end_time: timeString,
    }));
  }, 300);

  const renderShiftSelection = () => {
    return (
      <>
        <Label className="shift-stepper-sub-title">Shift Details</Label>
        <div className="shift-stepper-field-wrap common-field-group">
          <Label className="common-stepper-label">Date</Label>
          <DatePicker
            className="common-datepicker common-antd-component"
            value={
              !!postShiftObj?.date && dayjs(postShiftObj?.date, "DD-MM-YYYY")
            }
            onChange={(date, dateString) => {
              setPostShiftObj((prev) => ({
                ...prev,
                date: dateString,
              }));
            }}
            disabledDate={disabledDate}
            timeString
            format="DD-MM-YYYY"
          />
        </div>
        <div className="shift-stepper-field-wrap common-field-group">
          <Label className="common-stepper-label">Time</Label>
          <div className="d-flex">
            {/* Start Time Picker */}
            <TimePicker
              placeholder="Start time"
              value={
                postShiftObj?.start_time &&
                dayjs(postShiftObj?.start_time, "h:mm A")
              }
              className="common-timepicker common-antd-component me-2"
              use12Hours
              format="h:mm A"
              onChange={(time, timeString) =>
                handleStartTimeChange(time, timeString)
              }
              changeOnScroll
              needConfirm={false}
            />
            {/* End Time Picker */}
            <TimePicker
              placeholder="End time"
              value={
                postShiftObj?.end_time &&
                dayjs(postShiftObj?.end_time, "h:mm A")
              }
              className="common-timepicker common-antd-component"
              use12Hours
              format="h:mm A"
              onChange={(time, timeString) =>
                handleEndTimeChange(time, timeString)
              }
              changeOnScroll
              needConfirm={false}
            />
          </div>
        </div>
        {JSON.parse(localStorage.getItem("user"))?.role ===
          USER_ROLE.SUB_USER && (
          <div className="shift-stepper-field-wrap common-field-group">
            <Label className="common-stepper-label">Location</Label>
            <Select
              className="common-dropdown common-antd-component unpaid-hours-select"
              // defaultValue={postShiftObj?.location?._id}
              value={postShiftObj?.location}
              placeholder="Select location"
              optionFilterProp="children"
              suffixIcon={<CustomArrowIcon />}
              onChange={(e) => {
                setPostShiftObj((prev) => ({
                  ...prev,
                  location: e,
                }));
              }}
              options={availableLocation}
            />
          </div>
        )}
      </>
    );
  };

  // Calculate time difference in minutes between start_time and end_time
  const calculateTimeDifference = (start, end) => {
    const startTime = dayjs(start, "h:mm A");
    const endTime = dayjs(end, "h:mm A");
    const diffInMinutes = endTime.diff(startTime, "minute");
    return diffInMinutes;
  };

  // Determine the maximum allowed unpaid minutes based on the selected time range
  const maxAllowedUnpaidMinutes = calculateTimeDifference(
    postShiftObj.start_time,
    postShiftObj.end_time
  );

  // Filter the unpaid hours list based on the maximum allowed minutes
  const filteredUnPaidHoursList = unPaidHoursList.filter((item) => {
    const minutes = parseInt(item.value.split("-")[0]);
    return minutes < maxAllowedUnpaidMinutes;
  });

  const renderUnpaidHours = () => {
    return (
      <>
        <Label className="shift-stepper-sub-title">Unpaid Hours (Break)</Label>
        <Select
          className="common-dropdown common-antd-component unpaid-hours-select"
          value={postShiftObj?.unpaid_hours}
          placeholder="Select hours"
          optionFilterProp="children"
          suffixIcon={<CustomArrowIcon />}
          onChange={(val) => {
            setPostShiftObj((prev) => ({
              ...prev,
              unpaid_hours: val,
            }));
          }}
          options={filteredUnPaidHoursList}
        />
      </>
    );
  };
  const renderHourlyRate = () => {
    return (
      <>
        <div className="common-radio-option post-shift-radio-option">
          <Label className="shift-stepper-sub-title">Hourly Rate</Label>
          <div className="post-shift-option-wrapper">
            <Input
              placeholder="Enter rate"
              className="stepper-input-field"
              onChange={(e) => {
                const isValidRate = /^\d{0,4}(\.\d{0,2})?$/.test(
                  e.target.value
                );
                if (isValidRate) {
                  setPostShiftObj((prev) => ({
                    ...prev,
                    hourly_rate: e.target.value,
                  }));
                }
              }}
              value={postShiftObj?.hourly_rate}
              type="number"
            />
          </div>

          <Radio.Group
            className="common-single-select"
            onChange={(e, v) => {
              setPostShiftObj((prev) => ({
                ...prev,
                hourly_rate_type: e.target.value,
              }));
            }}
            value={postShiftObj?.hourly_rate_type}
          >
            <Space className="radio-wrapper" direction="vertical">
              <Radio value="Fixed">Fixed </Radio>
              <Radio value="Negotiable">Negotiable </Radio>
            </Space>
          </Radio.Group>
        </div>
      </>
    );
  };

  const steps = [
    {
      title: "Shift Selection",
      content: renderShiftSelection(),
    },
    {
      title: "Unpaid Hours",
      content: renderUnpaidHours(),
    },
    {
      title: "Hourly Rate",
      content: renderHourlyRate(),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const isCurrentStepEmpty = () => {
    switch (current) {
      case 0:
        return (
          !postShiftObj.date ||
          !postShiftObj.start_time ||
          !postShiftObj.end_time
        );
      case 1:
        return !postShiftObj.unpaid_hours;
      case 2:
        return !postShiftObj.hourly_rate;
      default:
        return false;
    }
  };

  return {
    openPostShiftModal,
    setOpenPostShiftModal,
    steps,
    items,
    current,
    skip,
    next,
    prev,
    createPostShift,
    shiftOverview,
    unPaidHoursList,
    loading,
    isCurrentStepEmpty,
    resetStates,
    updatePostShift,
    initializeShiftData,
  };
};

export default usePostShiftHook;
