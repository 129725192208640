/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import PostShift from "components/PostShift/PostShift";
import { useAuth } from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import routes from "routes.js";
import logo from "../../assets/img/optopm-logo.svg";
import "./DemoNavbar.scss";
import { Badge, Button, List, Popover, Avatar } from "antd";
import ShiftContext from "components/PostShift/ShiftContext";
import { get, startCase } from "lodash";
import msgIcon from "assets/img/header-msg-icon.svg";
import { get_api } from "api";
import { put_api } from "api";
import {
  UserOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  CaretDownOutlined
} from "@ant-design/icons";
const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");

dayjs.extend(relativeTime);
function Header({ notifications }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const { logout, companyProfile, userData } = useAuth();
  const navigate = useNavigate();
  const [openPostShiftModal, setOpenPostShiftModal] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListCount, setNotificationListCount] = useState(0);
  const [notificationMessageCount, setNotificationMessageCount] = useState(0);
  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((notification) => {
        const { notificationType } = notification.data;        
        if (notificationType !== "6") {
          setNotificationListCount((prevCount) => prevCount + 1);
        } else {
          setNotificationMessageCount((prevCount) => prevCount + 1);
        }
      });
    }
  }, [notifications]);
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const handleLogout = () => {
    logout();
  };

  const extraClass = "inner-main-navbar";
  const username = `${startCase(get(userData, "first_name", ""))} ${startCase(
    get(userData, "last_name", "")
  )}`;
  // const accountdetail =
  //   userData?.pricingPlan?.productIntervalType === "month"
  //     ? "Monthly"
  //     : userData?.pricingPlan?.productIntervalType === "year"
  //     ? "Yearly"
  //     : "Free";

  const calculateDaysLeft = (endEpochTime) => {
    const currentEpochTime = Math.floor(Date.now() / 1000); // Get current epoch time in seconds
    const secondsLeft = endEpochTime - currentEpochTime; // Calculate the difference in seconds
    return Math.max(Math.floor(secondsLeft / (60 * 60 * 24)), 0); // Convert seconds to days and ensure no negative value
  };

  const [daysLeft, setDaysLeft] = useState(() =>
    calculateDaysLeft(userData?.pricingPlan?.trialPeriodEndDate)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDaysLeft(calculateDaysLeft(userData?.pricingPlan?.trialPeriodEndDate));
    }, 1000 * 60 * 60);

    return () => clearInterval(interval);
  }, [userData?.pricingPlan?.trialPeriodEndDate]);
  const { refreshShifts } = useContext(ShiftContext);

  const getNotificationList = async () => {
    try {
      setNotificationLoading(true);
      const { data } = await get_api("notification-list");
      setNotificationList(data.notificationList);
      const result = checkUnreadNotifications(data.notificationList);
      setNotificationListCount(result)
      setNotificationLoading(false);
    } catch (err) {
      console.log("🚀 ~ getNotificationList ~ err:", err);
      setNotificationLoading(false);
    }
  };

  useEffect(() => {
    getNotificationList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUnreadNotifications = (notificationList) => {
  const unreadNotifications = notificationList.filter(notification => !notification.isReaded);
  if (unreadNotifications.length > 0) {
    return unreadNotifications.length;
  } else {
    return 0;
  }
};

  const handleRedirect = (item) => {
    if (item?.payload?.type === "shift-request") {
      navigate("/shift-requests");
    }
  };

  const handleRedirectMessage = () => {
    setNotificationMessageCount(0)
    navigate("/messages");
  }

  const content = (
    <List
      loading={notificationLoading}
      dataSource={notificationList}
      renderItem={(item) => (
        <List.Item
          style={{ cursor: "pointer" }}
          key={item._id}
          onClick={() => handleRedirect(item)}
        >
          <List.Item.Meta
            avatar={<UnorderedListOutlined />}
            title={
              <span>
                {item.message}{" "}
                <span className="noti-time">
                  {item.createdAt && dayjs(item.createdAt).fromNow()}
                </span>
              </span>
            }
            // description={"item.description"}
          />
        </List.Item>
      )}
    />
  );

  const updateNotificationCount = async () => {
    try {
      setNotificationLoading(true);
      const { data } = await put_api("notification-list", { isReaded: true });
      setNotificationList(data.notificationList);
      const result = checkUnreadNotifications(data.notificationList);
      setNotificationListCount(result)
      setNotificationLoading(false);
    } catch (err) {
      console.log("🚀 ~ getNotificationList ~ err:", err);
      setNotificationLoading(false);
    }
  };

  const canceledSubscription = companyProfile?.pricingPlan?.subscriptionStatus === 'canceled' || companyProfile?.pricingPlan?.invoiceStatus === 'failed'
  return (
    <Navbar
      id="navbar"
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top" + extraClass
          : "navbar-absolute fixed-top " + extraClass
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          {companyProfile && !canceledSubscription && (
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          )}  
          <NavbarBrand className="p-0 m-0" href="/">
            <img src={logo} alt="image logo" />
          </NavbarBrand>
        </div>
        <div className="user-profile-header d-flex justify-content-end">
          {companyProfile && !canceledSubscription && (
            <div className="post-shift-btn-wrap">
              <Button
                color="transparent"
                // outline
                className="header-btn-wrap"
                onClick={() => setOpenPostShiftModal(true)}
              >
                <i className="fa fa-plus" />
                Post Shift
              </Button>
            </div>
          )}
          {companyProfile && !canceledSubscription && (
            <Button
              className="header-rounded-btn"
              onClick={() => handleRedirectMessage()}
            >
              {notificationMessageCount > 0 && <span className="header-notification-dot"></span>}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.31315 19.706L7.43133 17.4417H10.945C12.1876 17.4417 13.195 16.4344 13.195 15.1917L13.195 9.5C13.195 8.25736 12.1877 7.25 10.945 7.25H2.25C1.00736 7.25 0 8.25736 0 9.5V15.1917C0 16.4344 1.00736 17.4417 2.25 17.4417H2.32869V18.6945C2.32869 19.7157 3.48684 20.306 4.31315 19.706Z"
                  fill="currentColor"
                />
                <path
                  d="M2.49316 3.02734C2.49316 1.50856 3.72438 0.277344 5.24316 0.277344H17.2432C18.7619 0.277344 19.9932 1.50856 19.9932 3.02734V11.0158C19.9932 12.3883 18.9509 13.5173 17.6148 13.6545V16.3982C17.6148 17.448 16.399 18.0302 15.5811 17.372L14.4689 16.477C14.6152 16.076 14.695 15.6431 14.695 15.1915L14.695 9.49981C14.695 7.42874 13.0161 5.74981 10.945 5.74981H2.49316V3.02734Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
          )}
          {companyProfile && !canceledSubscription && (
            <Popover
              content={content}
              title="Notifications"
              trigger="click"
              open={notificationVisible}
              onOpenChange={() => setNotificationVisible(!notificationVisible)}
              className="notification-dropdown-btn"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              overlayClassName="notification-dropdown"
            >
              <Button
                className="header-rounded-btn"
                onClick={updateNotificationCount}
              >
                {notificationListCount > 0 && <span className="header-notification-dot"></span>}
                <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.914 24.2781C16.9142 24.9789 16.6496 25.6539 16.173 26.1678C15.6965 26.6816 15.0434 26.9964 14.3446 27.0489L14.1362 27.0559H11.3585C10.6577 27.0561 9.98267 26.7914 9.46881 26.3149C8.95495 25.8384 8.64019 25.1853 8.58763 24.4864L8.58068 24.2781H16.914ZM12.7473 0.666992C15.2682 0.666951 17.6904 1.64601 19.5032 3.39765C21.316 5.14929 22.3776 7.53656 22.464 10.0559L22.4696 10.3892V15.617L25.0001 20.6781C25.1106 20.8989 25.1658 21.1432 25.1612 21.3901C25.1566 21.637 25.0922 21.879 24.9735 22.0956C24.8549 22.3121 24.6855 22.4967 24.48 22.6334C24.2744 22.7702 24.0387 22.8551 23.7932 22.8809L23.6335 22.8892H1.86124C1.61424 22.8893 1.37091 22.8295 1.1521 22.7149C0.933286 22.6003 0.745513 22.4344 0.604872 22.2314C0.46423 22.0283 0.374914 21.7942 0.344576 21.5491C0.314239 21.304 0.343784 21.0551 0.430682 20.8239L0.49457 20.6781L3.02513 15.617V10.3892C3.02513 7.81072 4.04943 5.33784 5.8727 3.51456C7.69597 1.69129 10.1689 0.666992 12.7473 0.666992ZM12.7473 3.44477C10.9578 3.44487 9.2374 4.1358 7.94488 5.37346C6.65236 6.61113 5.88751 8.29998 5.80985 10.0878L5.8029 10.3892V15.617C5.80292 15.9614 5.73887 16.3029 5.61401 16.6239L5.50985 16.86L3.88485 20.1114H21.6112L19.9862 16.8587C19.8321 16.5507 19.7365 16.2168 19.7043 15.8739L19.6918 15.617V10.3892C19.6918 8.54743 18.9601 6.78109 17.6578 5.47875C16.3555 4.17641 14.5891 3.44477 12.7473 3.44477Z" fill="currentColor"/>
                </svg>
              </Button>
            </Popover>
          )}
        </div>
        <NavbarToggler className="profile-toggler" onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>        
        <Collapse
          isOpen={isOpen}
          navbar
          className=""
        >
          <PostShift
            onClose={() => {
              refreshShifts();
            }}
            openPostShiftModal={openPostShiftModal}
            setOpenPostShiftModal={setOpenPostShiftModal}
            refreshShifts={refreshShifts}
          />
          <Nav navbar>
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
              className="user-dropdown"
            >
              <DropdownToggle caret nav>
                {/* <span className="user-icon">
                  <i className="nc-icon nc-single-02" />
                </span> */}
                <div className="user-details">
                  <h3>{username}</h3>
                  {/* <span>{accountdetail}</span> */}
                  <CaretDownOutlined />
                </div>
              </DropdownToggle>
              <DropdownMenu right>
                <div className="user-profile-menu">
                  {companyProfile && get(companyProfile, "companyLogo") ? <Avatar src={get(companyProfile, "companyLogo")}/> : <Avatar src={process.env.REACT_APP_S3_URL+"/default_images/optom_default.png"} />}
                  <div className="user-details-wrap">
                    <h3>{username}</h3>
                    <span>Business User</span>
                  </div>
                </div>
                {companyProfile && !canceledSubscription && (
                  <DropdownItem
                    className="profile-dropdown-menu"
                    tag="a"
                    onClick={() => navigate("/profile")}
                  >
                    <div className="dropdown-menu-wrap">
                      <UserOutlined />
                      <h4 className="profile-name">Profile</h4>
                    </div>
                  </DropdownItem>
                )}
                <DropdownItem
                  className="logout-dropdown-menu"
                  tag="a"
                  onClick={handleLogout}
                >
                  <div className="dropdown-menu-wrap">
                    <LogoutOutlined />
                    <h4 className="profile-name">Logout</h4>
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
