import { Table, Empty, Button } from "antd";
import React, { useContext, useLayoutEffect, useState, useRef } from "react";
import usePostShiftListHook from "./usePostShiftListHook";
import PostShift from "./PostShift";
import ShiftContext from "./ShiftContext";

const PostShiftList = () => {
  const [openPostShiftModal, setOpenPostShiftModal] = useState(false);
  const [tableHeight, setTableHeight] = useState(240); // Initial height
  const { refreshShifts } = useContext(ShiftContext);
  const headerRef = useRef(null);
  const contentRef = useRef(null);

  const { columns, isLoading, shiftData, setShiftData } = usePostShiftListHook(
    setOpenPostShiftModal
  );
  const { data } = useContext(ShiftContext);

  const NoDataComponent = () => (
    <div className="empty-table-content" style={{height: `${tableHeight}px`, display: "flex", justifyContent: "center", alignItems: "center",}}>
      <Empty />
    </div>
  );

  const updateTableHeight = () => {
    const headerHeight = headerRef.current ? headerRef.current.getBoundingClientRect().height : 0;
    const titleMarginBottom = headerRef.current ? parseFloat(getComputedStyle(headerRef.current).marginBottom) : 0;
    const contentMarginTop = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).marginTop) : 0;
    const contentPaddingBottom = contentRef.current ? parseFloat(getComputedStyle(contentRef.current).paddingBottom) : 0;

    //calculate table header spacing and height
    const tableWrapper = document.querySelector('.ant-table-wrapper');
    const tableHeader = document.querySelector('.ant-table-header, .ant-table-thead');

    const tableWrapperPaddingTop = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingTop) : 0;
    const tableWrapperPaddingBottom = tableWrapper ? parseFloat(getComputedStyle(tableWrapper).paddingBottom) : 0;
    const tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0;
    const tableWrapperPadding = tableWrapperPaddingTop + tableWrapperPaddingBottom;

    const contentOuterHeight = contentMarginTop + contentPaddingBottom;

    const newHeight = window.innerHeight - contentOuterHeight - tableWrapperPadding - tableHeaderHeight - headerHeight - titleMarginBottom - 2; // Adjust the -85 as needed
    setTableHeight(newHeight);

    const tableBody = document.querySelector('.ant-table-body');
      if (tableBody) {
        tableBody.style.height = `${tableHeight}px`;
        tableBody.style.overflowY = 'auto';
      }
  };

  useLayoutEffect(() => {
    // Update height on component mount
    updateTableHeight();

    // Update height on window resize
    window.addEventListener("resize", updateTableHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [data, tableHeight]);

  const scrollXValue = columns.length * 150;

  return (
    <div ref={contentRef} className="content mb-0">
      <div ref={headerRef} className="page-header-wrap">
        <h2 className="page-main-title">
          Post Shifts
        </h2>
        <div className="post-shift-btn-wrap">
          <Button
            color="transparent"
            outline
            className="header-btn-wrap"
            onClick={() => setOpenPostShiftModal(true)}
          >
            <i className="fa fa-plus" />
            Post Shift
          </Button>
        </div>
      </div>
      <div className="cmn-card-content">
        {data?.length === 0 ? (
          <NoDataComponent />
        ) : (  
          <Table
            className="post-shift-tbl"
            columns={columns}
            dataSource={data}
            rowKey="_id"
            loading={isLoading}
            pagination={false}
            scroll={data.length > 0 ? { x: scrollXValue, y: tableHeight } : undefined} // Use dynamic height
            rowClassName={() => "custom-row"}
          />
        )}
      </div>
      {/* for update post shift */}
      <PostShift
        shiftData={shiftData}
        onClose={() => {
          setShiftData(null);
          refreshShifts();
        }}
        openPostShiftModal={openPostShiftModal}
        setOpenPostShiftModal={setOpenPostShiftModal}
        refreshShifts={refreshShifts}
      />
    </div>
  );
};

export default PostShiftList;
