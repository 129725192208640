import { useState, useEffect, useContext } from "react";
import { post_api, get_api, delete_api, put_api } from "api";
import { toast } from "react-toastify";
import { Space, Modal, Tooltip } from "antd";
import { useAuth } from "context/AuthContext";
import userContext from "./UserContext";
const useUsers = (setOpenUserModal, onClose) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [payload, setPayload] = useState();
  const { refreshUser } = useContext(userContext);
  const { isAuthenticated } = useAuth();

  const getAllLocations = async () => {
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        const { data } = await get_api("multilocation/all");
        setAllLocation(data.location);
        setIsLoading(false);
        return allLocation;
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };

  const getAllUsers = async () => {
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        const { data } = await get_api("sub-user/all");
        setAllUsers(data.subUsers);
        setIsLoading(false);
        return allUsers;
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    refreshUser();
    getAllLocations();
  }, []);

  const createUser = async () => {
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        const { data, message } = await post_api("sub-user/create", payload);
        setIsLoading(false);
        toast.success(message);
        refreshUser();
        onClose();
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };

  const updateUser = async () => {
    if (isAuthenticated) {
      try {
        if (!payload) {
          toast.error("Please select a user to update");
          return;
        }
        setIsLoading(true);
        const { data, message } = await put_api(
          `sub-user/update/${payload._id}`,
          {
            first_name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            phone: payload.phone,
            multiLocationIds: payload.multiLocationIds,
          }
        );
        setIsLoading(false);
        toast.success(message);
        refreshUser();
        onClose();
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    }
  };

  const warning = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this user ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => { },
      onCancel: () => {
        console.log("Cancel clicked..");
      },
    });
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => {
        return <div key={record._id}>{text}</div>;
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (text, record) => {
        return (
          <div key={record._id}>
            {record.multiLocationIds.slice(0, 1).map((location) => (
              <span key={location._id}>
                {location.location.address}
              </span>
            ))}
            {record.multiLocationIds.length > 1 && (
              <span>
                , <Tooltip title={record.multiLocationIds.slice(1).map(loc => loc.location.address).join(', ')}>
                  +{record.multiLocationIds.length - 1} more
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          <i
            className="fas fa-edit"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setPayload(record);
              setOpenUserModal(true);
            }}
          />
          <i
            className="fas fa-trash-alt text-danger"
            style={{ cursor: "pointer" }}
            onClick={() => warning(record._id)}
          />
        </Space>
      ),
      align: "right",
    },
  ];

  return {
    isLoading,
    allLocation,
    getAllLocations,
    payload,
    setPayload,
    allUsers,
    setAllUsers,
    refreshUser: getAllUsers,
    columns,
    createUser,
    updateUser,
  };
};

export default useUsers;
