// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-popup-content .popup-user-form-title {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600;
  color: rgb(2, 58, 40);
}
.modal-popup-content .user-form-select-content {
  max-width: 600px;
  width: 100%;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
}
.modal-popup-content .user-form-select-content .user-form-stepper-field-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
}
.modal-popup-content .user-form-select-content .user-form-stepper-field-box {
  width: 100%;
  margin-top: 24px;
}
.modal-popup-content .user-form-select-content .user-form-stepper-field-box .user-form-stepper-sub-title {
  font-size: 28px;
  line-height: 42px;
  color: rgb(2, 58, 40);
  font-weight: 600;
  margin-bottom: 25px;
  width: 100%;
  text-align: center;
}
.modal-popup-content .user-form-select-content .user-form-stepper-field-box .user-form-stepper-field-wrap {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Users/Users.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AAAR;AAGI;EACI,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,iBAAA;AADR;AAGQ;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AADZ;AAIQ;EACI,WAAA;EACA,gBAAA;AAFZ;AAIY;EACI,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;AAFhB;AAKY;EACI,WAAA;AAHhB","sourcesContent":[".modal-popup-content {\n    .popup-user-form-title {\n        font-size: 36px;\n        line-height: 42px;\n        margin-bottom: 40px;\n        text-align: center;\n        font-weight: 600;\n        color: rgba(2, 58, 40, 1);\n    }\n\n    .user-form-select-content {\n        max-width: 600px;\n        width: 100%;\n        margin: 15px auto;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n        min-height: 500px;\n\n        .user-form-stepper-field-group {\n            width: 100%;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            column-gap: 16px;\n        }\n\n        .user-form-stepper-field-box {\n            width: 100%;\n            margin-top: 24px;\n\n            .user-form-stepper-sub-title {\n                font-size: 28px;\n                line-height: 42px;\n                color: rgba(2, 58, 40, 1);\n                font-weight: 600;\n                margin-bottom: 25px;\n                width: 100%;\n                text-align: center;\n            }\n\n            .user-form-stepper-field-wrap {\n                width: 100%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
