import React from 'react';

const DashboardIcon = () => (
  
    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.0626 36.4795H6.68148C3.50671 36.4795 1.97461 35.0106 1.97461 31.9779V7.02205C1.97461 3.98943 3.52251 2.52051 6.68148 2.52051H13.0626C16.2374 2.52051 17.7695 3.98943 17.7695 7.02205V31.9779C17.7695 35.0106 16.2216 36.4795 13.0626 36.4795ZM6.68148 4.88974C4.67553 4.88974 4.34384 5.42676 4.34384 7.02205V31.9779C4.34384 33.5732 4.67553 34.1103 6.68148 34.1103H13.0626C15.0686 34.1103 15.4003 33.5732 15.4003 31.9779V7.02205C15.4003 5.42676 15.0686 4.88974 13.0626 4.88974H6.68148Z" fill="currentColor"/>
        <path d="M31.2267 22.2641H24.8455C21.6708 22.2641 20.1387 20.7952 20.1387 17.7626V7.02205C20.1387 3.98943 21.6866 2.52051 24.8455 2.52051H31.2267C34.4014 2.52051 35.9335 3.98943 35.9335 7.02205V17.7626C35.9335 20.7952 34.3856 22.2641 31.2267 22.2641ZM24.8455 4.88974C22.8396 4.88974 22.5079 5.42676 22.5079 7.02205V17.7626C22.5079 19.3578 22.8396 19.8949 24.8455 19.8949H31.2267C33.2326 19.8949 33.5643 19.3578 33.5643 17.7626V7.02205C33.5643 5.42676 33.2326 4.88974 31.2267 4.88974H24.8455Z" fill="currentColor"/>
        <path d="M31.2267 36.479H24.8455C21.6708 36.479 20.1387 35.01 20.1387 31.9774V29.1344C20.1387 26.1017 21.6866 24.6328 24.8455 24.6328H31.2267C34.4014 24.6328 35.9335 26.1017 35.9335 29.1344V31.9774C35.9335 35.01 34.3856 36.479 31.2267 36.479ZM24.8455 27.002C22.8396 27.002 22.5079 27.5391 22.5079 29.1344V31.9774C22.5079 33.5727 22.8396 34.1097 24.8455 34.1097H31.2267C33.2326 34.1097 33.5643 33.5727 33.5643 31.9774V29.1344C33.5643 27.5391 33.2326 27.002 31.2267 27.002H24.8455Z" fill="currentColor"/>
    </svg>
);

export default DashboardIcon;