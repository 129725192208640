/*eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Carousel } from 'antd';
import logo from '../assets/img/optopm-logo.svg';
import whitelogo from '../assets/img/white-logo.svg';
import largefavicon from '../assets/img/optom-large-img.svg'
import largefavicon1 from '../assets/img/optom-large-img-1.svg'
import largefavicon2 from '../assets/img/optom-large-img-2.svg'
import rotateImage from '../assets/img/rotate-img.png'

const CommonForm = ({ children }) => {
  const [isLandscape, setIsLandscape] = useState(false);

  const adjustFormWrapHeight = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const isPortrait = windowHeight > windowWidth && windowWidth <= 768;
    const isLandscapeView = windowWidth > windowHeight && windowWidth >= 320 && windowWidth <= 992;

    setIsLandscape(isLandscapeView);

    if (isPortrait && !isLandscapeView) {

      const vh = windowHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      const headerHeight = document.querySelector('.web-logo')?.offsetHeight || 0;
      const headerPaddingTop = document.querySelector('.web-logo')?.paddingTop || 0;
      const headerPaddingBottom = document.querySelector('.web-logo')?.PaddingBottom || 0;

      const footerHeight = document.querySelector('.footer-copyright')?.offsetHeight || 0;
      const footerPaddingTop = document.querySelector('.footer-copyright')?.paddingTop || 0;
      const footerPaddingBottom = document.querySelector('.footer-copyright')?.PaddingBottom || 0;

      const header = headerPaddingTop + headerPaddingBottom
      const footer = footerHeight + footerPaddingTop + footerPaddingBottom

      const calculatedHeight = windowHeight - headerHeight - header - footer - 2;

      // Apply the calculated height if the .form-wrap element exists
      const formWrapElement = document.querySelector('.form-wrap');
      if (formWrapElement) {
        formWrapElement.style.height = `${calculatedHeight}px`;
      }
    }
  };

  useEffect(() => {
    adjustFormWrapHeight();
    window.addEventListener('resize', adjustFormWrapHeight);
  
    return () => {
      window.removeEventListener('resize', adjustFormWrapHeight);
    };
  }, []);

  // Additional useEffect to adjust height on orientation change
  useEffect(() => {
    adjustFormWrapHeight();
  }, [isLandscape]);

  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open("/privacy-policy", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="content">
      <div className="common-main-wrapper">
        <div className="form-slider-section">
          <Carousel autoplaySpeed={1500} className="carousel-slider-wrapper">
            <div className="slider-wrapper alt-slider-wrapper">
              <img src={largefavicon} alt="optom image" />
              <div className="slider-content-wrapper">
                <h2 className="slider-title">Connect</h2>
                <div className="slider-description">Easily connect with skilled locums to fill your open shifts. </div>
              </div>
            </div>
            <div className="slider-wrapper alt-slider-wrapper">
              <img src={largefavicon1} alt="optom image" />
              <div className="slider-content-wrapper">
                <h2 className="slider-title">Thrive</h2>
                <div className="slider-description">Keep your practice running smoothly with quick locum placements. </div>
              </div>
            </div>
            <div className="slider-wrapper alt-slider-wrapper">
              <img src={largefavicon2} alt="optom image" />
              <div className="slider-content-wrapper">
                <h2 className="slider-title">Succeed</h2>
                <div className="slider-description">Boost your clinic's performance with reliable locum support. </div>
              </div>
            </div>
          </Carousel>
        </div>
        
        <div className="common-form-wrapper">
          {!isLandscape ? (
            <div className="form-content-wrap">
              <Link className="web-logo">
                <img className="desk-logo" src={logo} alt="Logo"/>
                <img className="mobile-logo" src={whitelogo} alt="Logo"/>
              </Link>
              <div className="form-wrap">
                {children}
              </div>
              <div className="footer-copyright">
                <Link onClick={handleLinkClick} className="footer-link">
                  Privacy Policy
                </Link>
                <span className="copyright-text">
                  <a href="https://optomconnect.com.au/" target="_blank" rel="noreferrer">
                  ©Optom Connect 2024
                  </a>
                </span>
              </div>
            </div>
          ) : (
            <div className="landscape-view-message">
              <img className="rotate-img" src={rotateImage} alt="rotate image" />
            </div>
          )}  
        </div>
      </div>
    </div>
  );
};

export default CommonForm;
