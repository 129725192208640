import React from 'react';

const MessageIcon = () => (
  
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8896 3.87695V8.71029" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.7773 3.87695V8.71029" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.63965 15.2998H33.0285" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.833 14.349V28.0435C33.833 32.8768 31.4163 36.099 25.7775 36.099H12.8886C7.24967 36.099 4.83301 32.8768 4.83301 28.0435V14.349C4.83301 9.51568 7.24967 6.29346 12.8886 6.29346H25.7775C31.4163 6.29346 33.833 9.51568 33.833 14.349Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.286 22.7274H25.3004" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.286 27.5604H25.3004" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.327 22.7274H19.3415" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19.327 27.5604H19.3415" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3631 22.7274H13.3776" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3631 27.5604H13.3776" stroke="currentColor" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

);

export default MessageIcon;