// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "P22Mackinac";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("embedded-opentype"),
      url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff2"),
      url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff"),
      url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }`, "",{"version":3,"sources":["webpack://./src/assets/demo/extrafonts.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,4CAAgE;IAChE;;;gEAGgF;IAChF,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":["@font-face {\n    font-family: \"P22Mackinac\";\n    src: url(\"~assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.eot\");\n    src: url(\"~assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.eot\") format(\"embedded-opentype\"),\n      url(\"~assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.woff2\") format(\"woff2\"),\n      url(\"~assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.woff\") format(\"woff\"),\n      url(\"~assets/fonts/Fontspring-DEMO-P22Mackinac-Medium.ttf\") format(\"truetype\");\n    font-weight: normal;\n    font-style: normal;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
