/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const borderRadiusPlugin = {
  id: 'borderRadiusPlugin',
  beforeDraw: (chart) => {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      dataset._meta[chart.id].data.forEach((bar, index) => {
        const topBar = (chart.data.datasets.length > i + 1) && chart.data.datasets[i + 1].data[index] > 0;
        const bottomBar = (i > 0) && chart.data.datasets[i - 1].data[index] > 0;

        // Determine borderRadius
        const borderRadius = {
          topLeft: !topBar ? 10 : 0,
          topRight: !topBar ? 10 : 0,
          bottomLeft: !bottomBar ? 10 : 0,
          bottomRight: !bottomBar ? 10 : 0
        };

        // Apply the borderRadius
        ctx.save();
        ctx.fillStyle = bar.options.backgroundColor;
        ctx.beginPath();
        // Draw the bar using the path method with borderRadius
        ctx.roundRect(bar.x, bar.y, bar.width, bar.base - bar.y, borderRadius);
        ctx.fill();
        ctx.restore();
      });
    });
  }
};

// Extend the chart.js rectangle draw method to support borderRadius
if (!CanvasRenderingContext2D.prototype.roundRect) {
  CanvasRenderingContext2D.prototype.roundRect = function(x, y, width, height, radius) {
    if (typeof radius === 'number') {
      radius = {topLeft: radius, topRight: radius, bottomRight: radius, bottomLeft: radius};
    }
    this.beginPath();
    this.moveTo(x + radius.topLeft, y);
    this.lineTo(x + width - radius.topRight, y);
    this.quadraticCurveTo(x + width, y, x + width, y + radius.topRight);
    this.lineTo(x + width, y + height - radius.bottomRight);
    this.quadraticCurveTo(x + width, y + height, x + width - radius.bottomRight, y + height);
    this.lineTo(x + radius.bottomLeft, y + height);
    this.quadraticCurveTo(x, y + height, x, y + height - radius.bottomLeft);
    this.lineTo(x, y + radius.topLeft);
    this.quadraticCurveTo(x, y, x + radius.topLeft, y);
    this.closePath();
  };
}



const user = JSON.parse(localStorage.getItem("user"));
const dashboard24HoursPerformanceChart = {
  data: (canvas) => {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      datasets: [
        {
          borderColor: "#6bd098",
          backgroundColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          borderWidth: 3,
          tension: 0.4,
          fill: true,
          data: user?.dashboardAnalytics?.shiftRequest ? user.dashboardAnalytics.shiftRequest : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  options: {
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          color: "#023a28",
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      x: {
        stacked: true,
        barPercentage: 1.6,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          padding: 20,
          color: "#023a28",
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(tooltipItem) {
            let label = tooltipItem.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.raw;
            return label;
          }
        }
      },
    }   
  },
};


const dashboardEmailStatisticsChart = {
  data: (canvas) => {
    return {
      labels: [1, 2, 3],
      datasets: [
        {
          label: "Emails",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#e3e3e3", "#4acccd", "#fcc468", "#ef8157"],
          borderWidth: 0,
          data: [342, 480, 530, 120],
        },
      ],
    };
  },
  options: {
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    maintainAspectRatio: false,
    pieceLabel: {
      render: "percentage",
      fontColor: ["white"],
      precision: 2,
    },
    scales: {
      y: {
        ticks: {
          display: false,
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      x: {
        barPercentage: 1.6,
        grid: {
          drawBorder: false,
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  },
};

const dashboardNASDAQChart = {
  data: (canvas) => {
    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          data: [0, 19, 15, 20, 30, 40, 40, 50, 25, 30, 50, 70],
          fill: false,
          borderColor: "#fbc658",
          backgroundColor: "transparent",
          pointBorderColor: "#fbc658",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
        // {
        //   data: [0, 5, 10, 12, 20, 27, 30, 34, 42, 45, 55, 63],
        //   fill: false,
        //   borderColor: "#51CACF",
        //   backgroundColor: "transparent",
        //   pointBorderColor: "#51CACF",
        //   pointRadius: 4,
        //   pointHoverRadius: 4,
        //   pointBorderWidth: 8,
        //   tension: 0.4,
        // },
      ],
    };
  },
  options: {
    plugins: {
      legend: { display: false },
    },
  },
};

module.exports = {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
};
